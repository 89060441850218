import { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
// import PaginatedItems from "./Pagination";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
// import { internalUsers, userTypes_Ids } from "./constants/userTypes";
import debounce from "lodash/debounce";
import Select from "react-select";
import { internalUsers } from "./constants/userTypes";
const Users = ({
  setDisplayComponent,
  setIsLoading,
  isDarkMode,
  usersData,
  setusers,
  pageCache,
  setPageCache,
  total,
  setTotal,
  pageNo,
  setPageNo,
  deleteStates,
  setDeleteStates,
  selectedDealershipOption,
}) => {
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userType = localStorage.getItem("userType");
  // const [usersData, setusers] = useState([]);
  // const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  // const [pageNo, setPageNo] = useState(1);
  // const [total, setTotal] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  const [typeuser, settypeuser] = useState(userType === "Animator" ? "2" : "1");
  const [key, setKey] = useState("");
  const { language } = useLanguage();
  const bottomMarkerRef = useRef(null);
  const containerRef = useRef(null);
  const [allowScrollFetch, setAllowScrollFetch] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [dataEnd, setDataEnd] = useState(false);
  let page = 1;
  const isFirstRender = useRef(true);
  // Create state to track deleteClicked for each item
  // const [deleteStates, setDeleteStates] = useState();
  useEffect(() => {
    const fetchData = async () => {
      // if (!pageCache[pageNo]) {
      //   // Data already in cache, no need for API call
      //   const { cachedResponse, cachedTotal } = pageCache[pageNo];
      //   setusers(cachedResponse);
      //   setTotal(cachedTotal);
      //   console.log("here2");

      // } else {

      try {
        setIsLoading(true);
        const res = await fetch(
          ApiUrl + "/api/user/get-all-users?page=" + pageNo,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("Get users res: ", res);
        const data = await res.json();
        // console.log("Get users data: ", data)
        // console.log(result)
        if (data.status === "success") {
          const newData = data.list_of_users.data;
          const newTotal = data.list_of_users.total;
          // Update the cache with new data
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setusers(newData);
          setTotal(newTotal);
          setDeleteStates(newData.map(() => false));
          isFirstRender.current = false;
          // setIsLoading(false);
        } else {
          toast.error(data.message);
        }
        // setIsLoading(false);
      } catch (err) {
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
        setIsLoading(false);
      }
      // }
    };
    if (
      !activeSearch &&
      selectedDealershipOption?.length === 0 &&
      userType !== "Animator"
    ) {
      fetchData();
    }
    if (userType === "Animator") {
      filterUsers("2");
    }
    // }, [pageNo, pageCache, usersData]);
  }, []);

  const handleDelete = async (id, index) => {
    const page =
      usersData.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setIsLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/delete-users?page=" + page + "" + searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id,
            search_parameter: searchParam,
            userType: typeuser,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(translations.alertMessages.userDeletion[language]);
        setDeleteStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = false;
          return newStates;
        });
        const newData = data.users_list.data;
        const newTotal = data.users_list.total;

        setusers(newData);
        setTotal(newTotal);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        if (key) {
          setSearchCache({
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          });
        }

        if (activeSearch) {
          setPageNo(
            usersData.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleResend = async (email) => {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/send-set-password-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(translations.alertMessages.emailSentSuccess[language]);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      setusers(cachedResponse);
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/search-users?page=" + pageNo,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              search_parameter: key,
            }),
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.event_list.data;
          const newTotal = data.event_list.total;
          // Update the cache with new data
          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setusers(newData);
          setTotal(newTotal);
          // if (pageNo === 1) toast.success("Student search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(translations.alertMessages.wentWrong[language]);
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[1];
    setPageNo(1);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setusers(cachedResponse);
    setKey("");
    // toast.success("Student search cleared");
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  const getScrollData = async (allData) => {
    // setFilterLoading(true);
    // setGetLoading(true);
    page = page + 1;

    try {
      const res = await fetch(ApiUrl + "/api/user/search-users?page=" + page, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          search_parameter: key,
          userType: typeuser,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        // setActiveSearch(true);

        const newData = data.event_list.data;

        if (data.event_list.data.length > 0) {
          setusers((prevUsers) => [...prevUsers, ...newData]);
          setPageNo((prev) => prev + 1);
        }

        if (data.event_list.data.length === 0) {
          setDataEnd(true);
        }

        // Update the cache with new data
        // setSearchCache((prevCache) => ({
        //   ...prevCache,
        //   [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        // }));
        // Set state with new data
        // setDealershipSearch(newData);

        // setTotal(newTotal);
        // if (pageNo === 1) toast.success("Dealerships search successful");
        // setFilterLoading(false);
        // setGetLoading(false);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
      // setFilterLoading(false);
      // setGetLoading(false);
    }
  };

  const SCROLL_TRIGGER_OFFSET = 1000; // Pixels before the top or bottom marker to trigger the fetch
  // const SCROLL_THRESHOLD_PERCENT = 0.1;

  const fetchDataOnScroll = async (direction) => {
    if (isFetching || !allowScrollFetch || dataEnd) return; // Prevent fetch if already fetching or scroll fetching is disabled

    setIsFetching(true);
    try {
      console.log("Fetching data for lower dealers.");
      await getScrollData(usersData);
    } finally {
      setIsFetching(false);
    }
  };

  // Memoize the debounced function to prevent unnecessary re-creation
  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!allowScrollFetch) return;

        const container = containerRef.current;
        const bottomMarker = bottomMarkerRef.current;

        if (container && bottomMarker) {
          const containerRect = container.getBoundingClientRect();
          const bottomMarkerRect = bottomMarker.getBoundingClientRect();

          // Calculate distance to top and bottom markers

          const distanceToBottomMarker =
            bottomMarkerRect.bottom - containerRect.bottom;

          // Fetch data if within offset range
          if (distanceToBottomMarker < SCROLL_TRIGGER_OFFSET) {
            fetchDataOnScroll("down");
          }
        }
      }, 300), // Debounce time (adjust as needed)
    [allowScrollFetch, dataEnd] // Dependency array includes scroll flag
  );

  // Use `useCallback` to wrap the debounced function
  const handleScroll = useCallback(debouncedHandleScroll, [
    debouncedHandleScroll,
  ]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const filterUsers = async (userType) => {
    // setFilterLoading(true);
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/search-users?page=" + pageNo,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userType: userType,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setActiveSearch(true);

        const newData = data.event_list.data;
        const newTotal = data.event_list.total;
        // Update the cache with new data
        // setSearchCache((prevCache) => ({
        //   ...prevCache,
        //   [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        // }));
        // Set state with new data
        setusers(newData);
        setTotal(newTotal);
        // if (pageNo === 1) toast.success("Student search successful");
        // setFilterLoading(false);
        setDataEnd(false);
        setIsLoading(false);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
      // setFilterLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="Events">
      <div class="container maincont">
        <div id="app" class="appcountainer2">
          <div className="d-flex align-items-center justify-content-between py-2">
            <div>
              <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                {translations.usersPage.title[language]}
              </h2>
            </div>

            <div className="d-flex align-items-center gap-2">
              {userData &&
                userData.roles[0].permissions.some(
                  (item) => item.name === "Register"
                ) && (
                  <div className="d-grid gap-2">
                    {/* <Link to='/create-user' style={{ textDecoration: "none" }}> */}
                    <button
                      type="button"
                      className="d-flex btn btn-primary align-items-center admin-btn gap-1"
                      // id="create-user-link"
                      // fdprocessedid="dyv9n"
                      // data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          // setPrevDisplay(prev);
                          return "create-user";
                        });
                        window.history.pushState(
                          { prevState: "prev" },
                          "title",
                          "/event-portal?page=create-user"
                        );
                      }}
                    >
                      <i className="bi bi-person-add"></i>{" "}
                      {translations.topAndsidebar.createUser[language]}
                    </button>
                    {/* </Link> */}
                  </div>
                )}
              {userType &&
                internalUsers.includes(userType) &&
                userType !== "Animator" && (
                  <div
                    className=""
                    style={{ width: language === "fr" ? "210px" : "180px" }}
                  >
                    <Select
                      className="w-100 selectpicker"
                      aria-labelledby="dealership-name-quick"
                      // isClearable
                      options={[
                        {
                          label:
                            translations.commonWords.internalUsers[language],
                          value: "1",
                        },
                        {
                          label:
                            translations.commonWords.externalUsers[language],
                          value: "2",
                        },
                      ]}
                      placeholder={translations["dropdown"].select[language]}
                      isSearchable={false}
                      value={
                        [
                          {
                            label:
                              translations.commonWords.internalUsers[language],
                            value: "1",
                          },
                          {
                            label:
                              translations.commonWords.externalUsers[language],
                            value: "2",
                          },
                        ].find((option) => option.value === typeuser) || {
                          value: "",
                          label: translations["dropdown"].select[language],
                        }
                      }
                      onChange={(selectedOption) => {
                        settypeuser(selectedOption.value);
                        filterUsers(selectedOption.value);
                      }}
                      // maxMenuHeight={"120px"}
                      styles={{
                        input: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          backgroundColor: isDarkMode ? "transparent" : "white",
                          border: state.isFocused
                            ? "1px solid #80bdff"
                            : isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6", // Adjust the border color for focus
                          boxShadow: state.isFocused
                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                            : "none", // Bootstrap's box shadow for focus
                          borderRadius: ".45rem",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none", // Hide the indicator separator
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                          color: isDarkMode ? "#ffffff" : "#868686",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black",
                          backgroundColor: isDarkMode ? "#1f2023" : "white",
                          margin: "0", // Remove default margin
                          zIndex: "999",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                          // Additional styles for placeholder
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: isDarkMode ? "#ffffff" : "black",
                          backgroundColor: state.isHovered
                            ? "#80bdff"
                            : isDarkMode
                            ? "#1f2023"
                            : "white",
                          ":hover": {
                            backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                          },
                        }),
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
          <div class="headersec">
            <div class="d-flex mb-2">
              <input
                type="text"
                style={{
                  borderColor: "#dee2e6",
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                }}
                autocomplete="off"
                className={`form-control ${isDarkMode ? "dark-mode" : ""}`}
                id="searchInput"
                placeholder={
                  translations.commonFields.searchbar.search[language]
                }
                value={key}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
              {activeSearch && (
                <button
                  className="btn bi bi-x-lg searchBtn"
                  style={{ padding: "6px 8px" }}
                  onClick={handleClearFilters}
                ></button>
              )}
              <button
                className="btn bi bi-search searchBtn"
                style={{ padding: "6px 8px" }}
                onClick={() => {
                  setPageCache((prevCache) => ({
                    ...prevCache,
                    pageNo: pageNo,
                  }));
                  setPageNo(1);
                  setSearchCache({});
                  if (key) {
                    handleSearch(1, true);
                  }
                }}
              ></button>
            </div>
          </div>
          <div
            // class="bodysecsearch"
            ref={containerRef}
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
              maxHeight: "calc(100vh - 255px)",
            }}
          >
            <table class="table table-hover align-middle text-center">
              <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                <tr>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.commonFields.name[language]}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.commonFields.email[language]}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.usersPage.createUser.userType[language]}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.usersPage.createUser.status[language]}
                  </th>
                  {/* <th scope="col"></th> */}
                  {((userData &&
                    userData.roles[0].permissions.some(
                      (item) => item.name === "Edit User"
                    )) ||
                    (userData &&
                      userData.roles[0].permissions.some(
                        (item) => item.name === "Delete User"
                      ))) && (
                    <th
                      scope="col"
                      colSpan={
                        userData &&
                        userData.roles[0].permissions.some(
                          (item) => item.name === "Delete User"
                        )
                          ? 3
                          : 2
                      }
                      // colSpan={internalUsers.includes(userType) ? 3 : 1}
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      Actions
                    </th>
                  )}
                  {/* <th scope="col"></th> */}
                </tr>
              </thead>
              <tbody id="searchResults">
                {usersData &&
                  usersData.map((user, index) => {
                    return (
                      <tr class="result-box">
                        <th class="custID" scope="row">
                          {user.id}
                        </th>
                        <td class="dlrname">{user.name}</td>
                        <td class="salesrep">{user.email}</td>
                        <td class="semainesearch">
                          {user.user_type === "1"
                            ? `${
                                translations.commonWords.internalUsers[language]
                              } (${user.roles[0]?.name || " "})`
                            : `${
                                translations.commonWords.externalUsers[language]
                              } (${user.roles[0]?.name || " "})`}
                        </td>
                        <td
                          class="semainesearch"
                          id={
                            user.account_activate === "Yes"
                              ? "statusActive"
                              : "statusInactive"
                          }
                        >
                          {user.account_activate === "Yes"
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td>
                          {
                            user?.roles[0]?.name !== "Employee" && (
                              <button
                                type="button"
                                class="btn btn-light copy-button"
                                data-customer-id="405"
                                onClick={() => handleResend(user.email)}
                              >
                                <i class="bi bi-send"></i>{" "}
                                {
                                  translations.commonFields.resendEmail[
                                    language
                                  ]
                                }
                              </button>
                            )}
                        </td>
                        {((userData &&
                          userData.roles[0].permissions.some(
                            (item) => item.name === "Edit User"
                          )) ||
                          (userData &&
                            userData.roles[0].permissions.some(
                              (item) => item.name === "Delete User"
                            ))) && (
                          <>
                            {userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Edit User"
                              ) && (
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-light edit-button"
                                    data-customer-id="405"
                                    onClick={() => {
                                      setDisplayComponent("edit-event");

                                      window.history.pushState(
                                        {
                                          id: "gallery",
                                          randomData: window.Math.random(),
                                        },
                                        "title",
                                        "/event-portal?page=edit-user&user_id=" +
                                          user.id
                                      );
                                    }}
                                  >
                                    <i class="bi bi-pencil me-1"></i>
                                    {
                                      translations.commonFields.editBtn[
                                        language
                                      ]
                                    }
                                  </button>
                                </td>
                              )}
                            {userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Delete User"
                              ) && (
                                <td style={{ width: "170px" }}>
                                  <div class="btn-group" role="group">
                                    {deleteStates && deleteStates[index] ? (
                                      <>
                                        <button
                                          type="button"
                                          class="btn btn-success delete-button"
                                          data-customer-id="405"
                                          onClick={() =>
                                            handleDelete(user.id, index)
                                          }
                                        >
                                          {
                                            translations.commonFields.confirm[
                                              language
                                            ]
                                          }
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-secondary cancel-button"
                                          onClick={() =>
                                            setDeleteStates((prevStates) => {
                                              const newStates = [...prevStates];
                                              newStates[index] = false;
                                              return newStates;
                                            })
                                          }
                                        >
                                          {
                                            translations.commonFields.cancel[
                                              language
                                            ]
                                          }
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        type="button"
                                        class="btn btn-danger before-delete-button"
                                        dataset-button-state="delete"
                                        onClick={() => {
                                          setDeleteStates((prevStates) => {
                                            const newStates = [...prevStates];
                                            newStates[index] = true;
                                            return newStates;
                                          });
                                        }}
                                      >
                                        <i class="bi bi-trash"></i>{" "}
                                        {
                                          translations.commonFields.delete[
                                            language
                                          ]
                                        }
                                      </button>
                                    )}
                                  </div>
                                </td>
                              )}
                          </>
                        )}
                      </tr>
                    );
                  })}
                {isFetching && (
                  <tr className="skeleton-loader">
                    <th className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </th>
                    <td className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </td>
                    <td className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </td>
                    <td className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </td>
                    <td className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </td>
                    <td className="p-2"></td>
                    <td className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </td>
                    <td className="p-2">
                      <div
                        className={isDarkMode ? "skeleton-dark" : "skeleton"}
                      ></div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div ref={bottomMarkerRef} style={{ height: "1px" }} />
          </div>
          {/* {total !== 0 && total > 10 && (
            <PaginatedItems
              total={total}
              pageNo={pageNo}
              setPageNo={setPageNo}
              handlePageChange={handlePageChange}
              isDarkMode={isDarkMode}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Users;
