import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import BulkUpload from "./bulkuploadModal";
import { ApiUrl } from "../ApiUrl";
import { toast } from "react-toastify";
import Loader from "../loader";
import ClientsFilterbar from "./clientsFilterbar";
import { internalUsers } from "../constants/userTypes";
import { format } from "date-fns";
import * as XLSX from "xlsx";
import DraggableHeaderCell from "./EventsPage/DraggableHeaderCell";
import { horizontalListSortingStrategy } from "@dnd-kit/sortable";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { debounce } from "lodash";
import AddViewModal from "./EventsPage/AddViewModal";
import useGetViewsData from "../hooks/useGetUserData";

const Clients = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  seListId,
  setIsLoading,
}) => {
  var button = document.getElementById("openViewModal");
  const { language } = useLanguage();
  const userType = localStorage.getItem("userType");
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor));
  const clientsFilterData = JSON.parse(
    localStorage.getItem("clientsFilterData")
  );
  const clientsFilterPostData = JSON.parse(
    localStorage.getItem("clientsFilterPostData")
  );
  const [deleteStates, setDeleteStates] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [listIds, setListIds] = useState([]);
  const [configIds, setConfigIds] = useState([]);
  const [checkedFields, setCheckedFields] = useState(clientsFilterData || []);
  const token = localStorage.getItem("token");
  const [filtersData, setFiltersData] = useState(clientsFilterPostData || []);
  const [dealershipDetail, setDealershipDetail] = useState("");
  const [callStatusOptions, setCallStatusOptions] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [prospectiveType, setProspectiveType] = useState("");
  

  const getColumns = () => {
    return {
      name: "Default View",
      current: true,
      type: "default client",
      csv: true,
      xlsx: true,
      columns: [
        {
          id: "id",
          content: "ID",
          style: {},
          className: "text-center",
          width: 40,
          renderInView: true,
        },
        {
          id: "prospect_type",
          content: translations.clients.table.prospectiveType[language],
          minWidth: language === "en" ? "145px" : "150px",
          width: language === "en" ? 145 : 170,
          renderInView: true,
        },
        {
          id: "prospect_source",
          content: translations.createProspective.prospectiveSource[language],
          minWidth: language === "en" ? "140px" : "165px",
          width: language === "en" ? 160 : 185,
          renderInView: true,
        },
        {
          id: "first_name",
          content: translations.commonFields.firstName[language],
          style: { minWidth: "100px" },
          width: 120,
          renderInView: true,
        },
        {
          id: "last_name",
          content: translations.commonFields.lastName[language],
          width: language === "en" ? 120 : 165,
          style: {
            minWidth: language === "en" ? "100px" : "135px",
          },
          renderInView: true,
        },
        {
          id: "phone",
          content: translations.quickCreationModal.phoneNumber[language],
          width: language === "en" ? 150 : 200,
          style: {
            minWidth: language === "en" ? "130px" : "180px",
          },
          renderInView: true,
        },
        {
          id: "office_phone",
          content: translations.createProspective.officeNumber[language],
          style: { minWidth: language === "en" ? "130px" : "155px" },
          width: language === "en" ? 150 : 185,
          renderInView: true,
        },
        {
          id: "email",
          content: translations.commonFields.email[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "og_rep",
          content: translations.commonWords.usersTypes.representative[language],
          style: {
            // minWidth: language === "en" ? "150px" : "150px",
            // maxWidth: language === "en" ? "150px" : "150px",
            overflow: "hidden", // Hide content that exceeds the boundaries
            textOverflow: "ellipsis", // Display ellipsis (...) for overflowed text
            whiteSpace: "nowrap",
          },
          width: 150,
          renderInView: true,
        },
        {
          id: "call_status",
          content: translations.clients.table.callStatus[language],
          width: 150,
          renderInView: true,
        },
        {
          id: "make",
          content: translations.createProspective.make[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "model",
          content: translations.createProspective.model[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "year",
          content: translations.usersPage.createUser.year[language],
          width: language === "en" ? 95 : 90,
          style: {
            minWidth: language === "en" ? "95px" : "90px",
          },
          renderInView: true,
        },
        {
          id: "km",
          content: "KM",
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "address",
          content: translations.commonFields.address[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "city",
          content: translations.commonFields.city[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "province",
          content: translations.commonFields.province[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "postal_code",
          content:
            translations.forms.formFields.conquestSection.postalCode[language],
          width: 150,
          style: {
            minWidth: 150,
          },
          renderInView: true,
        },
        {
          id: "appointment",
          content: translations.appointments.appointment[language],
          width: language === "fr" ? 150 : 100,
          style: {
            minWidth: language === "fr" ? 150 : 100,
          },
          renderInView: true,
        },
        {
          id: "mobile_phone",
          content: translations.commonFields.mobileNumber[language],
          width: 150,
          style: {
            minWidth: 150,
          },
          renderInView: true,
        },
        {
          id: "language",
          content: translations.commonFields.language[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "vin",
          content: translations.createProspective.VIN[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "purchase_date",
          content: translations.createProspective.purchaseDate[language],
          width: 150,
          style: {
            minWidth: 150,
          },
          renderInView: true,
        },
        {
          id: "last_service_date",
          content: translations.createProspective.lastServiceDate[language],
          width: language === "fr" ? 180 : 150,
          style: {
            minWidth: language === "fr" ? 180 : 150,
          },
          renderInView: true,
        },

        {
          id: "greetings",
          content: translations.createProspective.greetings[language],
          width: 100,
          style: {
            minWidth: 100,
          },
          renderInView: true,
        },
        {
          id: "confirmation_status",
          content: translations.createProspective.confirmationStatus[language],
          style: {
            minWidth: language === "en" ? "170px" : "195px",
          },
          width: language === "en" ? 190 : 225,
          renderInView: true,
        },
        {
          id: "confirmation_notes",
          content: translations.createProspective.confirmationNotes[language],
          style: {
            minWidth: language === "en" ? "165px" : "195px",
          },
          width: language === "en" ? 195 : 225,
          renderInView: true,
        },
        {
          id: "visit_status",
          content: translations.createProspective.visitStatus[language],
          width: language === "en" ? 150 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "visit_notes",
          content: translations.createProspective.visitNotes[language],
          width: language === "en" ? 150 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "no_show_status",
          content: translations.clients.table.noShowStatus[language],
          width: language === "en" ? 170 : 225,
          style: {
            minWidth: language === "en" ? "170px" : "195px",
          },
          renderInView: true,
        },
        {
          id: "no_show_notes",
          content: translations.createProspective.noShowNotes[language],
          width: language === "en" ? 150 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "appointment_time",
          content: translations.clients.table.appointmentTime[language],
          width: language === "en" ? 190 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "appointment_date",
          content: translations.clients.table.appointmentDate[language],
          width: language === "en" ? 220 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "appointment_comment",
          content: translations.createProspective.appointmentComment[language],
          width: language === "en" ? 220 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        // {
        //   id: "appointment_user_name",
        //   content: translations.forms.formFields.animator[language],
        //   width: 150,
        //   renderInView: true,
        // },
        {
          id: "actions",
          content:
            translations.EventsPortal.eventsTableHeader.actions[language],
          style: {
            position: "-webkit-sticky",
            position: "sticky",
            right: 0,
          },
          width: 100,
          className: "text-center",
          colSpan: 3,
          renderInView: true,
        },
      ],
    };
  };
  const data = useGetViewsData("client");
  const [tableViews, setTableViews] = useState([]);
  const [currentView, setCurrentView] = useState(getColumns());
  const [viewData, setViewData] = useState(null);
  const columnWidthsRef = useRef({});
  const pendingStateUpdate = useRef(false);

  const allfields = [
    {
      content: translations.commonFields.firstName[language],
      name: "first_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.lastName[language],
      name: "last_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.phone[language],
      name: "phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.officeNumber[language],
      name: "office_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.mobileNumber[language],
      name: "mobile_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.email[language],
      name: "email",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.make[language],
      name: "make",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.model[language],
      name: "model",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.usersPage.createUser.year[language],
      name: "year",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: "KM",
      name: "km",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.clients.table.callStatus[language],
      name: "call_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.address[language],
      name: "address",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.city[language],
      name: "city",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.province[language],
      name: "province",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "Activix ID",
      name: "activix_id",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.prospectiveType[language],
      name: "prospective_type",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.forms.formFields.conquestSection.postalCode[language],
      name: "postal_code",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.appointments.appointment[language],
      name: "appointment",
      type: "dropdown",
      filterType: "",
      value: "",
    },

    {
      content: translations.commonFields.language[language],
      name: "language",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.VIN[language],
      name: "vin",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.purchaseDate[language],
      name: "purchase_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.lastServiceDate[language],
      name: "last_service_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospectiveSource[language],
      name: "prospect_source",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.greetings[language],
      name: "greetings",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "OG Rep",
      name: "og_rep",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const appointmentOption = [
    { label: translations.dropdown.yes[language], value: "1" },
    { label: translations.dropdown.no[language], value: "0" },
  ];
  const prospectiveSourceOptions2 = [
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt1[language],
      value: translations.createProspective.prospectiveSourceOptions.opt1["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt2[language],
      value: translations.createProspective.prospectiveSourceOptions.opt2["en"],
    },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt3[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt3["en"],
    // },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt4[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt4["en"],
    // },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt5[language],
      value: translations.createProspective.prospectiveSourceOptions.opt5["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt6[language],
      value: translations.createProspective.prospectiveSourceOptions.opt6["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt7[language],
      value: translations.createProspective.prospectiveSourceOptions.opt7["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt8[language],
      value: translations.createProspective.prospectiveSourceOptions.opt8["en"],
    },
  ];

  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));
  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "Wants callback",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "Message left",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields?.filter(
            (field) => data?.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    if (token && eventId) {
      fetchData();
      getExternalUsers();
      // getListIds();
    }
    if (data?.user_views) {
      setTableViews([...data?.user_views]);
      const currentViewObj = data?.user_views.find(
        (item) => item.current === "1"
      );

      setCurrentView({
        ...currentViewObj,
        columns: currentViewObj?.columns || [],
      });
    }
    // getFiltersData();
  }, [eventId, data]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + `/api/user/prospective-index/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          clientsFilterData && clientsFilterData?.length > 0
            ? {
                ...clientsFilterPostData,
                private_event_id: eventId,
                all_prospects: false,
              }
            : {
                private_event_id: eventId,
                all_prospects: false,
              }
        ),
      });
      const data = await res.json();
      if (data.status === "success") {
        // const newData = data.private_events.data;
        const callOptions =
          data.prospectives && data.prospectives.length > 0
            ? Array.from(
                new Set(
                  data.prospectives
                    .filter((item) => item?.call_status != null) // Filter out null or undefined call_status
                    .map((item) => item.call_status.toString()) // Map to call_status strings
                )
              ).map((call_status) => ({
                label: call_status,
                value: call_status,
              }))
            : [];

        setClients(data.prospectives);
        setCallStatusOptions(callOptions);
        seListId({ list_id: data.liste_id, list_name: data.list_name });
        setDealershipDetail(data?.dealershipDetails);
        setDeleteStates(data.prospectives.map(() => false));

        // autoScrollMid();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setLoading(false);
    }
  };

  const getExternalUsers = async () => {
    // setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        const typeOptions = data?.prospective_type?.length
          ? data?.prospective_type?.map((list) => ({
              label: list,
              value: list,
            }))
          : [];

        setProspectiveType(typeOptions);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setIsLoading(false);
    }
  };
  const getListIds = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/list-ids/" + eventId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        const configs =
          data?.config_ids?.lists &&
          data?.config_ids?.lists.length > 0 &&
          data?.config_ids?.lists.map((list) => {
            return {
              label: list?.calendar_config_name,
              value: list?.calendar_config_id,
            };
          });
        const lists =
          data?.list_ids?.lists &&
          data?.list_ids?.lists.length > 0 &&
          data?.list_ids?.lists.map((list) => {
            return { label: list?.list_name, value: list?.list_id };
          });

        setListIds(lists);
        setConfigIds(configs);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id, index) => {
    setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/prospectives/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success("Dealership deleted successful");
        const newData = clients.filter((client) => client.id !== id);
        setClients(newData);
        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleCheckboxChange = (clientId) => {
    setSelectedClients(
      (prevSelected) =>
        prevSelected.includes(clientId)
          ? prevSelected.filter((id) => id !== clientId) // Deselect if selected
          : [...prevSelected, clientId] // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedClients([]);
    } else {
      // Select all appointments' IDs
      const allClientIds = clients.map((client) => client.id);
      setSelectedClients(allClientIds);
    }
    setSelectAll(!selectAll); // Toggle the state of selectAll
  };
  const exportToExcel = () => {
    // Filter the selected appointments based on their IDs
    const selectedData = clients
      .filter((client) => selectedClients.includes(client.id)) // Only include selected appointments
      .map((client) => ({
        Event_ID: client?.private_event?.nom_vp || "",
        Prospect_Type: client?.prospect_type || "",
        Prospect_Source: client?.prospect_source || "",
        First_Name: client?.first_name || "",
        Last_Name: client?.last_name || "",
        Phone: client?.phone || "",
        Office_Number: client?.office_phone || "",
        Representative: client?.og_rep || "",
        Call_Status: client?.call_status || "",
        Make: client?.make || "",
        Model: client?.model || "",
        Year: client?.year || "",
        Confirmation_Status: client?.appointments[0]?.confirmation_status || "",
        Confirmation_Notes: client?.appointments[0]?.confirmation_notes || "",
        Visit_Status: client?.appointments[0]?.visit_status || "",
        Visit_Notes: client?.appointments[0]?.visit_notes || "",
        No_Show_Status: client?.appointments[0]?.no_show_status || "",
        No_Show_Notes: client?.appointments[0]?.no_show_notes || "",

        appointment_time: client?.appointments[0]?.appointment_time || "",
        appointment_date: client?.appointments[0]?.appointment_date || "",
        appointment_comment: client?.appointments[0]?.appointment_comment || "",
        appointment_rep_user_name:
          client?.appointments[0]?.appointment_rep_user_name || "",
        Province: client?.province || "",
        Purchase_Date: client?.purchase_date || "",
        VIN: client?.vin || "",
        Visit_Status_User: client?.visit_status_user || "",
        Email: client?.email || "",
        Greetings: client?.greetings || "",
        Client_ID: client?.id || "",
        KM: client?.km || "",
        Language: client?.language || "",
        List_ID: client?.list_id || "",
        Mobile_Phone: client?.mobile_phone || "",
        Postal_Code: client?.postal_code || "",
        Address: client?.address || "",
        Appointment: client?.appointment || "",
        City: client?.city || "",
      }));

    // Generate the Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Clients");

    // Save the file
    XLSX.writeFile(
      workbook,
      `Clients Report ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };
  const exportData = (fileType) => {
    if (!currentView || !currentView.columns) {
      console.error("Current view is not set or missing columns.");
      return;
    }

    // Extract column keys from `currentView.columns`
    const selectedColumns = currentView.columns
      .slice(0, -1)
      .filter((item) => item.renderInView !== false)
      .map((col) => ({
        key: col.id, // Assuming column `id` matches the data field name
        header: col.content || col.id, // Column name in the exported file
      }));

    const selectedData =
      clients && clients.length > 0
        ? clients.map((client) => {
            let rowData = {};

            // Add only the selected columns with proper transformations
            selectedColumns.forEach(({ key, header }) => {
              let value = "";

              // Apply transformation logic based on columnKey
              if (key === "prospect_source") {
                if (client?.prospect_source) {
                  const matchedOption = prospectiveSourceOptions.find(
                    (option) =>
                      option.value.en?.toLowerCase() ===
                        client?.prospect_source?.toLowerCase() ||
                      option.value.fr?.toLowerCase() ===
                        client?.prospect_source?.toLowerCase()
                  );
                  value = matchedOption
                    ? matchedOption.label[language]
                    : client?.prospect_source;
                }
              } else if (key === "confirmation_status") {
                value = getTranslatedConfirmationStatus(
                  client?.appointments?.[0]
                )?.confirmation_status;
              } else if (key === "confirmation_notes") {
                value = client?.appointments?.[0]?.confirmation_notes || "";
              } else if (key === "visit_status") {
                value = getTranslatedVisitStatus(
                  client?.appointments?.[0]?.visit_status
                );
              } else if (key === "visit_notes") {
                value = client?.appointments?.[0]?.visit_notes || "";
              } else if (key === "no_show_status") {
                value = getTranslatedNoShowStatus(
                  client?.appointments?.[0]?.no_show_status
                );
              } else if (key === "no_show_notes") {
                value = client?.appointments?.[0]?.no_show_notes || "";
              } else if (key === "appointment") {
                value = client[key] === 0 || client[key] === "0" ? "No" : "Yes";
              } else {
                value = client?.appointments?.[0]?.[key] || client[key] || "";
              }

              rowData[header] = value;
            });

            return rowData;
          })
        : [];

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");

    // Determine file type (CSV or XLSX)
    if (fileType === "csv") {
      const csvData = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Export_Clients_${format(
        new Date(),
        "dd MMMM yyyy"
      )}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      XLSX.writeFile(
        workbook,
        `Export_Clients_${format(new Date(), "dd MMMM yyyy")}.xlsx`
      );
    }
  };

  const getFilteredEvents = async (filterPost, checkedFieldsData) => {
    // setIsLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/prospective-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          private_event_id: eventId,
          ...filterPost,
          all_prospects: false,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        localStorage.setItem(
          "clientsFilterPostData",
          JSON.stringify(filterPost)
        );
        localStorage.setItem(
          "clientsFilterData",
          JSON.stringify(checkedFieldsData)
        );
        setClients(data.prospectives);
        setDeleteStates(data.prospectives.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  const getTranslatedConfirmationStatus = (status) => {
    const option = confirmationStatusOptions.find(
      (opt) => opt.value === status
    );
    return option ? option.label : status;
  };
  const getTranslatedVisitStatus = (status) => {
    const option = visitStatusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const getTranslatedNoShowStatus = (status) => {
    const option = noShowOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const moveColumn = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = currentView.columns.findIndex(
      (col) => col.id === active.id
    );
    const newIndex = currentView.columns.findIndex((col) => col.id === over.id);

    const reorderedColumns = arrayMove(currentView.columns, oldIndex, newIndex);
    const updatedData = { ...currentView, columns: reorderedColumns };
    setCurrentView((prevView) => ({ ...prevView, columns: reorderedColumns }));
    if (updatedData.type !== "default client") {
      updateView(updatedData);
    }
  };

  const handleResize = (columnId, newWidth) => {
    columnWidthsRef.current[columnId] = newWidth;
    // const newColumns = columns.map((col) =>
    //   col.id === columnId ? { ...col, width: newWidth } : col
    // );
    // setColumns(newColumns);
    // Immediate visual update
    requestAnimationFrame(() => {
      const columnElement = document.getElementById(columnId);
      if (columnElement) columnElement.style.width = `${newWidth}px`;
    });
    // Mark that a state update is needed
    if (!pendingStateUpdate.current) {
      pendingStateUpdate.current = true;
      debouncedUpdate(setCurrentView);
    }
  };

  // Debounce for smoother updates instead of throttle
  const debouncedUpdate = debounce((setCurrentView) => {
    const updatedData = {
      ...currentView,
      columns: currentView.columns.map((col) =>
        col.id in columnWidthsRef.current
          ? { ...col, width: columnWidthsRef.current[col.id] }
          : col
      ),
    };
    setCurrentView((prevColumns) => ({
      ...prevColumns,
      columns: prevColumns.columns.map((col) =>
        col.id in columnWidthsRef.current
          ? { ...col, width: columnWidthsRef.current[col.id] }
          : col
      ),
    }));
    if (updatedData.type !== "default client") {
      updateView(updatedData);
    }
    pendingStateUpdate.current = false; // Reset flag after update
  }, 16); // Adjust delay as needed

  const updateView = async (updatedData) => {
    try {
      const res = await fetch(ApiUrl + "/api/user/views", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data?.message);
        const viewData2 = {
          ...data.userView,
          columns: JSON.parse(data.userView.columns),
        };
        setTableViews((prev) =>
          prev.map((item) => {
            if (item.id === updatedData.id) {
              return viewData2;
            }
            return item;
          })
        );
      } else {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => debouncedUpdate.cancel();
  }, [data]);

  return (
    <div>
      {!loading ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <BulkUpload
              isDarkMode={isDarkMode}
              eventId={eventId}
              listIds={listIds}
              configIds={configIds}
              getListIds={getListIds}
              fetchData={fetchData}
            />
            <ClientsFilterbar
              isDarkMode={isDarkMode}
              allfields={allfields}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              textOptions={textOptions}
              textOptions2={textOptions2}
              appointmentOption={appointmentOption}
              prospectiveSourceOptions={prospectiveSourceOptions2}
              callStatusOptions={callStatusOptions}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              // filterOption={filterOption}
              // setFilterOption={setFilterOption}
              // dealerOptions={dealerOptions}
              // repOptions2={repOptions2}
              // managers={managers}
              // packageOptions={packageOptions}
              // animatorOptions={animatorOptions}
              getFilteredEvents={getFilteredEvents}
              prospectiveType={prospectiveType}
              // weekOptions={weekOptions}
            />
            <AddViewModal
              isDarkMode={isDarkMode}
              getColumns={
                (data?.user_views &&
                  data.user_views?.find(
                    (item) => item.type === "default client"
                  )) ||
                {}
              }
              setTableViews={setTableViews}
              setIsLoading={setIsLoading}
              viewData={viewData}
              setViewData={setViewData}
              setCurrentView={setCurrentView}
              tableType={"client"}
            />
            <div className="row align-items-center justify-content-between pb-2">
              <div className="col-7 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.clients.title[language]} {" - "}{" "}
                    {dealershipDetail?.name}
                    {": "}
                    {dealershipDetail?.start_date}{" "}
                    {translations.commonWords.to[language]}{" "}
                    {dealershipDetail?.end_date}
                  </h2>
                </div>
              </div>
              <div className="col-5 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("event-view");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=event-view&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("appointments");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=appointments&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className=""
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    style={{
                      background: "#05cc9e",
                      // color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>
                <div
                  className="btn-group d-flex align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary d-flex align-items-center gap-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("copy-client");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=copy-client&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-clipboard-plus"></i>{" "}
                    {translations.forms.formFields.copyClient[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("eventReport");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=eventReport&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex gap-3 align-items-center">
                <div
                  className="row align-items-center"
                  // style={{ minHeight: "44px" }}
                >
                  <div className="col-3">
                    {(userData.roles[0].name === "Super Admin" ||
                      userData.roles[0].name === "Admin") && (
                      <>
                        {selectedClients && selectedClients.length > 0 && (
                          <button
                            type="button"
                            className="client-btn d-flex align-items-center gap-2"
                            onClick={exportToExcel}
                          >
                            <i className="bi bi-upload"></i>
                            {translations.commonWords.export[language]}{" "}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("create-prospective");
                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=create-prospective&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className={`bi bi-clipboard-plus`}></i>{" "}
                    {translations.commonFields.addBtn[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  {userType &&
                    internalUsers.includes(userType) &&
                    userType !== "Animator" && (
                      <button
                        type="button"
                        className="btn admin-btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#bulkUploadModal"
                        id="add-dlr-trigger"
                      >
                        <i className="bi bi-upload"></i>{" "}
                        {translations.commonFields.bulkUpload[language]}
                      </button>
                    )}
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    id=""
                    type="button"
                    className="btn admin-btn btn-primary"
                    data-bs-target="#clientFiltersidebar"
                    data-bs-toggle="offcanvas"
                    aria-bs-controls="offcanvasmain"
                  >
                    <i className="bi bi-search"></i>{" "}
                  </button>
                </div>
                {currentView &&
                  (currentView?.csv === "1" ||
                    currentView?.csv === true ||
                    currentView?.xlsx === "1" ||
                    currentView?.xlsx === true) && (
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <div className="btn-group" role="group">
                        {(currentView?.xlsx === true ||
                          currentView?.xlsx === "1") && (
                          <button
                            type="button"
                            className="btn admin-btn btn-primary edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            onClick={() => {
                              exportData("xlsx");
                            }}
                          >
                            <i className={"bi bi-filetype-xlsx"}></i>{" "}
                          </button>
                        )}
                        {(currentView?.csv === true ||
                          currentView?.csv === "1") && (
                          <button
                            type="button"
                            className="btn btn-danger edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                            style={{
                              width: "100%",
                              maxWidth: "150px",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            onClick={() => {
                              exportData("csv");
                            }}
                          >
                            <i className={"bi bi-filetype-csv"}></i>{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ width: "125px" }}
                  >
                    {/* {translations.EventsPortal.views.default[language]} */}
                    {currentView?.name}
                  </button>
                  <ul
                    className="dropdown-menu"
                    style={{
                      minWidth: "250px",
                      background: isDarkMode ? "#2b3035" : "#FFFFFF",
                    }}
                  >
                    <li style={{ maxHeight: "200px", overflow: "auto" }}>
                      {tableViews &&
                        tableViews.map((view, index) => (
                          <li
                            className="w-100"
                            key={index}
                            style={{
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#343A3F"
                                  : "#34DBB4",
                              },
                            }}
                          >
                            <div
                              className={`dropdown-item ${
                                isDarkMode ? "views-hover-dark" : "views-hover"
                              }  px-2 d-flex align-items-center justify-content-between w-100`}
                              href="#"
                              style={{
                                ":hover": {
                                  background: isDarkMode
                                    ? "#343A3F"
                                    : "#34DBB4",
                                },
                              }}
                            >
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const updatedData = tableViews.map((item) =>
                                    item.id === view.id
                                      ? { ...item, current: true }
                                      : { ...item, current: false }
                                  );
                                  setTableViews(updatedData);
                                  setCurrentView({ ...view, current: true });
                                  updateView({ ...view, current: true });
                                }}
                              >
                                {view.name}{" "}
                              </p>
                              <div
                                className="d-flex align-items-center gap-2"
                                style={{ fontSize: "18px" }}
                              >
                                <i
                                  className="bi bi-star-fill"
                                  style={{
                                    color:
                                      currentView?.id === view.id
                                        ? "gold"
                                        : "#adb5bd",
                                  }}
                                ></i>{" "}
                                <i
                                  className="bi bi-pencil-fill"
                                  style={{
                                    color:
                                      view.type === "default client"
                                        ? "#adb5bd"
                                        : "#445775",
                                    cursor:
                                      view.type === "default client"
                                        ? "default"
                                        : "pointer",
                                  }}
                                  onClick={() => {
                                    if (view.type !== "default client") {
                                      setViewData({ ...view, mode: "edit" });
                                      button.click();
                                    }
                                  }}
                                ></i>{" "}
                                <i
                                  className="bi bi-trash-fill"
                                  style={{
                                    color:
                                      currentView?.id === view?.id ||
                                      view.type === "default client"
                                        ? "#adb5bd"
                                        : "#dc3545",
                                    cursor:
                                      currentView?.id === view?.id ||
                                      view.type === "default client"
                                        ? "default"
                                        : "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      currentView?.id !== view?.id &&
                                      view.type !== "default client"
                                    ) {
                                      setViewData({
                                        ...view,
                                        mode: "delete",
                                      });
                                      button.click();
                                    }
                                  }}
                                ></i>{" "}
                              </div>
                            </div>
                          </li>
                        ))}
                    </li>

                    <li className="px-2 mt-2 w-100">
                      <button
                        type="button"
                        className="btn btn-light w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#addViewModal"
                        fdprocessedid="2rud2c"
                        id="openViewModal"
                      >
                        <i className="bi bi-plus"></i>{" "}
                        {translations.EventsPortal.views.addNewView[language]}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={moveColumn}
            >
              <SortableContext
                items={
                  currentView &&
                  currentView?.columns?.slice(1, -1)?.map((col) => col.id)
                }
                strategy={horizontalListSortingStrategy}
              >
                <div
                  // className="pt-4"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   justifyContent: "space-between",
                  // }}
                  // ref={containerRef}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "auto",
                    justifyContent: "space-between",
                    height: "calc(100vh - 260px)",
                  }}
                >
                  <table
                    // className="table table-hover text-center align-middle"
                    className="align-content-center align-middle"
                    style={{
                      minWidth: "100%",
                      borderCollapse: "collapse",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead
                      style={{ position: "sticky", top: "0", zIndex: "1" }}
                    >
                      <tr
                        style={{
                          background: isDarkMode ? "#1b1f22" : "#ffffff",
                        }}
                      >
                        {userData &&
                          (userData.roles[0].name === "Super Admin" ||
                            userData.roles[0].name === "Admin") && (
                            <th
                              scope="col"
                              className={`${isDarkMode ? "darkthead" : ""}`}
                            >
                              {
                                <input
                                  type="checkbox"
                                  className=""
                                  checked={selectAll}
                                  onChange={handleSelectAllChange}
                                />
                              }
                            </th>
                          )}
                        {
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""} ${
                              currentView?.columns[0]?.className || ""
                            }`}
                            style={{
                              ...currentView?.columns[0]?.style,
                              width: `${currentView?.columns[0]?.width}px`,
                              paddingInline: "56px !important",
                              marginInline: "4px !important",
                              background: isDarkMode ? "#1b1f22" : "#ffffff",
                              // background: isDarkMode ? "#343a3f" : "#ffffff",
                            }}
                            colSpan={currentView?.columns[0]?.colSpan}
                          >
                            {/* <div
                              className="column-content"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className={`p-2 ${
                                  isDarkMode ? "darkthead" : ""
                                }`}
                              > */}
                            {currentView?.columns[0]?.content}
                            {/* </span>
                            </div> */}
                          </th>
                        }
                        {currentView?.columns
                          .slice(1, -1)
                          .filter((header) => header.renderInView !== false)
                          .map((header) =>
                            header.showIf !== false ? (
                              <DraggableHeaderCell
                                key={header.id}
                                column={header}
                                isDarkMode={isDarkMode}
                                onResize={handleResize}
                              />
                            ) : null
                          )}

                        {currentView?.columns
                          .filter((header) => header.renderInView !== false)
                          .some((column) => column.id === "actions") && (
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""} ${
                              currentView?.columns[
                                currentView?.columns.length - 1
                              ]?.className || ""
                            }`}
                            style={{
                              ...currentView?.columns[
                                currentView.columns.length - 1
                              ]?.style,
                              width: `${
                                currentView?.columns[
                                  currentView?.columns.length - 1
                                ]?.width
                              }px`,
                              paddingInline: "56px !important",
                              marginInline: "4px !important",
                              background: isDarkMode ? "#1b1f22" : "#ffffff",
                              // background: isDarkMode ? "#343a3f" : "#ffffff",
                            }}
                            colSpan={
                              currentView?.columns[
                                currentView?.columns.length - 1
                              ]?.colSpan
                            }
                          >
                            {/* <div
                              className="column-content"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className={`p-2 ${
                                  isDarkMode ? "darkthead" : ""
                                }`}
                              > */}
                            {
                              currentView?.columns[
                                currentView?.columns.length - 1
                              ]?.content
                            }
                            {/* </span>
                            </div> */}
                          </th>
                        )}

                        {/* <th scope="col"></th> */}
                      </tr>
                    </thead>
                    {clients && !loading && (
                      <tbody id="">
                        {clients && clients.length > 0 ? (
                          clients.map((client, index) => {
                            return (
                              <>
                                <tr className="result-box" key={client?.id}>
                                  {userData &&
                                    (userData.roles[0].name === "Super Admin" ||
                                      userData.roles[0].name === "Admin") && (
                                      <td
                                        className="fw-bold"
                                        style={
                                          {
                                            // backgroundColor: "#ced4da66",
                                            // borderTop: "1px solid #dee2e6",
                                            // borderBottom: "1px solid #dee2e6",
                                          }
                                        }
                                      >
                                        {
                                          <input
                                            type="checkbox"
                                            className=""
                                            checked={selectedClients.includes(
                                              client.id
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(client.id)
                                            }
                                          />
                                        }
                                      </td>
                                    )}
                                  <th
                                    className="custID"
                                    scope="row"
                                    style={{ paddingInline: "25px" }}
                                  >
                                    {client?.id}
                                  </th>
                                  {currentView &&
                                    currentView?.columns &&
                                    currentView?.columns
                                      .slice(1, -1)
                                      .filter(
                                        (header) =>
                                          header.renderInView !== false
                                      )
                                      .map((col) => {
                                        if (col.showIf === false) return null; // Skip columns with showIf: false

                                        const columnKey = col.id;

                                        return (
                                          <td
                                            className=""
                                            scope="row"
                                            id={columnKey}
                                            key={col.id}
                                            style={col.style}
                                          >
                                            {columnKey === "prospect_source"
                                              ? client?.prospect_source
                                                ? (() => {
                                                    const matchedOption =
                                                      prospectiveSourceOptions.find(
                                                        (option) =>
                                                          option.value.en?.toLowerCase() ===
                                                            client?.prospect_source?.toLowerCase() ||
                                                          option.value.fr?.toLowerCase() ===
                                                            client?.prospect_source?.toLowerCase()
                                                      );

                                                    // Return the label in the current language if a match is found
                                                    return matchedOption
                                                      ? matchedOption.label[
                                                          language
                                                        ]
                                                      : client?.prospect_source;
                                                  })()
                                                : ""
                                              : columnKey ===
                                                "confirmation_status"
                                              ? getTranslatedConfirmationStatus(
                                                  client?.appointments[0]
                                                )?.confirmation_status
                                              : columnKey ===
                                                "confirmation_notes"
                                              ? client?.appointments[0]
                                                  ?.confirmation_notes
                                              : columnKey === "visit_status"
                                              ? getTranslatedVisitStatus(
                                                  client?.appointments[0]
                                                    ?.visit_status
                                                )
                                              : columnKey === "visit_notes"
                                              ? client?.appointments[0]
                                                  ?.visit_notes
                                              : columnKey === "no_show_status"
                                              ? getTranslatedNoShowStatus(
                                                  client?.appointments[0]
                                                    ?.no_show_status
                                                )
                                              : columnKey === "no_show_notes"
                                              ? client?.appointments[0]
                                                  ?.no_show_notes
                                              : columnKey === "appointment"
                                              ? client[columnKey] === 0 ||
                                                client[columnKey] === "0"
                                                ? "No"
                                                : "Yes"
                                              : client?.appointments[0]?.[
                                                  columnKey
                                                ] ||
                                                client[columnKey] ||
                                                ""}
                                          </td>
                                        );
                                      })}

                                  {currentView?.columns
                                    .filter(
                                      (header) => header.renderInView !== false
                                    )
                                    .some(
                                      (column) => column.id === "actions"
                                    ) && (
                                    <>
                                      <td
                                        style={{
                                          background: isDarkMode
                                            ? "#212529"
                                            : "#ffffff",
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          right:
                                            userData &&
                                            userData.roles[0].permissions.some(
                                              (item) =>
                                                item.name ===
                                                "Prospective Destroy"
                                            )
                                              ? language == "fr"
                                                ? "136px"
                                                : "96.25px"
                                              : 0,
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                          //   data-customer-id="405"
                                          //   data-bs-toggle="modal"
                                          //   data-bs-target="#AddDealerModal"
                                          onClick={() => {
                                            setDisplayComponent(
                                              "edit-prospective"
                                            );
                                            //   setEditEventID(event.id);
                                            window.history.pushState(
                                              {
                                                id: "gallery",
                                                randomData:
                                                  window.Math.random(),
                                              },
                                              "title",
                                              "/event-portal?page=edit-prospective&event_id=" +
                                                eventId +
                                                `&client_id=${client?.id}`
                                            );
                                          }}
                                        >
                                          <i className="bi bi-eye"></i>{" "}
                                          {
                                            translations.commonWords.view[
                                              language
                                            ]
                                          }
                                        </button>
                                      </td>
                                      {userData &&
                                        userData.roles[0].permissions.some(
                                          (item) =>
                                            item.name === "Prospective Destroy"
                                        ) && (
                                          <td
                                            style={{
                                              width: "170px",
                                              position: "-webkit-sticky",
                                              position: "sticky",
                                              right: 0,
                                              background: isDarkMode
                                                ? "#212529"
                                                : "#ffffff",
                                            }}
                                          >
                                            <div
                                              className="btn-group"
                                              role="group"
                                            >
                                              {deleteStates[index] ? (
                                                <>
                                                  <button
                                                    type="button"
                                                    className="btn btn-success delete-button"
                                                    data-customer-id="405"
                                                    onClick={() =>
                                                      handleDelete(
                                                        client?.id,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    {
                                                      translations.commonFields
                                                        .confirm[language]
                                                    }
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-secondary cancel-button"
                                                    onClick={() =>
                                                      setDeleteStates(
                                                        (prevStates) => {
                                                          const newStates = [
                                                            ...prevStates,
                                                          ];
                                                          newStates[
                                                            index
                                                          ] = false;
                                                          return newStates;
                                                        }
                                                      )
                                                    }
                                                  >
                                                    {
                                                      translations.commonFields
                                                        .cancel[language]
                                                    }
                                                  </button>
                                                </>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn btn-danger before-delete-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                                  dataset-button-state="delete"
                                                  onClick={() =>
                                                    setDeleteStates(
                                                      (prevStates) => {
                                                        const newStates = [
                                                          ...prevStates,
                                                        ];
                                                        newStates[index] = true;
                                                        return newStates;
                                                      }
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-trash"></i>{" "}
                                                  {
                                                    translations.commonFields
                                                      .delete[language]
                                                  }
                                                </button>
                                              )}
                                            </div>
                                          </td>
                                        )}
                                    </>
                                  )}
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr
                            className="align-middle"
                            style={{
                              position: "relative",
                              // height: "30px",
                              // zIndex: 10 + eventList.length - index,
                            }}
                          >
                            <td
                              style={{
                                background: `${
                                  isDarkMode ? "transparent" : "white"
                                }`,
                                borderStyle: "none",
                              }}
                              colSpan={14}
                              className="text-center py-5"
                            >
                              <h4>
                                {
                                  translations.EventsPortal.noDataMessage[
                                    language
                                  ]
                                }
                              </h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                  {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
                </div>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Clients;
