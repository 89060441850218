import React from "react";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import { ApiUrl } from "../../../ApiUrl";
import { format } from "date-fns";
import { toast } from "react-toastify";

const Send2Rep = ({
  isDarkMode,
  appointmentData,
  eventId,
  setIsLoading,
  repOptions,
}) => {
  const token = localStorage.getItem("token");
  const { language } = useLanguage();
  var button = document.getElementById("closeSend2RepModal");

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/send-sms`;
      const bodyData =
        // values.email === ""
        //   ? {
        //       user_id: values.user_id,
        //       phone: values.phone,
        //       user_language: values.user_language,
        //       event_id: values.event_id,
        //       client_id: values.client_id,
        //       client_name: values.client_name,
        //       appointment_time: values.appointment_time,
        //     }
        //   : 
        {
              ...values,
            };
      const method = "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.mailstatus === "success") {
        toast.success(data.mailMessage);
        // navigate("/event-portal");
      } else {
        // toast.error(data.message);
        toast.error(data.mailMessage);
      }
      if (data.smsstatus === "success") {
        toast.success(data.smsMessage);
      } else {
        toast.error(data.smsMessage);
      }
      if (data.mailstatus === "success" && data.smsstatus === "success") {
        button.click();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };
  

  return (
    <div id="send2RepModal" className="modal fade" tabindex="-1">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        style={{ maxWidth: "500px" }}
      >
        <div className="modal-content">
          <Formik
            initialValues={{
              // user_id: appointmentData.rep_name ? appointmentData.rep_name : "",
              user_id:  "",
              email: "",
              phone: "",
              user_language: "",
              event_id: eventId,
              client_id: appointmentData?.appointment?.prospective?.id,
              client_name:
                (appointmentData?.appointment?.prospective?.first_name ?? "") +
                " " +
                (appointmentData?.appointment?.prospective?.last_name ?? ""),
              appointment_time: appointmentData?.appointment?.appointment_time,
            }}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, values, setValues, resetForm, isSubmitting }) => (
              <Form>
                <div
                  className="d-flex flex-column modal-header align-items-stretch"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  {/* First div with close button aligned to the end */}

                  {/* Second div with heading and another close button justified between */}
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-col w-100">
                      <div>
                        {/* <h3 style={{ color: "#000000" }}>
                        {translations.appointments.appointmentDetails[language]}
                      </h3> */}
                        <h3 className="" style={{ color: "#495057" }}>
                          <i class="bi bi-send-fill me-2"></i>
                          {"Send2Rep"}
                        </h3>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      id="closeSend2RepModal"
                      onClick={() => {
                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((backdrop) => {
                            backdrop.remove();
                          });
                        var createModal = new window.bootstrap.Modal(
                          document.getElementById("editAppointmentModal")
                        );
                        createModal.show();
                      }}
                      style={{
                        filter: isDarkMode ? "invert(1)" : "none",
                        color: isDarkMode
                          ? "#ffffff"
                          : "rgb(255, 255, 255) !important",
                      }}
                    ></button>
                  </div>
                </div>
                <div class="modal-body">
                  {/* <!-- fields: First Name and Last Name from Prospect Table--> */}
                  <h3>
                    <i class="bi bi-person-fill mb-0 me-2"></i>
                    {(appointmentData?.appointment?.prospective?.first_name ?? "") +
                      " " +
                      (appointmentData?.appointment?.prospective?.last_name ?? "")}
                  </h3>
                  <div class="d-flex fs-6 mb-1">
                    {/* <!-- Appointment Date, Appointment Time from appointment Table --> */}
                    <i class="bi bi-calendar"></i>&nbsp;
                    {appointmentData?.appointment?.appointment_date &&
                    appointmentData?.appointment?.appointment_date !== null
                      ? format(
                          appointmentData?.appointment?.appointment_date,
                          "dd/MM/yyyy"
                        )
                      : ""}{" "}
                    &nbsp;&nbsp;
                    <i class="bi bi-clock-fill"></i>&nbsp;
                    {appointmentData?.start && appointmentData?.start !== null
                      ? format(appointmentData?.start, "hh:mm aa")
                      : ""}
                  </div>

                  <hr
                    style={{
                      borderTop: isDarkMode
                        ? "1px solid #ffffff"
                        : "1px solid #000000",
                    }}
                  />

                  <p>
                    {translations.createProspective.send2RepMessage[language]}
                  </p>

                  <div
                    className="d-flex input-group align-items-center my-2"
                    style={{
                      border: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                      borderRadius: ".45rem",
                      // backgroundColor: values.email ? "#ced4da66":""
                    }}
                    
                  >
                    <span
                      // for="annual-sales-count"
                      className="bg-transparent px-2"
                      style={{
                        width: "30%",
                        border: "none",
                        backgroundColor: "#2b3035",
                      }}
                    >
                      {
                        translations.commonWords.usersTypes.representative[
                          language
                        ]
                      }
                    </span>
                    {
                      console.log("kajshkjasfd",values)
                    }
                    <Field
                      name="user_id"
                      render={({ field, form }) => (
                        <>
                      
                        <Select
                          className="selectpicker"
                          isClearable
                          options={repOptions}
                          // isDisabled={values.email}
                          placeholder={
                            translations["dropdown"].select[language]
                          }
                          isSearchable
                          value={
                            (repOptions &&
                              repOptions.length > 0 &&
                              repOptions.find(
                                (option) => option.value === field.value
                              )) || {
                              value: "",
                              label: translations["dropdown"].select[language],
                            }
                          }
                          onChange={(selectedOption) => {
                            form.setFieldValue(
                              field.name,
                              selectedOption?.value
                            );
                          }}
                          maxMenuHeight={"85px"}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: "70%", // Set the container width to 70%
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: isDarkMode
                                ? values?.email ? "#ced4da66":"transparent"
                                : values?.email ? "#ced4da66":"white",
                              border: "none",
                              borderLeft: state.isHovered
                                ? "none"
                                : state.isFocused
                                ? "1px solid #80bdff"
                                : isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6", // Adjust the border color for focus
                              boxShadow: state.isFocused
                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                : "none", // Bootstrap's box shadow for focus
                              // borderRadius: ".45rem",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none", // Hide the indicator separator
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                              color: isDarkMode ? "#ffffff" : "#868686",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                              margin: "0", // Remove default margin
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                              // Additional styles for placeholder
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#34DBB4"
                                  : "#34DBB4",
                              },
                            }),
                          }}
                        />
                        </>
                       
                      )}
                    />
                  </div>
                  <div>
                    <label for="annual-sales-count" className="form-label">
                      {translations.commonFields.email[language]}
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          email: e.target.value,
                          user_id: "",
                        });
                      }}
                    />
                  </div>
                  <div className="pt-1">
                    <label for="annual-sales-count" className="form-label">
                      {translations.commonFields.mobileNumber[language]}
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      className="form-control"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    />
                  </div>
                  <div className="pt-1">
                    <label for="annual-sales-count" className="form-label">
                      {translations["commonFields"].language[language]}
                    </label>
                    <Field
                      name="user_language"
                      render={({ field, form }) => (
                        <Select
                          className="selectpicker"
                          // isClearable
                          options={[
                            {
                              label: translations.commonWords.english[language],
                              value: "en",
                            },
                            {
                              label: translations.commonWords.french[language],
                              value: "fr",
                            },
                          ]}
                          placeholder={
                            translations["dropdown"].select[language]
                          }
                          isSearchable
                          // value={
                          //   (noShowOptions &&
                          //     noShowOptions.length > 0 &&
                          //     noShowOptions.find(
                          //       (option) => option.value === field.value
                          //     )) || {
                          //     value: "",
                          //     label: translations["dropdown"].select[language],
                          //   }
                          // }
                          onChange={(selectedOption) =>
                            form.setFieldValue(field.name, selectedOption.value)
                          }
                          maxMenuHeight={"85px"}
                          styles={{
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: isDarkMode
                                ? "transparent"
                                : "white",
                              border: state.isFocused
                                ? "1px solid #80bdff"
                                : isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6", // Adjust the border color for focus
                              boxShadow: state.isFocused
                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                : "none", // Bootstrap's box shadow for focus
                              borderRadius: ".45rem",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none", // Hide the indicator separator
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                              color: isDarkMode ? "#ffffff" : "#868686",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                              margin: "0", // Remove default margin
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                              // Additional styles for placeholder
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#34DBB4"
                                  : "#34DBB4",
                              },
                            }),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    height: "71px",
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                  class="modal-footer"
                  id="modalfooter"
                >
                  <button
                    type="submit"
                    class="btn btn-primary admin-btn"
                    id="form-send-client"
                    // disabled={!values.user_id}
                  >
                    {translations.commonWords.sendBtn[language]}
                  </button>
                  <button
                    // id="closebtn2"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      var createModal = new window.bootstrap.Modal(
                        document.getElementById("editAppointmentModal")
                      );
                      createModal.show();
                    }}
                  >
                    {translations.commonFields.closeBtn[language]}
                  </button>
                  <div class="row" style={{ width: "100%" }}>
                    <div
                      class="d-flex alert alert-danger align-items-center invisible"
                      id="clientFormAlert"
                      role="alert"
                    >
                      <svg
                        class="flex-shrink-0 bi me-2"
                        width="24"
                        height="24"
                        role="img"
                        aria-label="Danger:"
                      >
                        {/*<use xlink:href="#exclamation-triangle-fill"></use>*/}
                      </svg>
                      <div id="clientFormAlertText"></div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Send2Rep;
