import React, { useEffect, useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import { ApiUrl } from "../../ApiUrl";
import { toast } from "react-toastify";
import Loader from "../../loader";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import ClientsFilterbar from "../../components/clientsFilterbar";
const CopyClient = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  seListId,
  dealerships2,
}) => {
  const { language } = useLanguage();
  const [deleteStates, setDeleteStates] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [checkedFields, setCheckedFields] = useState([]);
  const token = localStorage.getItem("token");
  const [filtersData, setFiltersData] = useState([]);
  const [dealershipDetail, setDealershipDetail] = useState("");
  const [callStatusOptions, setCallStatusOptions] = useState([]);
  // const [selectedDealership, setSelectedDealership] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState([
    { label: translations.commonFields.allProspects[language], value: "null" },
  ]);
  const [events, setEvents] = useState([]);

  const allfields = [
    {
      content: translations.commonFields.firstName[language],
      name: "first_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.lastName[language],
      name: "last_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.phone[language],
      name: "phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.email[language],
      name: "email",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.make[language],
      name: "make",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.model[language],
      name: "model",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.usersPage.createUser.year[language],
      name: "year",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: "KM",
      name: "km",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.clients.table.callStatus[language],
      name: "call_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.address[language],
      name: "address",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.city[language],
      name: "city",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.province[language],
      name: "province",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "Activix ID",
      name: "activix_id",
      type: "number",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.prospectiveType[language],
      name: "prospect_type",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.forms.formFields.conquestSection.postalCode[language],
      name: "postal_code",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.officeNumber[language],
      name: "office_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.appointments.appointment[language],
      name: "appointment",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.mobileNumber[language],
      name: "mobile_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.language[language],
      name: "language",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.VIN[language],
      name: "vin",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.purchaseDate[language],
      name: "purchase_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.lastServiceDate[language],
      name: "last_service_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospectiveSource[language],
      name: "prospect_source",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.greetings[language],
      name: "greetings",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "OG Rep",
      name: "og_rep",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const appointmentOption = [
    { label: translations.dropdown.yes[language], value: "1" },
    { label: translations.dropdown.no[language], value: "0" },
  ];
  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));
  const prospectiveSourceOptions2 = [
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt1[language],
      value: translations.createProspective.prospectiveSourceOptions.opt1["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt2[language],
      value: translations.createProspective.prospectiveSourceOptions.opt2["en"],
    },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt3[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt3["en"],
    // },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt4[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt4["en"],
    // },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt5[language],
      value: translations.createProspective.prospectiveSourceOptions.opt5["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt6[language],
      value: translations.createProspective.prospectiveSourceOptions.opt6["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt7[language],
      value: translations.createProspective.prospectiveSourceOptions.opt7["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt8[language],
      value: translations.createProspective.prospectiveSourceOptions.opt8["en"],
    },
  ];

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const getEventsList = async (dealershipId) => {
      // setLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/p-events/" + dealershipId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const lists =
            data?.private_events &&
            data?.private_events.length > 0 &&
            data?.private_events
              .filter((list) => list?.id.toString() !== eventId.toString())
              .map((list) => {
                return { label: list?.nom_vp, value: list?.id };
              });

          setEvents([
            ...[
              {
                label: translations.commonFields.allProspects[language],
                value: "null",
              },
            ],
            ...lists,
          ]);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
        // setIsLoading(false);
      }
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(ApiUrl + `/api/user/prospective-index/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            private_event_id: eventId,
            all_prospects: true,
          }),
        });
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;
          const callOptions =
            data.prospectives && data.prospectives.length > 0
              ? Array.from(
                  new Set(
                    data.prospectives
                      .filter((item) => item?.call_status != null) // Filter out null or undefined call_status
                      .map((item) => item.call_status.toString()) // Map to call_status strings
                  )
                ).map((call_status) => ({
                  label: call_status,
                  value: call_status,
                }))
              : [];

          setClients(data.prospectives);
          setCallStatusOptions(callOptions);
          seListId({ list_id: data.liste_id, list_name: data.list_name });
          setDealershipDetail(data?.dealershipDetails);
          //   setDeleteStates(data.prospectives.map(() => false));
          getEventsList(data?.dealershipDetails?.id);

          // autoScrollMid();
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
        setLoading(false);
      }
    };
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields?.filter(
            (field) => data?.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };

    if (token && eventId) {
      fetchData();
    }
    // getFiltersData();
  }, [eventId]);

  // const getEventsList = async (dealershipId) => {
  //   // setLoading(true);
  //   try {
  //     const res = await fetch(ApiUrl + "/api/user/p-events/" + dealershipId, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const data = await res.json();
  //     if (data.status === "success") {
  //       const lists =
  //         data?.private_events &&
  //         data?.private_events.length > 0 &&
  //         data?.private_events.map((list) => {
  //           return { label: list?.nom_vp, value: list?.id };
  //         });

  //       setEvents(lists);
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (err) {
  //     toast.error("Something went wrong");
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };

  const getFilteredClients = async (eventID) => {
    setLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/prospective-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          private_event_id: eventID ? eventID : eventId,
          all_prospects: eventID ? false : true,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setClients(data.prospectives);
        setDeleteStates(data.prospectives.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  const copyClient = async (prospectId) => {
    setLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/copy-prospective", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          private_event_id: eventId,
          prospect_id: prospectId,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setDisplayComponent("edit-prospective");
        //   setEditEventID(event.id);
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          "/event-portal?page=edit-prospective&event_id=" +
            eventId +
            `&client_id=${data?.prospective?.id}`
        );
        // setClients(data.prospectives);
        // setDeleteStates(data.prospectives.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  const getFilteredEvents = async (filterPost) => {
    // setIsLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/prospective-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          private_event_id: eventId,
          ...filterPost,
          all_prospects: filterPost.length === 0 ? true : true,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setClients(data.prospectives);
        setDeleteStates(data.prospectives.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  return (
    <div>
      {!loading ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <ClientsFilterbar
              isDarkMode={isDarkMode}
              allfields={allfields}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              textOptions={textOptions}
              textOptions2={textOptions2}
              appointmentOption={appointmentOption}
              prospectiveSourceOptions={prospectiveSourceOptions2}
              callStatusOptions={callStatusOptions}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              // filterOption={filterOption}
              // setFilterOption={setFilterOption}
              // dealerOptions={dealerOptions}
              // repOptions2={repOptions2}
              // managers={managers}
              // packageOptions={packageOptions}
              // animatorOptions={animatorOptions}
              getFilteredEvents={getFilteredEvents}
              // weekOptions={weekOptions}
            />
            <div className="row align-items-center justify-content-between pb-2">
              <div className="col-7 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.clients.title[language]} {" - "}{" "}
                    {dealershipDetail?.name}
                    {": "}
                    {dealershipDetail?.start_date}{" "}
                    {translations.commonWords.to[language]}{" "}
                    {dealershipDetail?.end_date}
                  </h2>
                </div>
              </div>
              <div className="col-5 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("event-view");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=event-view&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("appointments");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=appointments&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("clients");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=clients&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    className=""
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    style={{
                      background: "#05cc9e",
                      // color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-clipboard-plus"></i>{" "}
                    {translations.forms.formFields.copyClient[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("eventReport");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=eventReport&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between py-2">
              <div className="col-4 d-flex gap-3 align-items-center"></div>
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                {/* <div style={{ width: "250px" }}>
                  <Select
                    className="selectpicker w-100"
                    aria-labelledby="dealership-name-quick"
                    isClearable
                    options={dealerships2}
                    placeholder={translations["dropdown"].select[language]}
                    isSearchable={true}
                    maxMenuHeight={"120px"}
                    value={selectedDealership}
                    // value={
                    //   (filterOptions &&
                    //     filterOptions.length > 0 &&
                    //     filterOptions.find(
                    //       (option) => option.value === filterOption
                    //     )) || {
                    //     value: "",
                    //     label: translations["dropdown"].select[language],
                    //   }
                    // }
                    onChange={(selectedOption) => {
                      setSelectedDealership(selectedOption);
                      if (selectedOption) getEventsList(selectedOption.value);
                      else {
                        setSelectedEvent(null);
                        setClients([]);
                      }
                    }}
                    styles={{
                      input: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? "transparent" : "white",
                        border: state.isFocused
                          ? "1px solid #80bdff"
                          : isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6", // Adjust the border color for focus
                        boxShadow: state.isFocused
                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                          : "none", // Bootstrap's box shadow for focus
                        borderRadius: ".45rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none", // Hide the indicator separator
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                        color: isDarkMode ? "#ffffff" : "#868686",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: isDarkMode ? "#1f2023" : "white",
                        margin: "0", // Remove default margin
                        zIndex: "9999",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                        // Additional styles for placeholder
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: state.isHovered
                          ? "#80bdff"
                          : isDarkMode
                          ? "#1f2023"
                          : "white",
                        ":hover": {
                          backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                        },
                      }),
                    }}
                  />
                </div> */}
                <div
                  style={{ width: "250px" }}
                  className={
                    isDarkMode ? "statusDropdownDark" : "statusDropdown"
                  }
                >
                  <Select
                    className="selectpicker w-100"
                    aria-labelledby="dealership-name-quick"
                    isClearable
                    options={events}
                    placeholder={translations["dropdown"].select[language]}
                    isSearchable={true}
                    maxMenuHeight={"120px"}
                    value={selectedEvent}
                    // isDisabled={!selectedDealership}
                    onChange={(selectedOption) => {
                      setSelectedEvent(selectedOption);
                      if (selectedOption && selectedOption.value !== "null")
                        getFilteredClients(selectedOption.value);
                      else {
                        // setSelectedEvent(null);
                        // setClients([]);
                        getFilteredClients(null);
                      }
                    }}
                    styles={{
                      input: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? "transparent" : "white",
                        border: state.isFocused
                          ? "1px solid #80bdff"
                          : isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6", // Adjust the border color for focus
                        boxShadow: state.isFocused
                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                          : "none", // Bootstrap's box shadow for focus
                        borderRadius: ".45rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none", // Hide the indicator separator
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                        color: isDarkMode ? "#ffffff" : "#868686",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: isDarkMode ? "#1f2023" : "white",
                        margin: "0", // Remove default margin
                        zIndex: "9999",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                        // Additional styles for placeholder
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: state.isHovered
                          ? "#80bdff"
                          : isDarkMode
                          ? "#1f2023"
                          : "white",
                        ":hover": {
                          backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                        },
                      }),
                    }}
                  />
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    id=""
                    type="button"
                    className="btn admin-btn btn-primary"
                    data-bs-target="#clientFiltersidebar"
                    data-bs-toggle="offcanvas"
                    aria-bs-controls="offcanvasmain"
                  >
                    <i className="bi bi-search"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            <div
              // className="pt-4"
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "space-between",
              // }}
              // ref={containerRef}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
                justifyContent: "space-between",
                height: "calc(100vh - 260px)",
              }}
            >
              <table className="table table-hover text-center align-middle">
                <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                  <tr>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "125px" : "150px",
                      }}
                    >
                      {translations.clients.table.prospectiveType[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "140px" : "165px",
                      }}
                    >
                      {
                        translations.createProspective.prospectiveSource[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.commonFields.firstName[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "135px",
                      }}
                    >
                      {translations.commonFields.lastName[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "130px" : "180px",
                      }}
                    >
                      {translations.quickCreationModal.phoneNumber[language]}
                    </th>

                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "130px" : "155px",
                      }}
                    >
                      {translations.createProspective.officeNumber[language]}
                    </th>

                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "150px" : "150px",
                        maxWidth: language === "en" ? "150px" : "150px",
                      }}
                    >
                      {
                        translations.commonWords.usersTypes.representative[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "130px",
                      }}
                    >
                      {translations.clients.table.callStatus[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.createProspective.make[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.createProspective.model[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{ minWidth: language === "en" ? "95px" : "90px" }}
                    >
                      {translations.usersPage.createUser.year[language]}
                    </th>
                    {/* <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "135px" : "195px",
                      }}
                    >
                      {translations.clients.table.noShowStatus[language]}
                    </th> */}
                    <th
                      scope="col"
                      colSpan={1}
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        right: 0,
                      }}
                    >
                      Actions
                    </th>
                    {/* <th scope="col"></th> */}
                  </tr>
                </thead>
                {clients && !loading && (
                  <tbody id="">
                    {clients && clients.length > 0 ? (
                      clients.map((client, index) => {
                        return (
                          <>
                            <tr className="result-box" key={client?.id}>
                              <th className="custID" scope="row">
                                {client?.id}
                              </th>
                              <td className="dlrname">
                                {client?.prospect_type}
                              </td>
                              <td className="dlrname">
                                {client?.prospect_source
                                  ? (() => {
                                      const matchedOption =
                                        prospectiveSourceOptions.find(
                                          (option) =>
                                            option.value.en?.toLowerCase() ===
                                              client?.prospect_source?.toLowerCase() ||
                                            option.value.fr?.toLowerCase() ===
                                              client?.prospect_source?.toLowerCase()
                                        );

                                      // Return the label in the current language if a match is found
                                      return matchedOption
                                        ? matchedOption.label[language]
                                        : client?.prospect_source;
                                    })()
                                  : ""}
                              </td>
                              <td className="dlrname">{client?.first_name}</td>
                              <td className="dlrname">{client?.last_name}</td>
                              <td className="salesrep">{client?.phone}</td>
                              <td className="salesrep">
                                {client?.office_phone}
                              </td>

                              <td
                                className="salesrep"
                                style={{
                                  minWidth:
                                    language === "en" ? "150px" : "150px",
                                  maxWidth:
                                    language === "en" ? "150px" : "150px",
                                  overflow: "hidden", // Hide content that exceeds the boundaries
                                  textOverflow: "ellipsis", // Display ellipsis (...) for overflowed text
                                  whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                                }}
                              >
                                {client?.og_rep}
                              </td>
                              <td className="salesrep">
                                {client?.call_status}
                              </td>
                              <td className="salesrep">{client?.make}</td>
                              <td className="salesrep">{client?.model}</td>
                              <td className="salesrep">{client?.year}</td>
                              {/* <td className="salesrep">
                              {client?.no_show_status}
                            </td> */}

                              <td
                                style={{
                                  width: "170px",
                                  position: "-webkit-sticky",
                                  position: "sticky",
                                  right: 0,
                                }}
                              >
                                <div className="btn-group" role="group">
                                  {
                                    <button
                                      type="button"
                                      className="btn btn-light d-flex align-items-center gap-1"
                                      //   data-customer-id="405"
                                      onClick={() => copyClient(client?.id)}
                                    >
                                      <i className={"bi bi-clipboard"}></i>
                                      {
                                        translations.EventsPortal
                                          .eventsTableButtons.copy[language]
                                      }
                                    </button>
                                  }
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr
                        className="align-middle"
                        style={{
                          position: "relative",
                          // height: "30px",
                          // zIndex: 10 + eventList.length - index,
                        }}
                      >
                        <td
                          style={{
                            background: `${
                              isDarkMode ? "transparent" : "white"
                            }`,
                            borderStyle: "none",
                          }}
                          colSpan={14}
                          className="text-center py-5"
                        >
                          <h4>
                            {translations.EventsPortal.noDataMessage[language]}
                          </h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CopyClient;
