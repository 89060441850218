import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { Field } from "formik";
import { CSS } from "@dnd-kit/utilities";
function VerticalSortableColumns({
  column,
  setValues,
  isDarkMode,
  values,
  index,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    borderBottom: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6",
  };
  //   console.log(values);

  return (
    <div
      className="col-12 d-flex align-items-center justify-content-between px-3 py-2"
      style={{
        ...style,
      }}
    >
      <label
        for="inputadddealership"
        class="form-label"
        ref={setNodeRef} // Applies only to the draggable column
        {...{ ...attributes, ...listeners }}
        style={{
          cursor: "move",
        }}
      >
        {column.content}
      </label>
      <div class="">
        <Field
          //   name={`columns.${column}`}
          type="checkbox"
          className="form-check-input"
          checked={values.columns[index]?.renderInView ? true : false}
          id="inputadddealership"
          style={{
            // backgroundColor: isDarkMode
            //   ? "transparent"
            //   : "",
            // color: isDarkMode ? "#ffffff" : "#000000",
            border: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6",
          }}
          onChange={(e) => {
            const updatedColumns = values.columns.map((col, idx) =>
              idx === index ? { ...col, renderInView: e.target.checked } : col
            );

            setValues({
              ...values,
              columns: updatedColumns,
            });
          }}
        />
      </div>
    </div>
  );
}

export default VerticalSortableColumns;
