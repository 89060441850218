import React, { useRef, useState } from "react";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { ApiUrl } from "../../../ApiUrl";
import { toast } from "react-toastify";
import EditAppointmenModal from "./editAppointmenModal";
import DraggableHeaderCell from "../../../components/EventsPage/DraggableHeaderCell";
import AddViewModal from "../../../components/EventsPage/AddViewModal";

const TableView = ({
  isDarkMode,
  appointments,
  setDisplayComponent,
  setDeleteStates,
  eventId,
  deleteStates,
  setLoading,
  setAppointments,
  selectedAppointments,
  setSelectedAppointments,
  selectAll,
  setSelectAll,
  myEvents,
  setMyEvents,
  calendarData,
  setIsLoading,
  fetchCalendarData,
  currentView,
  handleResize,
  data,
  setTableViews,
  viewData,
  setViewData,
  setCurrentView,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");

  const [appointmentData, seAppointmentData] = useState("");

  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "Wants callback",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "Message left",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];

  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));

  const handleDelete = async (id, index) => {
    setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/appointments/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data) {
        toast.success(
          translations.alertMessages.appoinmentDeletedSuccess[language]
        );
        const newData = appointments.filter((client) => client.id != id);
        setAppointments(newData);
        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleCheckboxChange = (appointmentId) => {
    setSelectedAppointments(
      (prevSelected) =>
        prevSelected.includes(appointmentId)
          ? prevSelected.filter((id) => id !== appointmentId) // Deselect if selected
          : [...prevSelected, appointmentId] // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedAppointments([]);
    } else {
      // Select all appointments' IDs
      const allAppointmentIds = appointments.map(
        (appointment) => appointment.id
      );
      setSelectedAppointments(allAppointmentIds);
    }
    setSelectAll(!selectAll); // Toggle the state of selectAll
  };
  function getFormattedDate(appointment_date, appointment_time) {
    // Default time if appointment_time is missing or invalid
    const defaultTime = "09:00:00";
    const timeToUse =
      appointment_time && appointment_time.includes(":")
        ? appointment_time
        : defaultTime;

    // Parse the date and time components
    if (!appointment_date) {
      console.error("Missing appointment date");
      return null;
    }

    const [year, month, day] = appointment_date.split("-").map(Number);
    const [hours, minutes, seconds] = timeToUse.split(":").map(Number);

    // Construct startDate object
    const startDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds || 0
    );

    if (isNaN(startDate)) {
      // console.error("Invalid startDate generated");
      return null;
    }

    // console.log("Generated Start Date:", startDate);
    return startDate;
  }

  const handleShowModal = (eventId, event) => {
    // console.log(calendarData);

    const findAppointmentWithRepData = (calendarData, eventId) => {
      for (const data of calendarData) {
        const appointment = data.appointments.find(
          (appointment) => appointment.id == eventId
        );

        if (appointment) {
          seAppointmentData({
            rep_id: appointment.appointment_rep_user_id,
            rep_name: data.rep_name,
            start: getFormattedDate(
              appointment.appointment_date,
              appointment.appointment_time
            ),
            end: getFormattedDate(
              appointment.appointment_end_date,
              appointment.appointment_end_time
            ),
            appointment: appointment,
            ...event,
          });
          var createModal = new window.bootstrap.Modal(
            document.getElementById("editAppointmentModal")
          );
          createModal.show();
        }
      }
      return null; // Return null if no matching appointment is found
    };

    // Usage example
    const data = findAppointmentWithRepData(calendarData, eventId);
  };

  const getTranslatedConfirmationStatus = (status) => {
    const option = confirmationStatusOptions.find(
      (opt) => opt.value === status
    );
    return option ? option.label : status;
  };
  const getTranslatedVisitStatus = (status) => {
    const option = visitStatusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const getTranslatedNoShowStatus = (status) => {
    const option = noShowOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const getStatusColor = (status) => {
    const statusColors = {
      cancelled: "#ADADAD",
      present: "#32DBB4",
      "sold new": "#327CD9",
      "sold used": "#327CD9",
      "in follow up": "#FFA601",
      cancel: "#327DDA",
      confirmed: "#DC3343",
    };
    // console.log("statusColors[status?.toLowerCase()]",statusColors[status?.toLowerCase()],status?.toLowerCase())
    return statusColors[status?.toLowerCase()] || "white";
  };

  return (
    <div
      // className="pt-4"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-between",
      // }}
      // ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        justifyContent: "space-between",
        height: "calc(100vh - 320px)",
        position: "relative",
      }}
    >
      <EditAppointmenModal
        isDarkMode={isDarkMode}
        appointmentData={appointmentData}
        seAppointmentData={seAppointmentData}
        eventId={eventId}
        setIsLoading={setIsLoading}
        appointments={myEvents}
        setAppointments={setMyEvents}
        appointments2={appointments}
        setAppointments2={setAppointments}
        setDisplayComponent={setDisplayComponent}
        fetchCalendarData={fetchCalendarData}
      />
      <AddViewModal
        isDarkMode={isDarkMode}
        getColumns={
          (data?.user_views &&
            data.user_views?.find(
              (item) => item.type === "default appointment"
            )) ||
          {}
        }
        setTableViews={setTableViews}
        setIsLoading={setIsLoading}
        viewData={viewData}
        setViewData={setViewData}
        setCurrentView={setCurrentView}
        tableType={"appointment"}
      />
      <table
        // className="table table-hover align-middle text-center"
        className="align-content-center table align-middle"
        style={{
          minWidth: "100%",
          borderCollapse: "collapse",
          tableLayout: "auto",
        }}
      >
        <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <tr>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{ width: "50px", paddingBlock: "16px", }}
            >
              {
                <input
                  type="checkbox"
                  className=""
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
            </th>

            {currentView?.columns
              .slice(0, -1)
              .filter((header) => header.renderInView !== false)
              .map((header) =>
                header.showIf !== false ? (
                  <DraggableHeaderCell
                    key={header.id}
                    column={header}
                    isDarkMode={isDarkMode}
                    onResize={handleResize}
                  />
                ) : null
              )}

            {currentView?.columns
              .filter((header) => header.renderInView !== false)
              .some((column) => column.id === "actions") && (
              <th
                scope="col"
                className={`${isDarkMode ? "darkthead" : ""} ${
                  currentView?.columns[currentView?.columns.length - 1]
                    ?.className || ""
                }`}
                style={{
                  ...currentView?.columns[currentView.columns.length - 1]
                    ?.style,
                  width: `${
                    currentView?.columns[currentView?.columns.length - 1]?.width
                  }px`,
                  // paddingInline: "56px !important",
                  // marginInline: "4px !important",
                  paddingBlock: "16px",
                  background: isDarkMode ? "#1b1f22" : "#ffffff",
                  // background: isDarkMode ? "#343a3f" : "#ffffff",
                }}
                colSpan={
                  currentView?.columns[currentView?.columns.length - 1]?.colSpan
                }
              >
                {currentView?.columns[currentView?.columns.length - 1]?.content}
              </th>
            )}
            {/* <th scope="col"></th> */}
          </tr>
        </thead>
        <tbody id="">
          {appointments && appointments.length > 0 ? (
            appointments.map((appointment, index) => {
              // console.log(event.number);
              return (
                <>
                  {
                    <tr
                      key={appointment.id}
                      style={{
                        backgroundColor: getStatusColor(
                          appointment?.confirmation_status === "cancelled" ||
                            appointment?.confirmation_status?.toLowerCase() ===
                              "cancel"
                            ? appointment?.confirmation_status
                            : appointment?.visit_status
                        ),
                      }}
                      className={`result-box ${
                        appointment?.confirmation_status === "cancelled" ||
                        appointment?.confirmation_status?.toLowerCase() ===
                          "cancel"
                          ? appointment?.confirmation_status
                              ?.toLowerCase()
                              ?.replace(/ /g, "-")
                              ?.replace(/[()]/g, "") || ""
                          : appointment?.confirmation_status === "CONFIRMED"
                          ? "confirmed"
                          : appointment?.visit_status
                              ?.toLowerCase()
                              ?.replace(/ /g, "-")
                              ?.replace(/[()]/g, "") || ""
                      }`}
                    >
                      <td
                        className="fw-bold"
                        style={
                          {
                            // backgroundColor: "#ced4da66",
                            // borderTop: "1px solid #dee2e6",
                            // borderBottom: "1px solid #dee2e6",
                            //  paddingInline: "25px"
                          }
                        }
                      >
                        {
                          <input
                            type="checkbox"
                            className=""
                            checked={selectedAppointments.includes(
                              appointment.id
                            )}
                            onChange={() =>
                              handleCheckboxChange(appointment.id)
                            }
                          />
                        }
                      </td>

                      {currentView &&
                        currentView?.columns &&
                        currentView?.columns
                          .slice(0, -1)
                          .filter((header) => header.renderInView !== false)
                          .map((col) => {
                            if (col.showIf === false) return null; // Skip columns with showIf: false

                            const columnKey = col.id;

                            return (
                              <td
                                className=""
                                scope="row"
                                id={columnKey}
                                key={col.id}
                                style={{
                                  ...col?.style,
                                  paddingInline: "56px !important",
                                  marginInline: "4px !important",
                                  cursor: "pointer",
                                  // background: isDarkMode ? "#343a3f" : "#ffffff",
                                }}
                                onClick={() =>
                                  handleShowModal(appointment.id, appointment)
                                }
                              >
                                {columnKey === "full_name"
                                  ? `${
                                      appointment?.prospective?.first_name !==
                                        "null" &&
                                      appointment?.prospective?.first_name
                                        ? appointment.prospective.first_name
                                        : ""
                                    } ${
                                      appointment?.prospective?.last_name !==
                                        "null" &&
                                      appointment?.prospective?.last_name
                                        ? appointment.prospective.last_name
                                        : ""
                                    }`
                                  : columnKey === "prospect_source"
                                  ? appointment?.prospective?.prospect_source
                                    ? (() => {
                                        const matchedOption =
                                          prospectiveSourceOptions.find(
                                            (option) =>
                                              option.value.en?.toLowerCase() ===
                                                appointment?.prospective?.prospect_source?.toLowerCase() ||
                                              option.value.fr?.toLowerCase() ===
                                                appointment?.prospective?.prospect_source?.toLowerCase()
                                          );

                                        // Return the label in the current language if a match is found
                                        return matchedOption
                                          ? matchedOption.label[language]
                                          : appointment?.prospective
                                              ?.prospect_source;
                                      })()
                                    : ""
                                  : columnKey === "visit_status"
                                  ? getTranslatedVisitStatus(
                                      appointment?.visit_status
                                    )
                                  : columnKey === "confirmation_status"
                                  ? getTranslatedConfirmationStatus(
                                      appointment?.confirmation_status
                                    )
                                  : columnKey === "no_show_status"
                                  ? getTranslatedNoShowStatus(
                                      appointment?.no_show_status
                                    )
                                  : appointment?.prospective?.[columnKey] ||
                                    appointment[columnKey] ||
                                    ""}
                              </td>
                            );
                          })}

                      {currentView?.columns
                        .filter((header) => header.renderInView !== false)
                        .some((column) => column.id === "actions") && (
                        <>
                          <td
                            style={{
                              position: "-webkit-sticky",
                              position: "sticky",
                              right: language == "fr" ? "136px" : "110px",
                            }}
                          >
                            <button
                              type="button"
                              className="d-flex btn btn-light align-items-center justify-content-center edit-button gap-1 mx-auto"
                              //   data-customer-id="405"
                              //   data-bs-toggle="modal"
                              //   data-bs-target="#AddDealerModal"
                              onClick={() => {
                                // setDisplayComponent("edit-appointment");
                                // //   setEditEventID(event.id);
                                // window.history.pushState(
                                //   {
                                //     id: "gallery",
                                //     randomData: window.Math.random(),
                                //   },
                                //   "title",
                                //   "/event-portal?page=edit-appointment&event_id=" +
                                //     eventId +
                                //     `&client_id=${appointment.id}`
                                // );
                                setDisplayComponent("edit-appointment");
                                //   setEditEventID(event.id);
                                window.history.pushState(
                                  {
                                    id: "gallery",
                                    randomData: window.Math.random(),
                                  },
                                  "title",
                                  "/event-portal?page=edit-appointment&event_id=" +
                                    eventId +
                                    `&client_id=${appointment.prospective.id}`
                                );
                              }}
                            >
                              <i className="bi bi-eye"></i>{" "}
                              {translations.commonWords.view[language]}
                            </button>
                          </td>

                          <td
                            style={{
                              width: "170px",
                              position: "-webkit-sticky",
                              position: "sticky",
                              right: 0,
                            }}
                          >
                            <div className="btn-group" role="group">
                              {deleteStates[index] ? (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-success delete-button"
                                    data-customer-id="405"
                                    onClick={() =>
                                      handleDelete(appointment.id, index)
                                    }
                                  >
                                    {
                                      translations.commonFields.confirm[
                                        language
                                      ]
                                    }
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary cancel-button"
                                    onClick={() =>
                                      setDeleteStates((prevStates) => {
                                        const newStates = [...prevStates];
                                        newStates[index] = false;
                                        return newStates;
                                      })
                                    }
                                  >
                                    {translations.commonFields.cancel[language]}
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="button"
                                  className="d-flex btn btn-danger align-items-center justify-content-center before-delete-button gap-1 mx-auto"
                                  dataset-button-state="delete"
                                  onClick={() =>
                                    setDeleteStates((prevStates) => {
                                      const newStates = [...prevStates];
                                      newStates[index] = true;
                                      return newStates;
                                    })
                                  }
                                >
                                  <i className="bi bi-trash"></i>{" "}
                                  {translations.commonFields.delete[language]}
                                </button>
                              )}
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  }
                </>
              );
            })
          ) : (
            <tr
              className="align-middle"
              style={{
                position: "relative",
                // height: "30px",
                // zIndex: 10 + eventList.length - index,
              }}
            >
              <td
                style={{
                  background: `${isDarkMode ? "transparent" : "white"}`,
                  borderStyle: "none",
                }}
                colSpan={16}
                className="text-center py-5"
              >
                <h4>{translations.EventsPortal.noDataMessage[language]}</h4>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
    </div>
  );
};

export default TableView;
