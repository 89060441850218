import React, { useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ApiUrl } from "../../ApiUrl";
import { toast } from "react-toastify";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { verticalListSortingStrategy } from "@dnd-kit/sortable";
import VerticalSortableColumns from "./verticalSortableColumns";

function AddViewModal({
  isDarkMode,
  getColumns,
  setTableViews,
  setIsLoading,
  viewData,
  setViewData,
  setCurrentView,
  tableType = "event",
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const { language } = useLanguage();
  var button = document.getElementById("addViewModalCloseBtn");

  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const initialData = {
    name: viewData ? viewData?.name : "",
    type: tableType || "",
    csv: viewData ? (viewData?.csv === "1" ? true : false) : false,
    xlsx: viewData ? (viewData?.xlsx === "1" ? true : false) : false,
    current: viewData ? viewData.current : false,
    columns: viewData
      ? tableType === "event"
        ? viewData?.columns?.filter((item) => item.id !== "week")
        : tableType === "appointment"
        ? viewData?.columns
        : viewData?.columns?.filter((item) => item.id !== "id")
      : tableType === "event"
      ? getColumns?.columns?.filter((item) => item.id !== "week")
      : tableType === "appointment"
      ? getColumns?.columns
      : getColumns?.columns?.filter((item) => item.id !== "id"),
    // columns: getColumns()
    //   .columns.filter((item) => item.id !== "week")
    //   .reduce((acc, item) => {
    //     acc[item.id] = true;
    //     return acc;
    //   }, {}),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.EventsPortal.views.createViewModal.validationMessage[
        language
      ]
    ),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    // console.log(values.columns);
    // const selectedColumns = values.columns.filter((item) => item.renderInView);
    const newView = {
      name: values.name,
      type: tableType || "",
      current: values.current,
      xlsx: values.xlsx,
      csv: values.csv,
      columns:
        tableType === "event"
          ? [
              {
                id: "week",
                content: translations.forms.formFields.week[language],
                style: {
                  position: "-webkit-sticky",
                  position: "sticky",
                  left: "0px",
                  // width: "57px",
                },
                width: 57,
                className: "text-center",
                renderInView: true,
              },
              ...values.columns,
            ]
          : tableType === "appointment"
          ? values.columns
          : [
              {
                id: "id",
                content: "ID",
                style: {},
                className: "text-center",
                width: 40,
                renderInView: true,
              },
              ...values.columns,
            ],
    };

    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/views", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: viewData
          ? JSON.stringify({ id: viewData.id, ...newView })
          : JSON.stringify(newView),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data?.message);
        const viewData2 = {
          ...data.userView,
          columns: JSON.parse(data.userView.columns),
        };
        if (viewData) {
          setTableViews((prev) =>
            prev.map((item) => {
              if (item.id === viewData.id) {
                return viewData2;
              }
              return item;
            })
          );
          if (viewData.current === true || viewData.current === "1") {
            setCurrentView(viewData2);
          }
        } else {
          setTableViews((prev) => [...prev, viewData2]);
        }
        resetForm();
        button.click();
      } else {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };
  const handleDelete = async ({ resetForm }) => {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + `/api/user/views/${viewData?.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data?.message);
        // const viewData = {
        //   ...data.userView,
        //   columns: JSON.parse(data.userView.columns),
        // };
        setTableViews((prev) => prev.filter((item) => item.id !== viewData.id));
        button.click();
      } else {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const moveColumn = (event, setValues, values) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = values.columns.findIndex((col) => col.id === active.id);
    const newIndex = values.columns.findIndex((col) => col.id === over.id);

    const updatedColumns = arrayMove(values.columns, oldIndex, newIndex);

    setValues((prev) => ({
      ...prev,
      columns: updatedColumns,
    }));
  };

  return (
    <div id="addViewModal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={viewData?.mode === "delete" ? handleDelete : handleSubmit}
            id="creationsuccess"
            enableReinitialize={true}
          >
            {({ errors, values, setValues, resetForm, setFieldValue }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3>
                    {viewData?.mode === "delete"
                      ? translations.EventsPortal.views.deleteViewModal.title[
                          language
                        ]
                      : viewData?.mode === "edit"
                      ? translations.EventsPortal.views.editViewModal.title[
                          language
                        ]
                      : translations.EventsPortal.views.createViewModal.title[
                          language
                        ]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    id="addViewModalCloseBtn"
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    onClick={() => {
                      resetForm();
                      setViewData(null);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  {viewData?.mode === "delete" ? (
                    <div>
                      <h6 className="text-center">
                        {
                          translations.EventsPortal.views.deleteViewModal
                            .message[language]
                        }
                      </h6>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <label for="inputadddealership" class="form-label">
                          {
                            translations.EventsPortal.views.createViewModal
                              .viewName[language]
                          }
                        </label>
                        <div class="mb-3">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            id="inputadddealership"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            // placeholder={
                            //   translations["commonFields"].name[language]
                            // }
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label for="inputadddealership" class="form-label">
                          {
                            translations.EventsPortal.views.createViewModal
                              .exportOptions[language]
                          }
                        </label>
                        <div className="row pb-3">
                          <div className="col-3">
                            <div className="d-flex align-items-center gap-2">
                              <Field
                                // name={values.columns[]}
                                type="checkbox"
                                className="form-check-input"
                                checked={values.xlsx}
                                id="inputadddealership"
                                style={{
                                  // backgroundColor: isDarkMode
                                  //   ? "transparent"
                                  //   : "",
                                  // color: isDarkMode ? "#ffffff" : "#000000",
                                  marginTop: 0,
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    xlsx: e.target.checked,
                                  });
                                }}
                              />
                              <label for="" class="">
                                XLSX
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex align-items-center gap-2">
                              <Field
                                // name={values.columns[]}
                                type="checkbox"
                                className="form-check-input"
                                checked={values.csv}
                                id="inputadddealership"
                                style={{
                                  // backgroundColor: isDarkMode
                                  //   ? "transparent"
                                  //   : "",
                                  // color: isDarkMode ? "#ffffff" : "#000000",
                                  marginTop: 0,
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                onChange={(e) => {
                                  setValues({
                                    ...values,
                                    csv: e.target.checked,
                                  });
                                }}
                              />
                              <label for="" class="">
                                CSV
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={(event) =>
                          moveColumn(event, setValues, values)
                        }
                      >
                        <SortableContext
                          items={
                            (values?.columns &&
                              values?.columns
                                ?.slice(0, -1)
                                ?.map((col) => col)) ||
                            []
                          }
                          strategy={verticalListSortingStrategy}
                        >
                          <div className="col-12">
                            <div
                              style={{
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                                borderRadius: "0.375rem",
                              }}
                              className=""
                            >
                              {values?.columns
                                ?.slice(0, -1)
                                ?.map((column, index) => (
                                  <VerticalSortableColumns
                                    key={column.id}
                                    index={index}
                                    column={column}
                                    setValues={setValues}
                                    isDarkMode={isDarkMode}
                                    values={values}
                                  />
                                ))}
                              <div className="col-12 d-flex align-items-center justify-content-between px-3 py-2">
                                <label
                                  for="inputadddealership"
                                  class="form-label"
                                >
                                  {
                                    getColumns?.columns?.find(
                                      (item) => item.id === "actions"
                                    )?.content
                                  }
                                </label>
                                <div class="">
                                  <Field
                                    // name={values.columns[]}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      values.columns
                                        ? values?.columns[
                                            values?.columns?.length - 1
                                          ]?.renderInView
                                        : false
                                    }
                                    id="inputadddealership"
                                    style={{
                                      // backgroundColor: isDarkMode
                                      //   ? "transparent"
                                      //   : "",
                                      // color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                    onChange={(e) => {
                                      const updatedColumns = values.columns.map(
                                        (col, idx) =>
                                          col.id === "actions"
                                            ? {
                                                ...col,
                                                renderInView: e.target.checked,
                                              }
                                            : col
                                      );

                                      setValues({
                                        ...values,
                                        columns: updatedColumns,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </SortableContext>
                      </DndContext>
                    </div>
                  )}
                </div>

                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <div className="d-flex align-items-center gap-2 justify-content-end w-100">
                    <div>
                      <button
                        type="button"
                        className="px-4 py-2 btn btn-secondary"
                        // id="form-send-client"
                        onClick={() => {
                          button.click();
                          // setDisplayComponent("create-event");
                          // window.history.pushState(
                          //   {
                          //     id: "gallery",
                          //     randomData: window.Math.random(),
                          //   },
                          //   "title",
                          //   "/event-portal?page=create-event&parent_id=" +
                          //     eventId +
                          //     "&event-navigate=true"
                          // );
                        }}
                      >
                        {translations.commonFields.closeBtn[language]}
                      </button>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-light px-4 py-2"
                      id="form-send-client"
                      // onClick={() => {
                      //   // button.click();
                      //   // setDisplayComponent("create-event");
                      //   // window.history.pushState(
                      //   //   {
                      //   //     id: "gallery",
                      //   //     randomData: window.Math.random(),
                      //   //   },
                      //   //   "title",
                      //   //   "/event-portal?page=create-event&parent_id=" +
                      //   //     eventId +
                      //   //     "&event-navigate=true"
                      //   // );
                      //   // setEventId("");
                      // }}
                    >
                      {viewData?.mode === "delete"
                        ? translations.commonFields.confirm[language]
                        : translations.commonFields.saveBtn[language]}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddViewModal;
