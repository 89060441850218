import React, { useState } from "react";
import translations from "../translation.json";
import { useLanguage } from "../languageContext";
import { toast } from "react-toastify";

const AddRepresent = ({
  isDarkMode,
  setRepOptions,
  repOptions,
  editModal,
  setFieldValue,
}) => {
  const { language } = useLanguage();
  var button = document.getElementById("closeAddRepresent");
  const [name, setName] = useState();
  const [error, setError] = useState("");
  const handleSubmit = async (values) => {
    if (name) {
      const newOption = {
        label: name,
        value: name,
      };

      if (!repOptions.some((option) => option.value === newOption.value)) {
        setRepOptions([...repOptions, newOption]);
        setFieldValue("appointment_rep_user_name", newOption.value);
        setName("");
        toast.success(translations.alertMessages.repNameAddedSuccess[language]);
        button.click();
      }
    } else {
      setError();
    }
  };

  return (
    <div id="AddRepresent" className="modal fade" tabindex="-1">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        style={{ maxWidth: "500px" }}
      >
        <div className="modal-content">
          <div
            className="modal-header d-flex flex-column align-items-stretch"
            style={{
              borderBottom: isDarkMode
                ? "1px solid #495057"
                : "1px solid #dee2e6",
            }}
          >
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="closeAddRepresent"
              onClick={() => {
                if (editModal) {
                  document
                    .querySelectorAll(".modal-backdrop")
                    .forEach((backdrop) => {
                      backdrop.remove();
                    });
                  var createModal = new window.bootstrap.Modal(
                    document.getElementById("editAppointmentModal")
                  );
                  createModal.show();
                }
              }}
              style={{
                filter: isDarkMode ? "invert(1)" : "none",
                color: isDarkMode ? "#ffffff" : "rgb(255, 255, 255) !important",
              }}
            ></button>
          </div>
          <div class="modal-body p-4">
            <div className="mb-3 d-flex align-items-center gap-3">
              <input
                placeholder={translations.commonWords.repName[language]}
                onChange={(e) => setName(e.target.value)}
                value={name}
                className={`form-control ${
                  isDarkMode ? "custom-placeholder" : "light-placeholder"
                }`}
                // className={`form-control ${isDarkMode ? "custom-date" : ""}`}
                style={{
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                  border: isDarkMode
                    ? "1px solid #495057"
                    : "1px solid #dee2e6",
                }}
              />
             
              <button
                style={{ width: "100px" }}
                type="button"
                class="btn admin-btn btn-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div
            style={{
              height: "71px",
              borderTop: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6",
            }}
            class="modal-footer"
            id="modalfooter"
          >
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                if (editModal) {
                  var createModal = new window.bootstrap.Modal(
                    document.getElementById("editAppointmentModal")
                  );
                  createModal.show();
                }
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRepresent;
