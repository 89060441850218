import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "../ApiUrl";

const useGetViewsData = (viewType) => {
  const [data, setData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const fetchUser = async () => {
        if (token) {
          try {
            const res = await fetch(ApiUrl + "/api/user/get_views", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                type : viewType
              })
            });
            const data = await res.json();
            if (data.status === "success") {
              //   const userdata = localStorage.getItem("userData");
              //   const parsedData = JSON.parse(userdata);
              //   if (parsedData.user_language !== data.user.user_language) {
              //     localStorage.setItem(
              //       "userData",
              //       JSON.stringify({
              //         ...parsedData,
              //         user_language: data.user.user_language,
              //       })
              //     );
              //   }
              const parsedUserData = JSON.parse(JSON.stringify(data.userView));

              // Parse columns for each user view
              parsedUserData.user_views = parsedUserData.user_views.map(
                (view) => ({
                  ...view,
                  columns: JSON.parse(view.columns),
                })
              );
              setData(parsedUserData);

              // localStorage.setItem('userType', data.user.user_type)
              // if (data.user.user_type === 'admin') {
              //   navigate('/event-portal')
              // } else {
              //   navigate('/client-form')
              // }
            } else {
              //   localStorage.removeItem("token");
              //   localStorage.removeItem("userType");
              //   localStorage.removeItem("userData");
              // navigate("/");
            }
            // return data;
          } catch (error) {
            // localStorage.removeItem("token");
            // localStorage.removeItem("userType");
            // localStorage.removeItem("userData");
            // navigate("/");
          }
        }
      };
      fetchUser();
    }
  }, []);

  return data;
};

export default useGetViewsData;
