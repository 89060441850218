import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "../../ApiUrl";
import { toast } from "react-toastify";
import TableView from "./components/tableView";
import CalendarView from "./components/calendarView";
import Loader from "../../loader";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import CalendarView2 from "./components/calendarView2";
import AppointmentsFilterbar from "./components/appointmentsFilterbar";
import useGetViewsData from "../../hooks/useGetUserData";
import { debounce } from "lodash";
import { horizontalListSortingStrategy } from "@dnd-kit/sortable";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";

const Appointments = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  setIsLoading,
  users,
  showTable,
  setShowTable,
}) => {
  const { language } = useLanguage();
  var button = document.getElementById("openViewModal");
  const appointmentsFilterData = JSON.parse(
    localStorage.getItem("appointmentsFilterData")
  );
  const appointmentsFilterPostData = JSON.parse(
    localStorage.getItem("appointmentsFilterPostData")
  );
  const calendarSelectedDate = JSON.parse(
    localStorage.getItem("calendarSelectedDate")
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const navigate = useNavigate();
  const [deleteStates, setDeleteStates] = useState();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const token = localStorage.getItem("token");
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedFields, setCheckedFields] = useState(
    appointmentsFilterData || []
  );
  const [calendarData, setCalendarData] = useState(null);
  const [repOptions, setRepOptions] = useState([]);
  const [clients, setClients] = useState([]);
  const [filtersData, setFiltersData] = useState(
    appointmentsFilterPostData || []
  );
  const [dealershipDetail, setDealershipDetail] = useState("");
  const [eventDates, setEventDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [myEvents, setMyEvents] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeRange, setTimeRange] = useState({
    start_time: null,
    end_time: null,
  });
  const [prospectiveType, setProspectiveType] = useState("");

  const getColumns = () => {
    return {
      name: "Default View",
      current: true,
      type: "default appointment",
      csv: true,
      xlsx: true,
      columns: [
        {
          id: "full_name",
          content: translations.commonFields.fullName[language],
          style: {
            // minWidth: "100px",
            // paddingInline: "56px !important",
            // marginInline: "4px !important",
          },
          className: "text-center",
          width: 160,
          renderInView: true,
        },
        {
          id: "phone",
          content: translations.commonFields.phone[language],
          width: language === "en" ? 150 : 200,
          style: {
            minWidth: language === "en" ? "130px" : "180px",
          },
          renderInView: true,
        },
        {
          id: "prospect_type",
          content: translations.clients.table.prospectiveType[language],
          minWidth: language === "en" ? "145px" : "150px",
          width: language === "en" ? 145 : 170,
          renderInView: true,
        },
        {
          id: "prospect_source",
          content: translations.createProspective.prospectiveSource[language],
          minWidth: language === "en" ? "140px" : "165px",
          width: language === "en" ? 160 : 185,
          renderInView: true,
        },
        {
          id: "visit_status",
          content: translations.createProspective.visitStatus[language],
          width: language === "en" ? 150 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "visit_notes",
          content: translations.createProspective.visitNotes[language],
          width: language === "en" ? 150 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "general_comment",
          content: translations.createProspective.generalComment[language],
          width: language === "en" ? 220 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "call_status",
          content: translations.clients.table.callStatus[language],
          width: 150,
          renderInView: true,
        },
        {
          id: "model",
          content: translations.createProspective.model[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "year",
          content: translations.usersPage.createUser.year[language],
          width: language === "en" ? 95 : 90,
          style: {
            minWidth: language === "en" ? "95px" : "90px",
          },
          renderInView: true,
        },
        {
          id: "appointment_date",
          content: translations.clients.table.appointmentDate[language],
          width: language === "en" ? 220 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "appointment_time",
          content: translations.clients.table.appointmentTime[language],
          width: language === "en" ? 190 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "appointment_rep_user_name",
          content:
            translations.createProspective.appointmentRepresentative[language],
          width: language === "en" ? 260 : 255,
          style: {
            minWidth: language === "en" ? "280px" : "295px",
          },
          renderInView: true,
        },
        {
          id: "appointment_comment",
          content: translations.createProspective.appointmentComment[language],
          width: language === "en" ? 220 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "confirmation_status",
          content: translations.createProspective.confirmationStatus[language],
          style: {
            minWidth: language === "en" ? "170px" : "195px",
          },
          width: language === "en" ? 190 : 225,
          renderInView: true,
        },
        {
          id: "confirmation_notes",
          content: translations.createProspective.confirmationNotes[language],
          style: {
            minWidth: language === "en" ? "165px" : "195px",
          },
          width: language === "en" ? 195 : 225,
          renderInView: true,
        },

        {
          id: "no_show_status",
          content: translations.clients.table.noShowStatus[language],
          width: language === "en" ? 170 : 225,
          style: {
            minWidth: language === "en" ? "170px" : "195px",
          },
          renderInView: true,
        },
        {
          id: "no_show_notes",
          content: translations.createProspective.noShowNotes[language],
          width: language === "en" ? 150 : 255,
          style: {
            minWidth: language === "en" ? "220px" : "255px",
          },
          renderInView: true,
        },
        {
          id: "actions",
          content:
            translations.EventsPortal.eventsTableHeader.actions[language],
          style: {
            position: "-webkit-sticky",
            position: "sticky",
            right: 0,
          },
          width: 100,
          className: "text-center",
          colSpan: 3,
          renderInView: true,
        },
      ],
    };
  };

  const data = useGetViewsData("appointment");
  const [tableViews, setTableViews] = useState([]);
  const [currentView, setCurrentView] = useState(getColumns());
  const [viewData, setViewData] = useState(null);
  const columnWidthsRef = useRef({});
  const pendingStateUpdate = useRef(false);

  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 7; hour <= 21; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  };
  const appointmentTimeSlots = generateTimeSlots().map((time) => {
    return { label: time, value: time };
  });

  const allfields = [
    {
      content: translations.commonFields.firstName[language],
      name: "first_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.lastName[language],
      name: "last_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.createProspective.appointmentRepresentative[language],
      name: "appointment_rep_user_id",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.appointmentAgent[language],
      name: "appointment_agent_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentEntryDate[language],
      name: "appointment_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentDate[language],
      name: "appointment_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.appointmentTime[language],
      name: "appointment_time",
      type: "time",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.appointmentComment[language],
      name: "appointment_comment",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.phone[language],
      name: "phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.officeNumber[language],
      name: "office_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.mobileNumber[language],
      name: "mobile_phone",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.confirmationStatus[language],
      name: "confirmation_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.confirmationAgent[language],
      name: "confirmation_agent_name",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content: translations.createProspective.confirmationNotes[language],
      name: "confirmation_notes",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.confirmationStatusDate[language],
      name: "confirmation_status_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitStatus[language],
      name: "visit_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitStatusDate[language],
      name: "visit_status_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospective[language],
      name: "prospective_id",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.prospectiveSource[language],
      name: "prospective_source",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.clients.table.prospectiveType[language],
      name: "prospective_type",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.noShowSatus[language],
      name: "no_show_status",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content:
        translations.createProspective.no_show_status_entry_date[language],
      name: "no_show_status_entry_date",
      type: "date",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitStatusUser[language],
      name: "visit_status_user_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.appointments.appointment[language],
      name: "no_show_user_name",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.visitNotes[language],
      name: "visit_notes",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.createProspective.noShowNotes[language],
      name: "no_show_notes",
      type: "text",
      filterType: "",
      value: "",
    },
  ];
  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "Wants callback",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "Message left",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];
  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));

  const prospectiveSourceOptions2 = [
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt1[language],
      value: translations.createProspective.prospectiveSourceOptions.opt1["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt2[language],
      value: translations.createProspective.prospectiveSourceOptions.opt2["en"],
    },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt3[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt3["en"],
    // },
    // {
    //   label:
    //     translations.createProspective.prospectiveSourceOptions.opt4[language],
    //   value: translations.createProspective.prospectiveSourceOptions.opt4["en"],
    // },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt5[language],
      value: translations.createProspective.prospectiveSourceOptions.opt5["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt6[language],
      value: translations.createProspective.prospectiveSourceOptions.opt6["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt7[language],
      value: translations.createProspective.prospectiveSourceOptions.opt7["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt8[language],
      value: translations.createProspective.prospectiveSourceOptions.opt8["en"],
    },
  ];

  const exportToExcel = () => {
    // Filter the selected appointments based on their IDs
    const selectedData = appointments
      .filter((appointment) => selectedAppointments.includes(appointment.id)) // Only include selected appointments
      .map((appointment) => ({
        Event_ID: appointment?.prospective?.private_event?.nom_vp || "",
        // Event_Name: appointment.private_event.nom_vp || "Null",
        Client_ID: appointment?.prospective?.id || "",
        Appointment_ID: appointment?.id || "",
        Full_Name:
          (appointment.prospective?.first_name ?? "") +
            " " +
            (appointment.prospective?.last_name ?? "") || "",
        // Last_Name: appointment.prospective?.last_name || "Null",
        Phone: appointment.prospective?.phone || "",
        Prospect_Type: appointment.prospective?.prospect_type || "",
        Prospect_Source: appointment.prospective?.prospect_source || "",
        Office_Number: appointment.prospective?.office_phone || "",
        OG_Rep: appointment?.prospective?.og_rep || "",
        Visit_Status: appointment?.visit_status || "",
        Visit_Notes: appointment?.visit_notes || "",
        Call_Status: appointment?.prospective?.call_status || "",
        Make: appointment.prospective?.make || "",
        Model: appointment.prospective?.model || "",
        Year: appointment.prospective?.year || "",
        Appointment_Date: appointment?.appointment_date || "",
        Appointment_Time: appointment?.appointment_time || "",
        Appointment_Representative:
          appointment?.appointment_rep_user_name || "",
        Appointment_Comment: appointment?.appointment_comment || "",
        Confirmation_Status: appointment?.confirmation_status || "",
        Confirmation_Notes: appointment?.confirmation_notes || "",
        No_Show_Status: appointment?.no_show_status || "",
        No_Show_Notes: appointment?.no_show_notes || "",
        Province: appointment?.prospective?.province || "",
        Purchase_Date: appointment?.prospective?.purchase_date || "",
        VIN: appointment?.prospective?.vin || "",
        Visit_Status_User: appointment?.visit_status_user_name || "",
        Email: appointment?.prospective?.email || "",
        Greetings: appointment?.prospective?.greetings || "",
        KM: appointment?.prospective?.km || "",
        Language: appointment?.prospective?.language || "",
        List_ID: appointment?.prospective?.list_id || "",
        Mobile_Phone: appointment?.prospective?.mobile_phone || "",
        Postal_Code: appointment?.prospective?.postal_code || "",
        Address: appointment?.prospective?.address || "",
        Appointment: appointment?.prospective?.appointment || "",
        City: appointment?.prospective?.city || "",
      }));

    // Generate the Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Selected Appointments");

    // Save the file
    XLSX.writeFile(
      workbook,
      `Appointments Report ${format(new Date(), "dd MMMM yyyy")}.xlsx`
    );
  };

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });
  const fetchCalendarData = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/calendar-dashboard/" + eventId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        // const newData = data.private_events.data;
        if (!showTable) {
          fetchData();
        }
        const events =
          data.appointmentsPerRep && data.appointmentsPerRep.length > 0
            ? data.appointmentsPerRep
                .filter(
                  (item) => item.appointments && item.appointments.length > 0
                ) // Filter items with non-empty appointments array
                .flatMap((item) =>
                  item.appointments
                    .filter(
                      (appointment) =>
                        appointment.confirmation_status?.toLowerCase() !==
                        "cancel"
                    )
                    .map((appointment, index) => ({
                      id: appointment.id,
                      title:
                        (appointment?.prospective?.first_name ?? "") +
                        " " +
                        (appointment?.prospective?.last_name ?? ""),
                      start: getFormattedDate(
                        appointment.appointment_date,
                        appointment.appointment_time
                      ),
                      end: getFormattedDate(
                        appointment.appointment_end_date,
                        appointment.appointment_end_time
                      ),
                      data: {
                        appointment,
                      },
                      resourceId: appointment.appointment_rep_user_name,
                    }))
                )
            : [];

        setCalendarData(data.appointmentsPerRep);
        setTimeRange({
          start_time: data?.start_time,
          end_time: data?.end_time,
        });
        setMyEvents(events);
        setDealershipDetail(data?.dealershipDetails);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/appointment-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          appointmentsFilterData && appointmentsFilterData?.length > 0
            ? {
                ...appointmentsFilterPostData,
                private_event_id: eventId,
              }
            : {
                private_event_id: eventId,
              }
        ),
      });
      const data = await res.json();
      if (data.status === "success") {
        // const newData = data.private_events.data;

        setAppointments(data.appointments);

        setDeleteStates(data.appointments.map(() => false));

        // autoScrollMid();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (eventId) {
      fetchData();
    }
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields?.filter(
            (field) => data?.fillter[field.name] === 1
          );

          setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    const getExternalUsers = async () => {
      // setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const repData = data?.externalUsers?.length
            ? data?.externalUsers?.map((list) => ({
                label: list.appointment_rep_user_name,
                value: list.appointment_rep_user_name,
              }))
            : [];
          const typeOptions = data?.prospective_type?.length
            ? data?.prospective_type?.map((list) => ({
                label: list,
                value: list,
              }))
            : [];
          setRepOptions(repData);
          setProspectiveType(typeOptions);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchClients = async () => {
      try {
        const res = await fetch(ApiUrl + `/api/user/prospective/${eventId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          setClients(
            data?.prospectives?.map((prospect) => {
              return { label: prospect.first_name, value: prospect.id };
            })
          );
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
        setIsLoading(false);
      }
    };
    const getDates = async () => {
      try {
        // setIsLoading(true);
        const res = await fetch(ApiUrl + `/api/user/fillter-dates/${eventId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const newData =
            data.appointment_dates &&
            data.appointment_dates.length > 0 &&
            data.appointment_dates.map((item) => {
              return { label: item, value: item };
            });
          const currentDate2 =
            newData && newData.length > 0 && newData[0]?.label
              ? newData[0]?.label
              : new Date();
          // setCurrentDate(new Date(currentDate2));
          setCurrentDate(
            calendarSelectedDate !== "" ? calendarSelectedDate : currentDate2
          );
          setSelectedDate(
            calendarSelectedDate !== "" ? calendarSelectedDate : currentDate2
          );
          setEventDates(newData);
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
        setIsLoading(false);
      }
    };
    if (token && eventId) {
      fetchCalendarData();
      fetchData();
      // getFiltersData();
      getExternalUsers();
      fetchClients();
      getDates();
    }
    if (data?.user_views) {
      setTableViews([...data?.user_views]);
      const currentViewObj = data?.user_views.find(
        (item) => item.current === "1"
      );

      setCurrentView({
        ...currentViewObj,
        columns: currentViewObj?.columns || [],
      });
    }
  }, [eventId, data]);

  function getFormattedDate(appointment_date, appointment_time) {
    // Default time if appointment_time is missing or invalid
    const defaultTime = "09:00:00";
    const timeToUse =
      appointment_time && appointment_time.includes(":")
        ? appointment_time
        : defaultTime;

    // Parse the date and time components
    if (!appointment_date) {
      console.error("Missing appointment date");
      return null;
    }

    const [year, month, day] = appointment_date.split("-").map(Number);
    const [hours, minutes, seconds] = timeToUse.split(":").map(Number);

    // Construct startDate object
    const startDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds || 0
    );

    if (isNaN(startDate)) {
      // console.error("Invalid startDate generated");
      return null;
    }

    // console.log("Generated Start Date:", startDate);
    return startDate;
  }

  const getFilteredEvents = async (filterPost, checkedFieldsData) => {
    // setIsLoading(true);
    // setAllowScrollFetch(false);

    try {
      const res = await fetch(ApiUrl + "/api/user/appointment-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ private_event_id: eventId, ...filterPost }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        localStorage.setItem(
          "appointmentsFilterPostData",
          JSON.stringify(filterPost)
        );
        localStorage.setItem(
          "appointmentsFilterData",
          JSON.stringify(checkedFieldsData)
        );
        setAppointments(data.appointments);

        setDeleteStates(data.appointments.map(() => false));
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      // setAllowScrollFetch(true);
      setLoading(false);
    }
  };

  const moveColumn = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = currentView.columns.findIndex(
      (col) => col.id === active.id
    );
    const newIndex = currentView.columns.findIndex((col) => col.id === over.id);

    const reorderedColumns = arrayMove(currentView.columns, oldIndex, newIndex);
    const updatedData = { ...currentView, columns: reorderedColumns };
    setCurrentView((prevView) => ({ ...prevView, columns: reorderedColumns }));
    if (updatedData.type !== "default appointment") {
      updateView(updatedData);
    }
  };

  const handleResize = (columnId, newWidth) => {
    columnWidthsRef.current[columnId] = newWidth;
    requestAnimationFrame(() => {
      const columnElement = document.getElementById(columnId);
      if (columnElement) columnElement.style.width = `${newWidth}px`;
    });
    // Mark that a state update is needed
    if (!pendingStateUpdate.current) {
      pendingStateUpdate.current = true;
      debouncedUpdate(setCurrentView);
    }
  };

  // Debounce for smoother updates instead of throttle
  const debouncedUpdate = debounce((setCurrentView) => {
    const updatedData = {
      ...currentView,
      columns: currentView.columns.map((col) =>
        col.id in columnWidthsRef.current
          ? { ...col, width: columnWidthsRef.current[col.id] }
          : col
      ),
    };
    setCurrentView((prevColumns) => ({
      ...prevColumns,
      columns: prevColumns.columns.map((col) =>
        col.id in columnWidthsRef.current
          ? { ...col, width: columnWidthsRef.current[col.id] }
          : col
      ),
    }));
    if (updatedData.type !== "default appointment") {
      updateView(updatedData);
    }
    pendingStateUpdate.current = false; // Reset flag after update
  }, 16); // Adjust delay as needed

  const updateView = async (updatedData) => {
    try {
      const res = await fetch(ApiUrl + "/api/user/views", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data?.message);
        const viewData2 = {
          ...data.userView,
          columns: JSON.parse(data.userView.columns),
        };
        setTableViews((prev) =>
          prev.map((item) => {
            if (item.id === updatedData.id) {
              return viewData2;
            }
            return item;
          })
        );
      } else {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const getTranslatedConfirmationStatus = (status) => {
    const option = confirmationStatusOptions.find(
      (opt) => opt.value === status
    );
    return option ? option.label : status;
  };
  const getTranslatedVisitStatus = (status) => {
    const option = visitStatusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const getTranslatedNoShowStatus = (status) => {
    const option = noShowOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const exportData = (fileType) => {
    if (!currentView || !currentView.columns) {
      console.error("Current view is not set or missing columns.");
      return;
    }

    const selectedColumns = currentView.columns
      .slice(0, -1)
      .filter((item) => item.renderInView !== false)
      .map((col) => ({
        key: col.id,
        header: col.content || col.id,
      }));

    const selectedData =
      Array.isArray(appointments) && appointments.length > 0
        ? appointments.map((appointment) => {
            let rowData = {};

            selectedColumns.forEach(({ key, header }) => {
              let value = "";

              if (key === "full_name") {
                value = `${
                  appointment?.prospective?.first_name !== "null" &&
                  appointment?.prospective?.first_name
                    ? appointment.prospective.first_name
                    : ""
                } ${
                  appointment?.prospective?.last_name !== "null" &&
                  appointment?.prospective?.last_name
                    ? appointment.prospective.last_name
                    : ""
                }`.trim();
              } else if (key === "prospect_source") {
                value = appointment?.prospective?.prospect_source
                  ? (() => {
                      const matchedOption = prospectiveSourceOptions.find(
                        (option) =>
                          option.value.en?.toLowerCase() ===
                            appointment?.prospective?.prospect_source?.toLowerCase() ||
                          option.value.fr?.toLowerCase() ===
                            appointment?.prospective?.prospect_source?.toLowerCase()
                      );

                      return matchedOption
                        ? matchedOption.label[language]
                        : appointment?.prospective?.prospect_source;
                    })()
                  : "";
              } else if (key === "visit_status") {
                value = getTranslatedVisitStatus(appointment?.visit_status);
              } else if (key === "confirmation_status") {
                value = getTranslatedConfirmationStatus(
                  appointment?.confirmation_status
                );
              } else if (key === "no_show_status") {
                value = getTranslatedNoShowStatus(appointment?.no_show_status);
              } else {
                value =
                  appointment?.prospective?.[key] || appointment[key] || "";
              }

              rowData[header] = value;
            });

            return rowData;
          })
        : [];

    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");

    if (fileType === "csv") {
      const csvData = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Export_Appointments_${format(
        new Date(),
        "dd MMMM yyyy"
      )}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // ✅ Prevent memory leak
    } else {
      XLSX.writeFile(
        workbook,
        `Export_Appointments_${format(new Date(), "dd MMMM yyyy")}.xlsx`
      );
    }
  };

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => debouncedUpdate.cancel();
  }, [data]);

  return (
    <div>
      {!loading && calendarData ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <AppointmentsFilterbar
              isDarkMode={isDarkMode}
              allfields={allfields}
              checkedFields={checkedFields}
              setCheckedFields={setCheckedFields}
              textOptions={textOptions}
              repOptions={repOptions}
              confirmationStatusOptions={confirmationStatusOptions}
              visitStatusOptions={visitStatusOptions}
              noShowOptions={noShowOptions}
              clients={clients}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              prospectiveSourceOptions={prospectiveSourceOptions2}
              // filterOption={filterOption}
              // setFilterOption={setFilterOption}
              // dealerOptions={dealerOptions}
              // repOptions2={repOptions2}
              // managers={managers}
              // packageOptions={packageOptions}
              // animatorOptions={animatorOptions}
              getFilteredEvents={getFilteredEvents}
              prospectiveType={prospectiveType}
              // weekOptions={weekOptions}
              appointmentTimeSlots={appointmentTimeSlots}
            />
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-7 d-flex align-items-center gap-3">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.eventView.buttons.calender[language]}
                    {" - "} {dealershipDetail?.name}
                    {": "}
                    {/* {translations.appointments.title[language]} */}
                    {dealershipDetail?.start_date}{" "}
                    {translations.commonWords.to[language]}{" "}
                    {dealershipDetail?.end_date}
                  </h2>
                </div>
              </div>
              <div className="col-5 d-flex align-items-center justify-content-end gap-1">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="d-flex btn btn-primary align-items-center admin-btn gap-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("event-view");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=event-view&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="d-flex btn-group align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className=""
                    style={{
                      background: "#05cc9e",
                      // color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="d-flex btn-group align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn btn-primary admin-btn"
                    onClick={() => {
                      setDisplayComponent("clients");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=clients&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>
                <div
                  className="d-flex btn-group align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="d-flex btn btn-primary align-items-center admin-btn gap-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("copy-client");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=copy-client&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-clipboard-plus"></i>{" "}
                    {translations.forms.formFields.copyClient[language]}
                  </button>
                </div>
                <div
                  className="d-flex btn-group align-items-center gap-1"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn btn-primary admin-btn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("eventReport");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=eventReport&event_id=" + eventId
                      );
                    }}
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>

            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex align-items-center gap-3">
                {!showTable && (
                  <div
                    className="input-group"
                    style={{ width: "calc(100% - 125px)" }}
                  >
                    <span
                      className="input-group-text"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    >
                      Date
                    </span>

                    <select
                      class={`form-select ${isDarkMode ? "custom-select" : ""}`}
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                      value={selectedDate}
                      onChange={(e) => {
                        setSelectedDate(e.target.value);
                        if (e.target.value && e.target.value !== "") {
                          // const newDate = myEvents.find()
                          // {console.log(e.target.value)}
                          // setCurrentDate(new Date(e.target.value));
                          setCurrentDate(e.target.value);
                        }

                        localStorage.setItem(
                          "calendarSelectedDate",
                          JSON.stringify(e.target.value)
                        );
                      }}
                    >
                      <option
                        value=""
                        style={{
                          background: isDarkMode ? "#1B1F22" : "",
                          color: isDarkMode ? "#ffffff" : "",
                        }}
                      >
                        {translations.dropdown.select[language]}
                      </option>
                      {eventDates &&
                        eventDates.length > 0 &&
                        eventDates.map((date) => (
                          <option
                            key={date.value}
                            value={date.value}
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {date.label}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <div
                  className="row align-items-center"
                  // style={{ minHeight: "44px" }}
                >
                  <div className="col-3">
                    {selectedAppointments &&
                      selectedAppointments.length > 0 &&
                      showTable && (
                        <button
                          type="button"
                          className="d-flex align-items-center client-btn gap-2"
                          onClick={exportToExcel}
                        >
                          <i className="bi bi-upload"></i>
                          {translations.commonWords.export[language]}{" "}
                        </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-8 d-flex align-items-center justify-content-end gap-4">
                <div className="d-flex align-items-center gap-2">
                  <p
                    style={{
                      fontWeight: showTable ? "" : "bold",
                      color: showTable ? "" : "#05cc9e",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowTable(!showTable);
                      setSelectAll(false);
                      setSelectedAppointments([]);
                    }}
                  >
                    Cal |{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: showTable ? "bold" : "",
                      color: showTable ? "#05cc9e" : "",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowTable(!showTable)}
                  >
                    {translations.commonFields.List[language]}
                    {/* List */}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-primary admin-btn"
                      onClick={() => {
                        setDisplayComponent("add-appointment");

                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=add-appointment&event_id=" +
                            eventId
                        );
                      }}
                    >
                      <i className={`bi bi-clipboard-plus`}></i>{" "}
                      {translations.commonFields.addBtn[language]}
                    </button>
                  </div>
                  {showTable && (
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        id=""
                        type="button"
                        className="btn btn-primary admin-btn"
                        data-bs-target="#appointmentsFiltersidebar"
                        data-bs-toggle="offcanvas"
                        aria-bs-controls="offcanvasmain"
                      >
                        <i className="bi bi-search"></i>{" "}
                      </button>
                    </div>
                  )}
                  {showTable && (
                    <>
                      {currentView &&
                        (currentView?.csv === "1" ||
                          currentView?.csv === true ||
                          currentView?.xlsx === "1" ||
                          currentView?.xlsx === true) && (
                          <div
                            className="btn-group"
                            role="group"
                            aria-label="Basic example"
                          >
                            <div className="btn-group" role="group">
                              {(currentView?.xlsx === true ||
                                currentView?.xlsx === "1") && (
                                <button
                                  type="button"
                                  className="btn admin-btn btn-primary edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                  style={{
                                    width: "100%",
                                    maxWidth: "150px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                  onClick={() => {
                                    exportData("xlsx");
                                  }}
                                >
                                  <i className={"bi bi-filetype-xlsx"}></i>{" "}
                                </button>
                              )}
                              {(currentView?.csv === true ||
                                currentView?.csv === "1") && (
                                <button
                                  type="button"
                                  className="btn btn-danger edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                  style={{
                                    width: "100%",
                                    maxWidth: "150px",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                  onClick={() => {
                                    exportData("csv");
                                  }}
                                >
                                  <i className={"bi bi-filetype-csv"}></i>{" "}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-light dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ width: "125px" }}
                        >
                          {/* {translations.EventsPortal.views.default[language]} */}
                          {currentView?.name}
                        </button>
                        <ul
                          className="dropdown-menu"
                          style={{
                            minWidth: "250px",
                            background: isDarkMode ? "#2b3035" : "#FFFFFF",
                          }}
                        >
                          <li style={{ maxHeight: "200px", overflow: "auto" }}>
                            {tableViews &&
                              tableViews.map((view, index) => (
                                <li
                                  className="w-100"
                                  key={index}
                                  style={{
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#343A3F"
                                        : "#34DBB4",
                                    },
                                  }}
                                >
                                  <div
                                    className={`dropdown-item ${
                                      isDarkMode
                                        ? "views-hover-dark"
                                        : "views-hover"
                                    }  px-2 d-flex align-items-center justify-content-between w-100`}
                                    href="#"
                                    style={{
                                      ":hover": {
                                        background: isDarkMode
                                          ? "#343A3F"
                                          : "#34DBB4",
                                      },
                                    }}
                                  >
                                    <p
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        const updatedData = tableViews.map(
                                          (item) =>
                                            item.id === view.id
                                              ? { ...item, current: true }
                                              : { ...item, current: false }
                                        );
                                        setTableViews(updatedData);
                                        setCurrentView({
                                          ...view,
                                          current: true,
                                        });
                                        updateView({ ...view, current: true });
                                      }}
                                    >
                                      {view.name}{" "}
                                    </p>
                                    <div
                                      className="d-flex align-items-center gap-2"
                                      style={{ fontSize: "18px" }}
                                    >
                                      <i
                                        className="bi bi-star-fill"
                                        style={{
                                          color:
                                            currentView?.id === view.id
                                              ? "gold"
                                              : "#adb5bd",
                                        }}
                                      ></i>{" "}
                                      <i
                                        className="bi bi-pencil-fill"
                                        style={{
                                          color:
                                            view.type === "default appointment"
                                              ? "#adb5bd"
                                              : "#445775",
                                          cursor:
                                            view.type === "default appointment"
                                              ? "default"
                                              : "pointer",
                                        }}
                                        onClick={() => {
                                          if (
                                            view.type !== "default appointment"
                                          ) {
                                            setViewData({
                                              ...view,
                                              mode: "edit",
                                            });
                                            button.click();
                                          }
                                        }}
                                      ></i>{" "}
                                      <i
                                        className="bi bi-trash-fill"
                                        style={{
                                          color:
                                            currentView?.id === view?.id ||
                                            view.type === "default appointment"
                                              ? "#adb5bd"
                                              : "#dc3545",
                                          cursor:
                                            currentView?.id === view?.id ||
                                            view.type === "default appointment"
                                              ? "default"
                                              : "pointer",
                                        }}
                                        onClick={() => {
                                          if (
                                            currentView?.id !== view?.id &&
                                            view.type !== "default appointment"
                                          ) {
                                            setViewData({
                                              ...view,
                                              mode: "delete",
                                            });
                                            button.click();
                                          }
                                        }}
                                      ></i>{" "}
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </li>

                          <li className="px-2 mt-2 w-100">
                            <button
                              type="button"
                              className="btn btn-light w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#addViewModal"
                              fdprocessedid="2rud2c"
                              id="openViewModal"
                            >
                              <i className="bi bi-plus"></i>{" "}
                              {
                                translations.EventsPortal.views.addNewView[
                                  language
                                ]
                              }
                            </button>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {showTable ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={moveColumn}
              >
                <SortableContext
                  items={
                    currentView &&
                    currentView?.columns?.slice(0, -1)?.map((col) => col.id)
                  }
                  strategy={horizontalListSortingStrategy}
                >
                  <TableView
                    isDarkMode={isDarkMode}
                    appointments={appointments}
                    setDisplayComponent={setDisplayComponent}
                    setDeleteStates={setDeleteStates}
                    eventId={eventId}
                    deleteStates={deleteStates}
                    setLoading={setLoading}
                    setAppointments={setAppointments}
                    selectedAppointments={selectedAppointments}
                    setSelectedAppointments={setSelectedAppointments}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    myEvents={myEvents}
                    setMyEvents={setMyEvents}
                    calendarData={calendarData}
                    setIsLoading={setIsLoading}
                    fetchCalendarData={fetchCalendarData}
                    currentView={currentView}
                    handleResize={handleResize}
                    data={data}
                    setTableViews={setTableViews}
                    viewData={viewData}
                    setViewData={setViewData}
                    setCurrentView={setCurrentView}
                  />
                </SortableContext>
              </DndContext>
            ) : (
              // <CalendarView
              //   isDarkMode={isDarkMode}
              //   appointments={appointments}
              //   setAppointments={setAppointments}
              //   eventId={eventId}
              //   setIsLoading={setIsLoading}
              // />
              <>
                {calendarData && (
                  <CalendarView2
                    isDarkMode={isDarkMode}
                    calendarData={calendarData}
                    setCalendarData={setCalendarData}
                    myEvents={myEvents}
                    setMyEvents={setMyEvents}
                    eventId={eventId}
                    setIsLoading={setIsLoading}
                    setDisplayComponent={setDisplayComponent}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timeRange={timeRange}
                    fetchCalendarData={fetchCalendarData}
                    appointments={appointments}
                    setAppointments={setAppointments}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Appointments;
