import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import PaginatedItems from "./Pagination";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import AddWeekModal from "./addWeekModal";
import Loader from "./loader";
import Select from "react-select";
import CreateFormModal from "./createFormModal";
import EditEventModal from "./ModalAddDealer_CreateEvent";
import { useNavigate } from "react-router-dom";
import FilterBar from "./components/filterBar";
import debounce from "lodash/debounce";
import { externalUsers, internalUsers } from "./constants/userTypes";
import numeral from "numeral";
import * as XLSX from "xlsx";
import DraggableHeaderCell from "./components/EventsPage/DraggableHeaderCell";
import { horizontalListSortingStrategy } from "@dnd-kit/sortable";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import AddViewModal from "./components/EventsPage/AddViewModal";
import { format } from "date-fns";
import useGetViewsData from "./hooks/useGetUserData";

const EventsNew = ({
  setDisplayComponent,
  eventTrigger,
  setEditEventID,
  setIsDarkMode,
  isDarkMode,
  isLoading,
  setIsLoading,
  users,
  dealerships,
  setPrevDisplay,
  events,
  setEvents,
  stats,
  setStats,
  key,
  setKey,
  total,
  setTotal,
  searchCache,
  setSearchCache,
  pageCache,
  setPageCache,
  deleteStates,
  setDeleteStates,
  pageNo,
  setPageNo,
  selectedDealershipOption,
  setSelectedDealership,
  totalEvents,
  setTotalEvents,
  setDealershipLabel,
  selectedOptionRef,
  checkedFields,
  setCheckedFields,
}) => {
  const { language } = useLanguage();
  var button = document.getElementById("openViewModal");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const eventsFilterData = JSON.parse(localStorage.getItem("eventsFilterData"));
  const dealershipFilter = JSON.parse(localStorage.getItem("dealershipFilter"));
  const eventsFilterPostData = JSON.parse(
    localStorage.getItem("eventsFilterPostData")
  );
  const userType = localStorage.getItem("userType");
  const pageTranslations = translations["EventsPortal"];
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const navigate = useNavigate();
  const [filtersData, setFiltersData] = useState(eventsFilterPostData || []);
  const statusOptions = [
    {
      label: translations.usersPage.createUser.statusOptions.active[language],
      value: "Active",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "Cancelled",
    },
    {
      label: translations.usersPage.createUser.statusOptions.waiting[language],
      value: "Waiting",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.completed[language],
      value: "Completed",
    },
  ];
  const filterOptions = [
    {
      label: translations.EventsPortal.filterOptions.currentYear[language],
      value: "currentYear",
    },
    {
      label: "Archive",
      value: "previousYear",
    },
    {
      label: translations.EventsPortal.filterOptions.futureYear[language],
      value: "future",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "cancel",
    },
  ];
  const token = localStorage.getItem("token");
  const [clickedRow, setClickedRow] = useState(null);
  const [eventsSearch, setEventsSearch] = useState();
  const [editID, seteditID] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  const [eventId, setEventId] = useState("");
  const [parentId, setParentId] = useState("");
  const [week, setWeek] = useState("");
  const [loading, setLoading] = useState("");
  const [filterOption, setFilterOption] = useState(filterOptions[0].value);

  const [oldData, setOldData] = useState(null);
  const [brands, setBrands] = useState();
  const [parentForms, setParentForms] = useState([]);
  const [clientForms, setClientForms] = useState(false);
  const [childId, setChildId] = useState("");
  // const [checkedFields, setCheckedFields] = useState([]);
  const [repOptions2, setrepOptions2] = useState([]);
  const [agents, setagents] = useState([]);
  const [managers, setmanagers] = useState([]);
  const [packageOptions, setpackageOptions] = useState([]);
  const containerRef = useRef(null);
  const topMarkerRef = useRef(null);
  const bottomMarkerRef = useRef(null);
  const [allowScrollFetch, setAllowScrollFetch] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [initialScrollDone, setInitialScrollDone] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const isFetchingRef = useRef(false);
  const lastScrollTopRef = useRef(0);
  const [dataEnd, setDataEnd] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const columnWidthsRef = useRef({});
  const pendingStateUpdate = useRef(false);

  const getColumns = () => {
    return {
      name: "Default View",
      current: true,
      type: "default event",
      csv: true,
      xlsx: true,
      columns: [
        {
          id: "week",
          content: translations.forms.formFields.week[language],
          style: {
            position: "-webkit-sticky",
            position: "sticky",
            left: "0px",
            // width: "57px",
          },
          width: 57,
          className: "text-center",
          renderInView: true,
        },
        {
          id: "nom_vp",
          content: translations.commonFields.name[language],
          style: {
            // position: "-webkit-sticky",
            // position: "sticky",
            // left: language === "fr" ? "80px" : "58px",
            // width: "83px",
          },
          width: 83,
          renderInView: true,
        },
        {
          id: "dealership",
          content: translations.quickCreationModal.dealership[language],
          width: 120,
          renderInView: true,
        },
        {
          id: "package",
          content: translations.forms.privateEventformFields.package[language],
          showIf: userType && internalUsers.includes(userType),
          width: 100,
          renderInView: true,
        },
        {
          id: "start_date",
          content:
            translations.forms.privateEventformFields.startDate[language],
          style: { minWidth: "125px" },
          width: 125,
          renderInView: true,
        },
        {
          id: "end_date",
          content: translations.forms.privateEventformFields.endDate[language],
          width: 125,
          renderInView: true,
        },
        {
          id: "clients",
          content:
            translations.usersPage.createUser.statusOptions.active[language],
          showIf: userType && internalUsers.includes(userType),
          width: 80,
          renderInView: true,
        },
        {
          id: "inactive",
          content: translations.forms.formFields.inactive[language],
          showIf: userType && internalUsers.includes(userType),
          style: { minWidth: "55px" },
          width: 80,
          renderInView: true,
        },
        {
          id: "conquest",
          content:
            translations.forms.formFields.conquestSection.title[language],
          showIf:
            userType &&
            internalUsers.includes(userType) &&
            (userData.roles[0].name === "Project Manager" ||
              userData.roles[0].name === "Representative" ||
              userData.roles[0].name === "Admin" ||
              userData.roles[0].name === "Super Admin"),
          style: { minWidth: "55px" },
          width: 100,
          renderInView: true,
        },
        {
          id: "amount",
          content: translations.forms.formFields.amount[language],
          showIf: userType && internalUsers.includes(userType),
          width: 100,
          renderInView: true,
        },
        {
          id: "sales_rep",
          content: translations.forms.formFields.representative[language],
          width: 120,
          renderInView: true,
        },
        {
          id: "brand",
          content: translations.quickCreationModal.brand[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "project_manager",
          content: translations.forms.formFields.projectManager[language],
          width: language === "en" ? 100 : 120,
          renderInView: true,
        },
        {
          id: "confirmation_agent",
          content: translations.forms.formFields.confirmationAgent[language],
          width: language === "en" ? 160 : 180,
          renderInView: true,
        },
        {
          id: "no_show_agent",
          content: translations.forms.formFields.noShowAgent[language],
          width: language === "en" ? 160 : 180,
          renderInView: true,
        },
        {
          id: "carton",
          content: translations.forms.formFields.carton[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "mailing",
          content: translations.forms.formFields.mailing[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "massif",
          content: translations.forms.formFields.massif[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "web",
          content: translations.forms.formFields.web[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "banner",
          content: translations.forms.formFields.banner[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "news_letter",
          content: translations.forms.formFields.newsletter[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "facebook",
          content: translations.forms.formFields.facebook[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "ad_words",
          content: translations.forms.formFields.adwords[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "sms",
          content: "SMS",
          width: 100,
          renderInView: true,
        },
        {
          id: "invoice",
          content: translations.forms.formFields.invoice[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "amount_animator",
          content: translations.forms.formFields.amountAnimator[language],
          width: language === "en" ? 100 : 140,
          renderInView: true,
        },
        {
          id: "invoice_animator",
          content: translations.forms.formFields.invoiceAnimator[language],
          width: language === "en" ? 100 : 140,
          renderInView: true,
        },
        {
          id: "commission_paid",
          content: translations.forms.formFields.commissionPaid[language],
          width: language === "en" ? 100 : 140,
          renderInView: true,
        },
        {
          id: "instructions",
          content: translations.forms.formFields.instructions[language],
          width: 100,
          renderInView: true,
        },
        {
          id: "animator",
          content: translations.forms.formFields.animator[language],
          width: language === "en" ? 150 : 180,
          renderInView: true,
        },
        // {
        //   id: "status",
        //   content: translations.usersPage.createUser.status[language],
        //   showIf: userType !== "Employee" && userType !== "Manager",
        //   style: {
        //     position: "-webkit-sticky",
        //     position: "sticky",
        //     right: calculateStatusRightPosition(
        //       language,
        //       userType,
        //       StatusCondition,
        //       StatusCondition2,
        //       StatusCondition3,
        //       StatusCondition4
        //     ),
        //   },
        //   width: 100,
        //   renderInView: true,
        // },
        {
          id: "actions",
          content: pageTranslations["eventsTableHeader"].actions[language],
          showIf:
            userData?.roles[0].permissions.some(
              (item) => item.name === "Create Parent Form"
            ) ||
            userData?.roles[0].permissions.some(
              (item) => item.name === "Private Event Index"
            ) ||
            userData?.roles[0].permissions.some(
              (item) => item.name === "Private Event Destroy"
            ),
          style: {
            position: "-webkit-sticky",
            position: "sticky",
            right: 0,
          },
          width: 100,
          className: "text-center",
          colSpan: 5,
          renderInView: true,
        },
      ],
    };
  };
  const data = useGetViewsData("event");
  const [tableViews, setTableViews] = useState([]);
  const [currentView, setCurrentView] = useState(getColumns());
  const [viewData, setViewData] = useState(null);

  const hasPrivateEventDestroy =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Private Event Destroy"
    );

  const hasCreateParentForm =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Create Parent Form"
    );

  const hasPrivateEventShow =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Private Event Index"
    );
  const hasProspectiveIndex =
    userData &&
    userData.roles[0].permissions.some(
      (item) => item.name === "Prospective Index"
    );
  const StatusCondition =
    (hasPrivateEventDestroy && !hasCreateParentForm && !hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && hasCreateParentForm && !hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && !hasCreateParentForm && hasPrivateEventShow);
  const StatusCondition2 =
    (hasPrivateEventDestroy && hasCreateParentForm && !hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && hasCreateParentForm && hasPrivateEventShow) ||
    (!hasPrivateEventDestroy && hasCreateParentForm && hasPrivateEventShow);
  const StatusCondition3 =
    !hasPrivateEventDestroy && !hasCreateParentForm && !hasPrivateEventShow;
  const StatusCondition4 =
    !hasPrivateEventDestroy && !hasCreateParentForm && hasPrivateEventShow;
  const repOptions =
    users &&
    users.representatives.length > 0 &&
    users.representatives.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const dealerOptions =
    dealerships &&
    dealerships.length > 0 &&
    dealerships.map((dealer) => ({
      value: dealer.id,
      label: dealer.name,
    }));

  function formatDate(date) {
    const datesArray = date.split(" / ");
    const lastDate = datesArray[datesArray.length - 1];

    return lastDate;
  }

  const allfields = [
    {
      content: translations.forms.formFields.week[language],
      name: "week",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.commonFields.name[language],
      name: "nom_vp",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.quickCreationModal.dealership[language],
      name: "dealership",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.projectManager[language],
      name: "project_manager",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.sales_representative[language],
      name: "sales_rep",
      type: "dropdown",
      filterType: "",
      value: "",
    },

    {
      content: translations.forms.privateEventformFields.package[language],
      name: "package",
      type: "dropdown",
      filterType: "",
      value: "",
    },

    {
      content: translations.forms.privateEventformFields.dateRange[language],
      name: "date_range",
      type: "date_range",
      filterType: "date_range",
      value: "",
      component: [
        {
          content:
            translations.forms.privateEventformFields.startDate[language],
          name: "start_date",
          type: "date",
          filterType: "",
          value: "",
        },
        {
          content: translations.forms.privateEventformFields.endDate[language],
          name: "end_date",
          type: "date",
          filterType: "",
          value: "",
        },
      ],
    },
    {
      content: translations.forms.formFields.animator[language],
      name: "animator",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.mailing[language],
      name: "mailing",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.web[language],
      name: "web",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.banner[language],
      name: "banner",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.newsletter[language],
      name: "news_letter",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.facebook[language],
      name: "facebook",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.adwords[language],
      name: "ad_words",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.clients[language],
      name: "clients",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.inactive[language],
      name: "inactive",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.conquestSection.title[language],
      name: "conquest",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.massif[language],
      name: "massif",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.amount[language],
      name: "amount",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.confirmationAgent[language],
      name: "confirmation_agent",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.noShowAgent[language],
      name: "no_show_agent",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.carton[language],
      name: "carton",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: "SMS",
      name: "sms",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.quickCreationModal.brand[language],
      name: "brand",
      type: "dropdown",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.amountAnimator[language],
      name: "amount_animator",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.invoiceAnimator[language],
      name: "invoice_animator",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.commissionPaid[language],
      name: "commission_paid",
      type: "text",
      filterType: "",
      value: "",
    },
    {
      content: translations.forms.formFields.instructions[language],
      name: "instructions",
      type: "text",
      filterType: "",
      value: "",
    },

    {
      content:
        translations.forms.privateEventformFields.promoAndRequestSection
          .comments[language],
      name: "comments",
      type: "text",
      filterType: "",
      value: "",
    },
  ];

  const textOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const weekOptions = [
    {
      label: translations.commonFields.queryFilters.contain[language],
      value: "like",
    },
    {
      label: translations.commonFields.queryFilters.notContain[language],
      value: "not like",
    },
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
    {
      label: translations.commonFields.queryFilters.between[language],
      value: "between",
    },
  ];

  const textOptions2 = [
    {
      label: translations.commonFields.queryFilters.equal[language],
      value: "=",
    },
    {
      label: translations.commonFields.queryFilters.notEqual[language],
      value: "!=",
    },
  ];
  const animatorOptions =
    users?.animaters?.length > 0
      ? [
          {
            label: translations.dropdown.animatorOptions.op1[language],
            value: "1.1",
          },
          {
            label: translations.dropdown.animatorOptions.op2[language],
            value: "1.2",
          },
          ...users.animaters.map((user) => ({
            value: user.id,
            label: user.name,
          })),
        ]
      : [];

  const fetchData2 = async (scrollType, week) => {
    const tbody = document.getElementById("tbody");

    try {
      const res = await fetch(ApiUrl + "/api/user/private-events-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          setweek: week,
          scrollType: scrollType,
          type: filterOption ? filterOption : "",
          [filterOption === "cancel" ? "cancelYear" : "previousYear"]:
            selectedYear ? selectedYear : "",
          dealership_id:
            dealershipFilter && dealershipFilter.length > 0
              ? dealershipFilter?.map((option) => option?.value)
              : selectedDealershipOption?.map((option) => option.value) || [],
          ...filtersData,
        }),
      });

      const data = await res.json();

      if (data.status === "success") {
        const newData = data.private_events;
        const updatedEvents = { ...events };
        if (Object.keys(newData) && Object.keys(newData).length > 0) {
          Object.keys(newData).length > 0 &&
            Object.keys(newData).forEach((week) => {
              if (updatedEvents[week]) {
                const uniqueNewEntries = newData[week].filter(
                  (newEntry) =>
                    !updatedEvents[week].some(
                      (existingEntry) => existingEntry.id === newEntry.id
                    )
                );
                updatedEvents[week] = [
                  ...updatedEvents[week],
                  ...uniqueNewEntries,
                ];
              } else {
                updatedEvents[week] = newData[week];
              }
            });
        }

        if (Object.keys(newData).length === 0) {
          setDataEnd(true);
        }
        const sortedUpdatedEvents =
          Object.keys(updatedEvents) &&
          Object.keys(updatedEvents).length > 0 &&
          Object.keys(updatedEvents)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            )
            .reduce((sortedObj, week) => {
              sortedObj[week] = updatedEvents[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        let totalEventss = 0;

        for (const week in sortedUpdatedEvents) {
          if (sortedUpdatedEvents.hasOwnProperty(week)) {
            totalEventss += sortedUpdatedEvents[week].length;
          }
        }

        if (totalEventss !== totalEvents) {
          setStats(data);
          setTotalEvents(totalEventss);
        }
        setDeleteStates(
          sortedUpdatedEvents &&
            Object.keys(sortedUpdatedEvents).map((key) =>
              sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
            )
        );
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
    }
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const url =
          eventsFilterData && eventsFilterData.length > 0
            ? `${ApiUrl}/api/user/private-events-index`
            : `${ApiUrl}/api/user/private-events-index?page=${pageNo}`;

        const options = {
          method:
            eventsFilterData && eventsFilterData.length > 0 ? "POST" : "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body:
            eventsFilterData && eventsFilterData.length > 0
              ? JSON.stringify(eventsFilterPostData)
              : null,
        };

        const res = await fetch(url, options);
        const data = await res.json();
        if (data.status === "success") {
          const newData = data.private_events;
          const newTotal = data.private_events?.total;

          let totalEventss = 0;

          for (const week in newData) {
            if (newData.hasOwnProperty(week)) {
              totalEventss += newData[week].length;
            }
          }

          Promise.all([
            setEvents(newData),

            setStats(data),

            setTotalEvents(totalEventss),

            setEventsSearch(newData),
            setTotal(newTotal),
            setrepOptions2(
              data?.data.representative.length > 0 &&
                data.data.representative.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            ),
            setagents(
              data?.data.agents.length > 0 &&
                data.data.agents.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            ),
            setmanagers(
              data?.data.projectManagers.length > 0 &&
                data.data.projectManagers.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            ),
            setpackageOptions(
              data?.data.packages.length > 0 &&
                data.data.packages.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))
            ),
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            })),
            setDeleteStates(
              newData &&
                Object.keys(newData).map((key) =>
                  newData[key].map((item) => ({ [item.id]: false }))
                )
            ),
          ]).then(() => {
            setDataLoaded(true);
          });
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(translations.alertMessages.wentWrong[language]);
      } finally {
      }
    };
    console.log(selectedDealershipOption);

    if (!activeSearch && token && selectedDealershipOption?.length === 0) {
      fetchData();
    }

    const userData = JSON.parse(localStorage.getItem("userData"));
    setIsDarkMode(userData.theme_mode !== "light" ? true : false);
  }, []);

  useEffect(() => {
    if (eventTrigger) {
      const newData = eventTrigger.data;
      const newTotal = eventTrigger.total;

      setPageCache((prevCache) => ({
        ...prevCache,
        [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
      }));

      setEvents(newData);
      setEventsSearch(newData);
      setTotal(newTotal);
    }
  }, [eventTrigger]);

  const SCROLL_TRIGGER_OFFSET = 1000;

  const fetchDataOnScroll = async (direction) => {
    if (isFetchingRef.current || !allowScrollFetch || dataEnd) {
      return;
    }

    isFetchingRef.current = true;
    setIsFetching(true);
    try {
      const lastWeekNum =
        Object.keys(events)[Object.keys(events).length - 1].split(" ")[1];

      await fetchData2("down", lastWeekNum);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      isFetchingRef.current = false;
      setIsFetching(false);

      setAllowScrollFetch(false);
      setTimeout(() => {
        setAllowScrollFetch(true);
      }, 1000);
    }
  };
  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!initialScrollDone || !allowScrollFetch) return;

        const container = containerRef.current;

        const bottomMarker = bottomMarkerRef.current;

        if (!container || !bottomMarker) return;

        const currentScrollTop = container.scrollTop;
        const currentScrollLeft = container.scrollLeft;

        const isVerticalScroll = currentScrollTop !== lastScrollTopRef.current;
        const shouldCheckBottom = isNearBottom(container, bottomMarker);

        lastScrollTopRef.current = currentScrollTop;
        // console.log(
        //   isVerticalScroll,
        //   currentScrollTop,
        //   lastScrollTopRef,
        //   "Vertical"
        // );

        if (isVerticalScroll) {
          const containerRect = container.getBoundingClientRect();
          const bottomMarkerRect = bottomMarker.getBoundingClientRect();
          const distanceToBottomMarker =
            bottomMarkerRect.bottom - containerRect.bottom;
          if (isVerticalScroll || shouldCheckBottom) {
            // console.log(selectedDealershipOption?.length , selectedOptionRef.current, "selectedDealershipOption");
            // console.log(selectedDealershipOption?.length === 0 , selectedDealershipOption.length ,initialScrollDone, "selectedDealershipOption");
            // if (shouldCheckBottom && !isFetchingRef.current && selectedDealershipOption?.length === 0 && !selectedOptionRef.current) {
            if (shouldCheckBottom && !isFetchingRef.current) {
              fetchDataOnScroll("down");
            }
          }
        }
      }, 300),
    [initialScrollDone, allowScrollFetch, dataEnd, events]
  );

  const isNearBottom = (container, bottomMarker) => {
    const containerRect = container.getBoundingClientRect();
    const bottomMarkerRect = bottomMarker.getBoundingClientRect();
    const distanceToBottomMarker =
      bottomMarkerRect.bottom - containerRect.bottom;
    const isAtBottom =
      Math.abs(
        container.scrollHeight - container.scrollTop - container.clientHeight
      ) < 2;

    return distanceToBottomMarker < SCROLL_TRIGGER_OFFSET || isAtBottom;
  };
  const handleScroll = useCallback(debouncedHandleScroll, [
    debouncedHandleScroll,
  ]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (dataLoaded) {
      const scrollTimer = setTimeout(() => {
        // if (selectedDealershipOption?.length === 0 && !selectedOptionRef.current && eventsFilterData?.length === 0)
        // if (eventsFilterData === null || eventsFilterData?.length === 0) {
          autoScrollMid();
        // }
        setDataLoaded(false);
      }, 100);

      return () => clearTimeout(scrollTimer);
    }
  }, [dataLoaded]);

  const autoScrollMid = () => {
    if (typeof window === "undefined") return;

    const container = containerRef.current;
    if (!container) return;
    requestAnimationFrame(() => {
      try {
        const bottomScrollPos = container.scrollHeight - container.clientHeight;

        container.scrollTo({
          top: Math.max(0, bottomScrollPos - 300),
          behavior: "smooth",
        });
        setPrevScrollPos(bottomScrollPos);
        setInitialScrollDone(true);
      } catch (error) {
        console.error("Scroll error:", error);
      }
    });
  };

  const handleDelete = async (id, index, accordionIndex) => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl +
          "/api/user/private-event-destroy/" +
          id +
          "/" +
          filterOption +
          "?page=" +
          page,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(translations.alertMessages.eventDeletion[language]);
        const updatedEvents = { ...events };
        for (let week in updatedEvents) {
          updatedEvents[week] = updatedEvents[week]
            .filter((event) => event.id !== id)
            .map((event) => event);
        }
        const sortedUpdatedEvents =
          Object.keys(updatedEvents) &&
          Object.keys(updatedEvents).length > 0 &&
          Object.keys(updatedEvents)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            )
            .reduce((sortedObj, week) => {
              sortedObj[week] = updatedEvents[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        const newDeleteStates = [...deleteStates];
        newDeleteStates[accordionIndex][index][id] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleCopy = async () => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/copy-parentform?page=" + page + "" + searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            [clientForms ? "child_id" : "parent_id"]: parentId,

            event_id: eventId,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setDisplayComponent("edit-form");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          `/event-portal?page=edit-form&event_id=${eventId}&form_id=${data.event_url}`
        );
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      console.log("here0");

      setEvents(cachedResponse);
      setDeleteStates(
        cachedResponse &&
          Object.keys(cachedResponse).map((key) =>
            cachedResponse[key].map((item) => ({ [item.id]: false }))
          )
      );
      setTotal(cachedTotal);
    } else {
      try {
        const res = await fetch(
          ApiUrl + "/api/user/private-events-index/" + key + "?page=" + pageNo,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.private_events;
          const newTotal = data.private_events.total;

          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          setEvents(newData);
          let totalEventss = 0;

          for (const week in newData) {
            if (newData.hasOwnProperty(week)) {
              totalEventss += newData[week].length;
            }
          }
          if (totalEventss !== totalEvents) {
            setStats(data);
            setTotalEvents(totalEventss);
          }
          setEventsSearch(newData);
          setTotal(newTotal);
          setDeleteStates(
            newData &&
              Object.keys(newData).map((key) =>
                newData[key].map((item) => ({ [item.id]: false }))
              )
          );
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
    }
  };

  const filterEvents = async (filterData) => {
    setIsLoading(true);
    setAllowScrollFetch(false);
    try {
      const res = await fetch(ApiUrl + "/api/user/private-events-index/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterData),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);

        const newData = data.private_events;
        const newTotal = data.private_events.total;
        containerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        const sortedUpdatedEvents =
          Object.keys(newData) &&
          Object.keys(newData).length > 0 &&
          Object.keys(newData)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            )
            .reduce((sortedObj, week) => {
              sortedObj[week] = newData[week];
              return sortedObj;
            }, {});

        setEvents(sortedUpdatedEvents);
        let totalEventss = 0;

        for (const week in sortedUpdatedEvents) {
          if (sortedUpdatedEvents.hasOwnProperty(week)) {
            totalEventss += sortedUpdatedEvents[week].length;
          }
        }
        if (totalEventss !== totalEvents) {
          setStats(data);
          setTotalEvents(totalEventss);
        }
        setTotal(newTotal);

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
          setDeleteStates(
            sortedUpdatedEvents &&
              Object.keys(sortedUpdatedEvents).map((key) =>
                sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
              )
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setAllowScrollFetch(true);
    }
  };

  const getFilteredEvents = async (filterPost, checkedFieldsData) => {
    setAllowScrollFetch(false);
    try {
      const res = await fetch(ApiUrl + "/api/user/private-events-index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterPost),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setSelectedDealership([]);
        localStorage.removeItem("dealershipFilter");
        localStorage.setItem(
          "eventsFilterPostData",
          JSON.stringify(filterPost)
        );
        localStorage.setItem(
          "eventsFilterData",
          JSON.stringify(checkedFieldsData)
        );
        const newData = data.private_events;
        const newTotal = data.private_events.total;
        const sortedUpdatedEvents =
          Object.keys(newData) &&
          Object.keys(newData).length > 0 &&
          Object.keys(newData)
            .sort(
              (a, b) => parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1])
            )
            .reduce((sortedObj, week) => {
              sortedObj[week] = newData[week];
              return sortedObj;
            }, {});
        let totalEventss = 0;

        for (const week in sortedUpdatedEvents) {
          if (sortedUpdatedEvents.hasOwnProperty(week)) {
            totalEventss += sortedUpdatedEvents[week].length;
          }
        }
        if (totalEventss !== totalEvents) {
          setStats(data);
          setTotalEvents(totalEventss);
        }
        setEvents(sortedUpdatedEvents);
        setTotal(newTotal);

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: newData,
              cachedTotal: newTotal,
            },
          });
          setDeleteStates(
            sortedUpdatedEvents &&
              Object.keys(sortedUpdatedEvents).map((key) =>
                sortedUpdatedEvents[key].map((item) => ({ [item.id]: false }))
              )
          );
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
      setAllowScrollFetch(true);
    }
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-brands", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
      }
    };
    const getFiltersData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-fillter", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const filteredFields = allfields.filter(
            (field) => data.fillter[field.name] === 1
          );

          // setCheckedFields(filteredFields);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
      }
    };
    getBrands();
    // getFiltersData();
    if (data?.user_views) {
      setTableViews([...data?.user_views]);
      const currentViewObj = data?.user_views.find(
        (item) => item.current === "1"
      );
      console.log({
        ...currentViewObj,
        columns: currentViewObj?.columns || [],
      });

      setCurrentView({
        ...currentViewObj,
        columns: currentViewObj?.columns || [],
      });
    }
  }, [token, data]);

  const changeDropdowns = async (bodyData) => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/change-status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        const updatedEvents = { ...events };
        for (let week in updatedEvents) {
          updatedEvents[week] = updatedEvents[week].map((event) =>
            event.id === bodyData.id
              ? {
                  ...event,
                  [Object.keys(bodyData)[1]]:
                    bodyData[Object.keys(bodyData)[1]],
                }
              : event
          );
        }

        setEvents(updatedEvents);
        setDeleteStates(
          updatedEvents &&
            Object.keys(updatedEvents).map((key) =>
              updatedEvents[key].map((item) => ({ [item.id]: false }))
            )
        );

        if (key) {
          setSearchCache({
            [pageNo]: {
              cachedResponse: updatedEvents,
              cachedTotal: events.total,
            },
          });
        } else {
          setPageCache({
            [pageNo]: {
              cachedResponse: updatedEvents,
              cachedTotal: events.total,
            },
          });
        }
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
    }
  };
  const checkParent = async (event_Id) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/check-parentform/" + event_Id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        if (data?.parentForm && data?.parentForm.length > 0) {
          const options =
            data?.parentForm &&
            data?.parentForm.length > 0 &&
            data.parentForm.map((item) => {
              return { label: item?.indexed_date, value: item.id };
            });
          setParentForms(options);
        }
        if (data?.clientForm && data?.clientForm.length > 0) {
          const options =
            data?.clientForm &&
            data?.clientForm.length > 0 &&
            data.clientForm.map((item) => {
              return { label: item?.indexed_date, value: item.id };
            });
          setParentForms(options);
          setClientForms(true);
        }

        setEventId(event_Id);

        var createModal = new window.bootstrap.Modal(
          document.getElementById("createForm")
        );
        createModal.show();
      } else {
        setDisplayComponent("create-event");

        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          "/event-portal?page=create-event&parent_id=" +
            event_Id +
            "&event-navigate=true&getFormData=false"
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
    }
  };
  const getDealershipData = async (dealership_Id) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/dealer-ship-detail/" + dealership_Id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        seteditID(dealership_Id);
        setOldData(data.dealer_ship);
        var createModal = new window.bootstrap.Modal(
          document.getElementById("AddDealerModal")
        );
        createModal.show();
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    } finally {
    }
  };

  const moveColumn = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = currentView.columns.findIndex(
      (col) => col.id === active.id
    );
    const newIndex = currentView.columns.findIndex((col) => col.id === over.id);

    const reorderedColumns = arrayMove(currentView.columns, oldIndex, newIndex);
    const updatedData = { ...currentView, columns: reorderedColumns };
    setCurrentView((prevView) => ({ ...prevView, columns: reorderedColumns }));
    if (updatedData.type !== "default event") {
      updateView(updatedData);
    }
  };

  const handleResize = (columnId, newWidth) => {
    columnWidthsRef.current[columnId] = newWidth;
    // const newColumns = columns.map((col) =>
    //   col.id === columnId ? { ...col, width: newWidth } : col
    // );
    // setColumns(newColumns);
    // Immediate visual update
    requestAnimationFrame(() => {
      const columnElement = document.getElementById(columnId);

      if (columnElement) columnElement.style.width = `${newWidth}px`;
    });

    // Mark that a state update is needed
    if (!pendingStateUpdate.current) {
      pendingStateUpdate.current = true;
      debouncedUpdate(setCurrentView);
    }
  };

  // Debounce for smoother updates instead of throttle
  const debouncedUpdate = debounce((setCurrentView) => {
    const updatedData = {
      ...currentView,
      columns: currentView.columns.map((col) =>
        col.id in columnWidthsRef.current
          ? { ...col, width: columnWidthsRef.current[col.id] }
          : col
      ),
    };
    setCurrentView((prevColumns) => ({
      ...prevColumns,
      columns: prevColumns.columns.map((col) =>
        col.id in columnWidthsRef.current
          ? { ...col, width: columnWidthsRef.current[col.id] }
          : col
      ),
    }));
    if (updatedData.type !== "default event") {
      updateView(updatedData);
    }
    pendingStateUpdate.current = false; // Reset flag after update
  }, 16); // Adjust delay as needed

  const updateView = async (updatedData) => {
    try {
      const res = await fetch(ApiUrl + "/api/user/views", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data?.message);
        const viewData2 = {
          ...data.userView,
          columns: JSON.parse(data.userView.columns),
        };
        setTableViews((prev) =>
          prev.map((item) => {
            if (item.id === updatedData.id) {
              return viewData2;
            }
            return item;
          })
        );
      } else {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => debouncedUpdate.cancel();
  }, [data]);

  const exportData = (fileType) => {
    if (!currentView || !currentView.columns) {
      console.error("Current view is not set or missing columns.");
      return;
    }

    // Extract column keys from `currentView.columns`
    const selectedColumns = currentView.columns
      .slice(0, -1)
      .filter((item) => item.renderInView !== false)
      .map((col) => ({
        key: col.id, // Assuming column `id` matches the data field name
        header: col.content || col.id, // Column name in the exported file
      }));

    const selectedData =
      events && Object.keys(events).length > 0
        ? Object.keys(events).flatMap((weekKey) => {
            return events[weekKey].map((client) => {
              let rowData = {};

              // Include "Week" column
              // rowData["Week"] = weekKey;

              // Add only the selected columns
              selectedColumns.forEach(({ key, header }) => {
                if (
                  ["dealership", "package", "animator", "sales_rep"].includes(
                    key
                  )
                ) {
                  rowData[header] = client[key]?.name || "";
                } else
                  rowData[header] = client[key]?.name
                    ? client[key].name
                    : client[key] || ""; // Match data based on column key
              });

              return rowData;
            });
          })
        : [];

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");

    // Determine file type (CSV or XLSX)
    if (fileType === "csv") {
      const csvData = XLSX.utils.sheet_to_csv(worksheet);
      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Export_Events_${format(new Date(), "dd MMMM yyyy")}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      XLSX.writeFile(
        workbook,
        `Export_Events_${format(new Date(), "dd MMMM yyyy")}.xlsx`
      );
    }
  };

  return (
    <div className="Event Index Private">
      {!loading ? (
        <>
          <CreateFormModal
            isDarkMode={isDarkMode}
            handleCopy={handleCopy}
            parentId={parentId}
            setParentId={setParentId}
            eventId={eventId}
            setEventId={setEventId}
            setDisplayComponent={setDisplayComponent}
            parentForms={parentForms}
            setChildId={setChildId}
          />
          <EditEventModal
            dealerships={dealerships}
            eventNavigate={true}
            editID={editID}
            isEdit={true}
            oldData={oldData}
            setOldData={setOldData}
            setIsLoading={setIsLoading}
            brands={brands}
            isDarkMode={isDarkMode}
            repOptions={repOptions}
          />
          <FilterBar
            isDarkMode={isDarkMode}
            allfields={allfields}
            checkedFields={checkedFields}
            setCheckedFields={setCheckedFields}
            textOptions={textOptions}
            textOptions2={textOptions2}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            dealerOptions={dealerOptions}
            repOptions2={repOptions2}
            managers={managers}
            packageOptions={packageOptions}
            animatorOptions={animatorOptions}
            getFilteredEvents={getFilteredEvents}
            weekOptions={weekOptions}
            setFiltersData={setFiltersData}
            filtersData={filtersData}
          />
          <AddViewModal
            isDarkMode={isDarkMode}
            getColumns={
              (data?.user_views &&
                data.user_views?.find(
                  (item) => item.type === "default event"
                )) ||
              {}
            }
            setTableViews={setTableViews}
            setIsLoading={setIsLoading}
            viewData={viewData}
            setViewData={setViewData}
            setCurrentView={setCurrentView}
          />
          <div className="container maincont">
            <div id="app" className="eventsContainer">
              <div className="row align-items-center justify-content-between">
                <div className="col-4 d-flex align-items-center gap-3">
                  <div>
                    <h2
                      id="h1"
                      className={`col-1${isDarkMode ? "darkthead col-1" : ""}`}
                    >
                      {pageTranslations["title"].events[language]}
                    </h2>
                  </div>
                </div>
                <div className="col-8 d-flex align-items-center justify-content-end gap-1">
                  {userData &&
                    (userData.roles[0].permissions.some(
                      (item) => item.name === "Event Report"
                    ) ||
                      userData.roles[0].permissions.some(
                        (item) => item.name === "Contract And Billing"
                      )) && (
                      <div style={{ width: "250px" }}>
                        <Select
                          className="w-100 selectpicker"
                          aria-labelledby="dealership-name-quick"
                          options={[
                            userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Event Report"
                              ) && {
                                label:
                                  translations.topAndsidebar.eventReports[
                                    language
                                  ],
                                value: "view-event-report",
                              },
                            userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Contract And Billing"
                              ) && {
                                label:
                                  translations.topAndsidebar.billingReport[
                                    language
                                  ],
                                value: "view-billing-report",
                              },
                            !window.location.href.includes("page=events") && {
                              label:
                                translations.EventsPortal.title.events[
                                  language
                                ],
                              value: "events",
                            },
                          ].filter(Boolean)}
                          placeholder={
                            translations["dropdown"].select[language]
                          }
                          isSearchable={false}
                          value={{
                            label:
                              translations.EventsPortal.title.events[language],
                            value: "events",
                          }}
                          onChange={(selectedOption) => {
                            setDisplayComponent((prev) => {
                              setPrevDisplay(prev);
                              return `${selectedOption.value}`;
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              `/event-portal?page=${selectedOption.value}`
                            );
                          }}
                          styles={{
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: isDarkMode
                                ? "transparent"
                                : "white",
                              border: state.isFocused
                                ? "1px solid #80bdff"
                                : isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                              boxShadow: state.isFocused
                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                : "none",
                              borderRadius: ".45rem",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0.18rem 0.55rem",
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              padding: "0.375rem 0.75rem",
                              color: isDarkMode ? "#ffffff" : "#868686",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                              margin: "0",
                              zIndex: "9999",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#34DBB4"
                                  : "#34DBB4",
                              },
                            }),
                          }}
                        />
                      </div>
                    )}
                  {userType && internalUsers.includes(userType) && (
                    <div style={{ width: "250px" }}>
                      <Select
                        className="w-100 selectpicker"
                        aria-labelledby="dealership-name-quick"
                        options={filterOptions}
                        placeholder={translations["dropdown"].select[language]}
                        isSearchable={false}
                        value={
                          (filterOptions &&
                            filterOptions.length > 0 &&
                            filterOptions.find(
                              (option) => option.value === filterOption
                            )) || {
                            value: "",
                            label: translations["dropdown"].select[language],
                          }
                        }
                        onChange={(selectedOption) => {
                          setFilterOption(selectedOption.value);
                          setSelectedYear("");
                          const filteredFields = checkedFields.filter(
                            (field) =>
                              field?.value !== "" || field?.value.length > 0
                          );

                          if (filteredFields && filteredFields.length > 0) {
                            const filteredData = filteredFields.reduce(
                              (acc, field) => {
                                acc[
                                  field.type === "dropdown"
                                    ? field.name + "_id"
                                    : field.name
                                ] =
                                  field.type === "text"
                                    ? [field.value, field.filterType]
                                    : [field.value];
                                return acc;
                              },
                              {}
                            );
                            const mergedData = {
                              ...filteredData,
                            };

                            mergedData.type = selectedOption.value;

                            getFilteredEvents(mergedData, checkedFields);
                          } else {
                            const data = { type: selectedOption.value };
                            filterEvents(data);
                          }
                        }}
                        styles={{
                          input: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: isDarkMode
                              ? "transparent"
                              : "white",
                            border: state.isFocused
                              ? "1px solid #80bdff"
                              : isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            boxShadow: state.isFocused
                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                              : "none",
                            borderRadius: ".45rem",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0.18rem 0.55rem",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            padding: "0.375rem 0.75rem",
                            color: isDarkMode ? "#ffffff" : "#868686",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: isDarkMode ? "#1f2023" : "white",
                            margin: "0",
                            zIndex: "9999",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "#333333",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: state.isHovered
                              ? "#80bdff"
                              : isDarkMode
                              ? "#1f2023"
                              : "white",
                            ":hover": {
                              backgroundColor: isDarkMode
                                ? "#34DBB4"
                                : "#34DBB4",
                            },
                          }),
                        }}
                      />
                    </div>
                  )}
                  {(filterOption === "cancel" ||
                    filterOption === "previousYear") && (
                    <div style={{ width: "250px" }}>
                      <Select
                        className="w-100 selectpicker"
                        aria-labelledby="dealership-name-quick"
                        options={
                          filterOption === "previousYear"
                            ? Array.from(
                                { length: 5 },
                                (_, i) => new Date().getFullYear() - 1 - i
                              ).map((item) => {
                                return { label: item, value: item };
                              })
                            : Array.from(
                                { length: 5 },
                                (_, i) => new Date().getFullYear() - i
                              ).map((item) => {
                                return { label: item, value: item };
                              })
                        }
                        placeholder={translations["dropdown"].select[language]}
                        isSearchable={false}
                        value={
                          (filterOption === "previousYear"
                            ? Array.from({ length: 5 }, (_, i) => {
                                const year = new Date().getFullYear() - 1 - i;
                                return { label: year, value: year };
                              })
                            : Array.from({ length: 5 }, (_, i) => {
                                const year = new Date().getFullYear() - i;
                                return { label: year, value: year };
                              })
                          ).find((option) => option.value === selectedYear) || {
                            value: "",
                            label: translations["dropdown"].select[language],
                          }
                        }
                        onChange={(selectedOption) => {
                          setSelectedYear(selectedOption.value);
                          const filteredFields = checkedFields.filter(
                            (field) =>
                              field?.value !== "" || field?.value.length > 0
                          );

                          if (filteredFields && filteredFields.length > 0) {
                            const filteredData = filteredFields.reduce(
                              (acc, field) => {
                                acc[
                                  field.type === "dropdown"
                                    ? field.name + "_id"
                                    : field.name
                                ] =
                                  field.type === "text"
                                    ? [field.value, field.filterType]
                                    : [field.value];
                                return acc;
                              },
                              {}
                            );

                            const mergedData = {
                              ...filteredData,
                            };
                            if (filterOption === "cancel")
                              mergedData.cancelYear = selectedOption.value;
                            else mergedData.previousYear = selectedOption.value;

                            getFilteredEvents(mergedData, checkedFields);
                          } else {
                            const data = {
                              type: filterOption,
                              [filterOption === "cancel"
                                ? "cancelYear"
                                : "previousYear"]: selectedOption.value,
                            };
                            filterEvents(data);
                          }
                        }}
                        styles={{
                          input: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: isDarkMode
                              ? "transparent"
                              : "white",
                            border: state.isFocused
                              ? "1px solid #80bdff"
                              : isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            boxShadow: state.isFocused
                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                              : "none",
                            borderRadius: ".45rem",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0.18rem 0.55rem",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            padding: "0.375rem 0.75rem",
                            color: isDarkMode ? "#ffffff" : "#868686",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: isDarkMode ? "#1f2023" : "white",
                            margin: "0",
                            zIndex: "9999",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "#333333",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: isDarkMode ? "#ffffff" : "black",
                            backgroundColor: state.isHovered
                              ? "#80bdff"
                              : isDarkMode
                              ? "#1f2023"
                              : "white",
                            ":hover": {
                              backgroundColor: isDarkMode
                                ? "#34DBB4"
                                : "#34DBB4",
                            },
                          }),
                        }}
                      />
                    </div>
                  )}
                  {userType &&
                    userType !== "Employee" &&
                    userType !== "Manager" && (
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          id=""
                          type="button"
                          className="btn btn-primary admin-btn"
                          data-bs-target="#filtersidebar"
                          data-bs-toggle="offcanvas"
                          aria-bs-controls="offcanvasmain"
                        >
                          <i className="bi bi-search"></i>{" "}
                        </button>
                      </div>
                    )}
                  {userData &&
                    userData.roles[0].permissions.some(
                      (item) => item.name === "Private Event Store"
                    ) && (
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="d-flex btn btn-primary align-items-center admin-btn gap-1"
                          onClick={() => {
                            setDisplayComponent((prev) => {
                              return "create-privateEvent";
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=create-privateEvent"
                            );
                          }}
                        >
                          <i className="bi bi-calendar2-event"></i>{" "}
                          {
                            translations.topAndsidebar.createPrivateEvent[
                              language
                            ]
                          }
                        </button>
                      </div>
                    )}
                  {userData &&
                    userData.roles[0].permissions.some(
                      (item) => item.name === "Private Event Store"
                    ) && (
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          id="quickAdd"
                          type="submit"
                          className="btn btn-primary admin-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#modalQuickAdd"
                          fdprocessedid="2rud2c"
                        >
                          <i className="bi bi-speedometer"></i>{" "}
                          {translations.topAndsidebar.quickCreation[language]}
                        </button>
                      </div>
                    )}
                  {currentView &&
                    (currentView?.csv === "1" ||
                      currentView?.csv === true ||
                      currentView?.xlsx === "1" ||
                      currentView?.xlsx === true) && (
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <div className="btn-group" role="group">
                          {(currentView?.xlsx === true ||
                            currentView?.xlsx === "1") && (
                            <button
                              type="button"
                              className="d-flex btn btn-primary align-items-center justify-content-center admin-btn edit-button gap-1 mx-auto"
                              style={{
                                width: "100%",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                exportData("xlsx");
                              }}
                            >
                              <i className={"bi bi-filetype-xlsx"}></i>{" "}
                            </button>
                          )}
                          {(currentView?.csv === true ||
                            currentView?.csv === "1") && (
                            <button
                              type="button"
                              className="d-flex btn btn-danger align-items-center justify-content-center edit-button gap-1 mx-auto"
                              style={{
                                width: "100%",
                                maxWidth: "150px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              onClick={() => {
                                exportData("csv");
                              }}
                            >
                              <i className={"bi bi-filetype-csv"}></i>{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-light dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ width: "125px" }}
                    >
                      {/* {translations.EventsPortal.views.default[language]} */}
                      {currentView?.name}
                    </button>
                    <ul
                      className="dropdown-menu"
                      style={{
                        minWidth: "250px",
                        background: isDarkMode ? "#2b3035" : "#FFFFFF",
                      }}
                    >
                      <li style={{ maxHeight: "200px", overflow: "auto" }}>
                        {tableViews &&
                          tableViews.map((view, index) => (
                            <li
                              className="w-100"
                              key={index}
                              style={{
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#343A3F"
                                    : "#34DBB4",
                                },
                              }}
                            >
                              <div
                                className={`dropdown-item ${
                                  isDarkMode
                                    ? "views-hover-dark"
                                    : "views-hover"
                                }  px-2 d-flex align-items-center justify-content-between w-100`}
                                href="#"
                                style={{
                                  ":hover": {
                                    background: isDarkMode
                                      ? "#343A3F"
                                      : "#34DBB4",
                                  },
                                }}
                              >
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const updatedData = tableViews.map((item) =>
                                      item.id === view.id
                                        ? { ...item, current: true }
                                        : { ...item, current: false }
                                    );
                                    setTableViews(updatedData);
                                    setCurrentView({ ...view, current: true });
                                    updateView({ ...view, current: true });
                                  }}
                                >
                                  {view.name}{" "}
                                </p>
                                <div
                                  className="d-flex align-items-center gap-2"
                                  style={{ fontSize: "18px" }}
                                >
                                  <i
                                    className="bi bi-star-fill"
                                    style={{
                                      color:
                                        currentView?.id === view?.id
                                          ? "gold"
                                          : "#adb5bd",
                                    }}
                                  ></i>{" "}
                                  <i
                                    className="bi bi-pencil-fill"
                                    style={{
                                      color:
                                        view?.type === "default event"
                                          ? "#adb5bd"
                                          : "#445775",
                                      cursor:
                                        view?.type === "default event"
                                          ? "default"
                                          : "pointer",
                                    }}
                                    onClick={() => {
                                      if (view.type !== "default event") {
                                        setViewData({ ...view, mode: "edit" });
                                        button.click();
                                      }
                                    }}
                                  ></i>{" "}
                                  <i
                                    className="bi bi-trash-fill"
                                    style={{
                                      color:
                                        currentView?.id === view?.id ||
                                        view.type === "default event"
                                          ? "#adb5bd"
                                          : "#dc3545",
                                      cursor:
                                        currentView?.id === view?.id ||
                                        view.type === "default event"
                                          ? "default"
                                          : "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        currentView?.id !== view?.id &&
                                        view.type !== "default event"
                                      ) {
                                        setViewData({
                                          ...view,
                                          mode: "delete",
                                        });
                                        button.click();
                                      }
                                    }}
                                  ></i>{" "}
                                </div>
                              </div>
                            </li>
                          ))}
                      </li>

                      <li className="w-100 mt-2 px-2">
                        <button
                          type="button"
                          className="btn btn-light w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#addViewModal"
                          fdprocessedid="2rud2c"
                          id="openViewModal"
                        >
                          <i className="bi bi-plus"></i>{" "}
                          {translations.EventsPortal.views.addNewView[language]}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="headersec mt-3">
                <div className="my-2"></div>
              </div>

              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={moveColumn}
              >
                <SortableContext
                  items={
                    currentView &&
                    currentView?.columns?.slice(1, -2)?.map((col) => col.id)
                  }
                  strategy={horizontalListSortingStrategy}
                >
                  <div
                    className=""
                    ref={containerRef}
                    style={{
                      // display: "flex",
                      // flexDirection: "column",
                      overflowX: "auto",
                      // padding: "20px",
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                      maxHeight: "calc(100vh - 200px)",
                    }}
                  >
                    <div ref={topMarkerRef} style={{ height: "1px" }} />
                    <table
                      className="align-content-center"
                      style={{
                        // width: "max-content",
                        minWidth: "100%",
                        borderCollapse: "collapse",
                        tableLayout: "fixed",
                      }}
                    >
                      <thead
                        style={{
                          position: "sticky",
                          top: "-1px",
                          zIndex: "999",
                        }}
                      >
                        <tr
                          style={{
                            background: isDarkMode ? "#1b1f22" : "#ffffff",
                          }}
                        >
                          {/* First Column */}
                          <th
                            key={currentView?.columns[0].id}
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""} ${
                              currentView?.columns[0]?.className || ""
                            }`}
                            style={{
                              ...currentView?.columns[0].style,
                              zIndex: "1",
                              width: `${currentView?.columns[0].width}px`,
                              background: isDarkMode ? "#1b1f22" : "#ffffff",
                            }}
                            colSpan={currentView?.columns[0].colSpan}
                          >
                            {currentView?.columns[0].content}
                          </th>

                          {/* Draggable Columns (Excluding First & Last Two) */}
                          {currentView?.columns
                            .slice(1, -1)
                            .filter((header) => header.renderInView !== false)
                            .map((header) =>
                              header.showIf !== false ? (
                                <DraggableHeaderCell
                                  key={header.id}
                                  column={header}
                                  isDarkMode={isDarkMode}
                                  onResize={handleResize}
                                />
                              ) : null
                            )}

                          {/* Last Two Columns */}
                          <>
                            {/* <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""} ${
                              currentView.columns[
                                currentView.columns.length - 2
                              ]?.className || ""
                            }`}
                            style={{
                              ...currentView.columns[
                                currentView.columns.length - 2
                              ]?.style,
                              width: `${
                                currentView.columns[
                                  currentView.columns.length - 2
                                ]?.width
                              }px`,
                              background: isDarkMode ? "#1b1f22" : "#ffffff",
                            }}
                            colSpan={
                              currentView.columns[
                                currentView.columns.length - 2
                              ]?.colSpan
                            }
                          >
                            {
                              currentView.columns[
                                currentView.columns.length - 2
                              ]?.content
                            }
                          </th> */}
                          </>
                          {currentView?.columns
                            .filter((header) => header.renderInView !== false)
                            .some((column) => column.id === "actions") && (
                            <th
                              scope="col"
                              className={`${isDarkMode ? "darkthead" : ""} ${
                                currentView?.columns[
                                  currentView?.columns.length - 1
                                ]?.className || ""
                              }`}
                              style={{
                                ...currentView?.columns[
                                  currentView?.columns.length - 1
                                ]?.style,
                                width: `${
                                  currentView?.columns[
                                    currentView?.columns.length - 1
                                  ]?.width
                                }px`,
                                background: isDarkMode ? "#343a3f" : "#ffffff",
                              }}
                              colSpan={
                                currentView?.columns[
                                  currentView?.columns.length - 1
                                ]?.colSpan
                              }
                            >
                              {
                                currentView?.columns[
                                  currentView?.columns.length - 1
                                ]?.content
                              }
                            </th>
                          )}
                        </tr>
                      </thead>

                      <tbody className="event-table" id="tbody">
                        {events && Object.keys(events).length > 0 ? (
                          Object.keys(events).map((key, accordionIndex) => {
                            const eventList = events[key];
                            return (
                              <React.Fragment
                                key={accordionIndex}
                                style={{
                                  marginBottom: "1px",
                                }}
                              >
                                {eventList.map((event, index) => {
                                  const isLastElement =
                                    index === eventList.length - 1;
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        className="align-middle"
                                        style={{
                                          position: "relative",
                                          zIndex: 10 + eventList.length - index,

                                          background: `${
                                            clickedRow?.key === key &&
                                            clickedRow?.index === index
                                              ? "yellow"
                                              : index % 2 === 0
                                              ? isDarkMode
                                                ? "#2B3035"
                                                : "#F8F9FA"
                                              : isDarkMode
                                              ? "#343A3F"
                                              : "#E9ECEF"
                                          }`,
                                        }}
                                        onClick={() => {
                                          setClickedRow((prev) =>
                                            prev?.key === key &&
                                            prev?.index === index
                                              ? null
                                              : { key, index }
                                          );
                                        }}
                                      >
                                        {index === 0 && (
                                          <td
                                            style={{
                                              borderBottom: "1px solid #dee2e6",
                                              backgroundColor:
                                                "rgba(67, 86, 116, 1)",
                                              position: "-webkit-sticky",
                                              position: "sticky",
                                              left: "0px",
                                              width: "57px",
                                              zIndex: "99",
                                            }}
                                            rowSpan={eventList.length}
                                            className="text-center dlrname fw-bold"
                                            id="weekCol"
                                          >
                                            {key.split(" ")[1]}
                                          </td>
                                        )}

                                        {/* Render the columns dynamically */}
                                        {currentView &&
                                          currentView?.columns &&
                                          currentView?.columns
                                            .slice(1, -1)
                                            .filter(
                                              (header) =>
                                                header.renderInView !== false
                                            )
                                            .map((col) => {
                                              if (col.showIf === false)
                                                return null; // Skip columns with showIf: false

                                              const columnKey = col.id;

                                              return (
                                                <td
                                                  // id={columnKey}
                                                  id={
                                                    clickedRow?.key === key &&
                                                    clickedRow?.index === index
                                                      ? "highlight-text"
                                                      : columnKey
                                                  }
                                                  key={col.id}
                                                  style={{
                                                    width: `${col.width}px`,
                                                    minWidth: col.width,
                                                    maxWidth: `${
                                                      col.width + 50
                                                    }px`,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    // borderRight: "1px solid",

                                                    background: `${
                                                      clickedRow?.key === key &&
                                                      clickedRow?.index ===
                                                        index
                                                        ? "yellow"
                                                        : index % 2 === 0
                                                        ? isDarkMode
                                                          ? "#2B3035"
                                                          : "#F8F9FA"
                                                        : isDarkMode
                                                        ? "#343A3F"
                                                        : "#E9ECEF"
                                                    }`,
                                                    borderBottom: isLastElement
                                                      ? "1px solid #dee2e6"
                                                      : "0px solid",
                                                    ...col.style, // Apply column-specific styles
                                                  }}
                                                  className="dlrname"
                                                  onClick={
                                                    columnKey === "dealership"
                                                      ? () => {
                                                          if (
                                                            userData &&
                                                            userData.roles[0].permissions.some(
                                                              (item) =>
                                                                item.name ===
                                                                "Edit Dealership"
                                                            )
                                                          ) {
                                                            getDealershipData(
                                                              event?.dealership
                                                                ?.id
                                                            );
                                                          }
                                                        }
                                                      : undefined
                                                  }
                                                >
                                                  {columnKey === "animator" ? (
                                                    <div
                                                      className="d-flex align-items-center"
                                                      style={{
                                                        minWidth: "186px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          width: "10px",
                                                          height: "10px",
                                                          backgroundColor:
                                                            event?.animator_id?.toString() ===
                                                            "1.1"
                                                              ? "red"
                                                              : event?.animator_id?.toString() ===
                                                                "1.2"
                                                              ? "orange"
                                                              : "",
                                                          borderRadius: "50%",
                                                          marginRight: "8px",
                                                        }}
                                                      ></div>
                                                      <Select
                                                        className="w-100 selectpicker"
                                                        aria-labelledby="dealership-name-quick"
                                                        isClearable
                                                        isDisabled={
                                                          userData &&
                                                          !userData.roles[0].permissions.some(
                                                            (item) =>
                                                              item.name ===
                                                              "Animator DropDown"
                                                          )
                                                        }
                                                        options={
                                                          animatorOptions
                                                        }
                                                        placeholder={
                                                          translations[
                                                            "dropdown"
                                                          ].select[language]
                                                        }
                                                        menuPortalTarget={
                                                          document.body
                                                        }
                                                        isSearchable={true}
                                                        value={
                                                          (animatorOptions &&
                                                            animatorOptions.length >
                                                              0 &&
                                                            animatorOptions.find(
                                                              (option) =>
                                                                option.value?.toString() ===
                                                                event?.animator_id?.toString()
                                                            )) || {
                                                            value: "",
                                                            label:
                                                              translations[
                                                                "dropdown"
                                                              ].select[
                                                                language
                                                              ],
                                                          }
                                                        }
                                                        onChange={(
                                                          selectedOption
                                                        ) => {
                                                          changeDropdowns({
                                                            id: event.id,
                                                            animator_id:
                                                              selectedOption
                                                                ? selectedOption?.value
                                                                : null,
                                                          });
                                                        }}
                                                        maxMenuHeight={"130px"}
                                                        styles={{
                                                          menuPortal: (
                                                            base
                                                          ) => ({
                                                            ...base,
                                                            zIndex: 9999,
                                                          }),
                                                          input: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            color: isDarkMode
                                                              ? "#ffffff"
                                                              : "black",
                                                          }),
                                                          control: (
                                                            provided,
                                                            state
                                                          ) => ({
                                                            ...provided,
                                                            backgroundColor:
                                                              isDarkMode
                                                                ? userData &&
                                                                  userData.roles[0].permissions.some(
                                                                    (item) =>
                                                                      item.name ===
                                                                      "Animator DropDown"
                                                                  )
                                                                  ? "transparent"
                                                                  : "#ced4da66"
                                                                : userData &&
                                                                  userData.roles[0].permissions.some(
                                                                    (item) =>
                                                                      item.name ===
                                                                      "Animator DropDown"
                                                                  )
                                                                ? "white"
                                                                : "#ced4da66",
                                                            border:
                                                              state.isFocused
                                                                ? "1px solid #80bdff"
                                                                : isDarkMode
                                                                ? "1px solid #495057"
                                                                : "1px solid #dee2e6",
                                                            boxShadow:
                                                              state.isFocused
                                                                ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                                                : "none",
                                                            borderRadius:
                                                              ".45rem",
                                                          }),
                                                          valueContainer: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            padding:
                                                              "0.18rem 0.55rem",
                                                            width: "120px",
                                                          }),
                                                          indicatorSeparator: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            display: "none",
                                                          }),
                                                          dropdownIndicator: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            padding:
                                                              "0.375rem 0.75rem",
                                                            color:
                                                              clickedRow?.key ===
                                                                key &&
                                                              clickedRow?.index ===
                                                                index
                                                                ? "black"
                                                                : isDarkMode
                                                                ? "#ffffff"
                                                                : "#868686",
                                                            display:
                                                              userData &&
                                                              userData.roles[0].permissions.some(
                                                                (item) =>
                                                                  item.name ===
                                                                  "Animator DropDown"
                                                              )
                                                                ? "block"
                                                                : "none",
                                                          }),
                                                          menu: (provided) => ({
                                                            ...provided,
                                                            color: isDarkMode
                                                              ? "#ffffff"
                                                              : "black",
                                                            backgroundColor:
                                                              isDarkMode
                                                                ? "#1f2023"
                                                                : "white",
                                                            margin: "0",
                                                          }),
                                                          placeholder: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            color: isDarkMode
                                                              ? "#ffffff"
                                                              : "#333333",
                                                          }),
                                                          singleValue: (
                                                            provided
                                                          ) => ({
                                                            ...provided,
                                                            color:
                                                              clickedRow?.key ===
                                                                key &&
                                                              clickedRow?.index ===
                                                                index
                                                                ? "black"
                                                                : isDarkMode
                                                                ? "#ffffff"
                                                                : "black",
                                                          }),
                                                          option: (
                                                            provided,
                                                            state
                                                          ) => ({
                                                            ...provided,
                                                            color: isDarkMode
                                                              ? "#ffffff"
                                                              : "black",
                                                            backgroundColor:
                                                              state.isHovered
                                                                ? "#80bdff"
                                                                : isDarkMode
                                                                ? "#1f2023"
                                                                : "white",
                                                            ":hover": {
                                                              backgroundColor:
                                                                isDarkMode
                                                                  ? "#34DBB4"
                                                                  : "#34DBB4",
                                                            },
                                                          }),
                                                        }}
                                                      />
                                                    </div>
                                                  ) : columnKey ===
                                                    "dealership" ? (
                                                    event?.dealership?.name
                                                  ) : columnKey ===
                                                    "package" ? (
                                                    event?.package?.name
                                                  ) : columnKey ===
                                                    "sales_rep" ? (
                                                    event?.sales_rep?.name
                                                  ) : event[columnKey]?.name ? (
                                                    event[columnKey]?.name
                                                  ) : (
                                                    event[columnKey] || ""
                                                  )}
                                                </td>
                                              );
                                            })}

                                        {currentView?.columns
                                          .filter(
                                            (header) =>
                                              header.renderInView !== false
                                          )
                                          .some(
                                            (column) => column.id === "actions"
                                          ) && (
                                          <>
                                            {userType !== "Employee" &&
                                              userType !== "Manager" && (
                                                <td
                                                  style={{
                                                    background: `${
                                                      clickedRow?.key === key &&
                                                      clickedRow?.index ===
                                                        index
                                                        ? "yellow"
                                                        : index % 2 === 0
                                                        ? isDarkMode
                                                          ? "#343A3F"
                                                          : "#F8F9FA"
                                                        : isDarkMode
                                                        ? "#343A3F"
                                                        : "#E9ECEF"
                                                    }`,
                                                    borderBottom: isLastElement
                                                      ? "1px solid #dee2e6"
                                                      : "0px solid",
                                                    position: "-webkit-sticky",
                                                    position: "sticky",
                                                    right:
                                                      language === "fr"
                                                        ? userType &&
                                                          !internalUsers.includes(
                                                            userType
                                                          )
                                                          ? "258px"
                                                          : StatusCondition
                                                          ? StatusCondition4
                                                            ? userType ===
                                                                "Animator" ||
                                                              userType ===
                                                                "Agent"
                                                              ? "133px"
                                                              : userType ===
                                                                "Accountant"
                                                              ? "175px"
                                                              : "348px"
                                                            : "122px"
                                                          : StatusCondition2
                                                          ? "394px"
                                                          : StatusCondition3
                                                          ? 0
                                                          : "436.38px"
                                                        : userType &&
                                                          !internalUsers.includes(
                                                            userType
                                                          )
                                                        ? "176px"
                                                        : StatusCondition
                                                        ? StatusCondition4
                                                          ? userType ===
                                                              "Animator" ||
                                                            userType === "Agent"
                                                            ? "93px"
                                                            : userType ===
                                                              "Accountant"
                                                            ? "136px"
                                                            : "307px"
                                                          : "94px"
                                                        : StatusCondition2
                                                        ? userType ===
                                                          "Project Manager"
                                                          ? "351px"
                                                          : "165px"
                                                        : StatusCondition3
                                                        ? 0
                                                        : "396.36px",
                                                    minWidth: "202px",
                                                    paddingInline: "8px",
                                                  }}
                                                >
                                                  <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                      minWidth: "186px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor:
                                                          event?.status ===
                                                            "Active" ||
                                                          event?.status ===
                                                            "Completed"
                                                            ? "#34DBB4"
                                                            : event?.status ===
                                                              "Waiting"
                                                            ? "orange"
                                                            : "red",
                                                        borderRadius: "50%",
                                                        marginRight: "8px",
                                                      }}
                                                    ></div>
                                                    <Select
                                                      className="w-100 selectpicker"
                                                      aria-labelledby="dealership-name-quick"
                                                      options={statusOptions}
                                                      placeholder={
                                                        translations["dropdown"]
                                                          .select[language]
                                                      }
                                                      isDisabled={
                                                        userData &&
                                                        !userData.roles[0].permissions.some(
                                                          (item) =>
                                                            item.name ===
                                                            "Change Event Status"
                                                        )
                                                      }
                                                      isSearchable={false}
                                                      value={
                                                        (statusOptions &&
                                                          statusOptions.length >
                                                            0 &&
                                                          statusOptions.find(
                                                            (option) =>
                                                              option.value ===
                                                              event?.status
                                                          )) || {
                                                          value: "",
                                                          label:
                                                            translations[
                                                              "dropdown"
                                                            ].select[language],
                                                        }
                                                      }
                                                      onChange={(
                                                        selectedOption
                                                      ) => {
                                                        changeDropdowns({
                                                          id: event.id,
                                                          status:
                                                            selectedOption.value,
                                                        });
                                                      }}
                                                      menuPortalTarget={
                                                        document.body
                                                      }
                                                      maxMenuHeight={"100px"}
                                                      styles={{
                                                        menuPortal: (base) => ({
                                                          ...base,
                                                          zIndex: 9999,
                                                        }),
                                                        input: (provided) => ({
                                                          ...provided,
                                                          color: isDarkMode
                                                            ? "#ffffff"
                                                            : "black",
                                                        }),
                                                        control: (
                                                          provided,
                                                          state
                                                        ) => ({
                                                          ...provided,
                                                          width: "100%",
                                                          backgroundColor:
                                                            isDarkMode
                                                              ? userData &&
                                                                userData.roles[0].permissions.some(
                                                                  (item) =>
                                                                    item.name ===
                                                                    "Change Event Status"
                                                                )
                                                                ? "transparent"
                                                                : "#ced4da66"
                                                              : userData &&
                                                                userData.roles[0].permissions.some(
                                                                  (item) =>
                                                                    item.name ===
                                                                    "Change Event Status"
                                                                )
                                                              ? "white"
                                                              : "#ced4da66",
                                                          border:
                                                            state.isFocused
                                                              ? "1px solid #80bdff"
                                                              : isDarkMode
                                                              ? "1px solid #495057"
                                                              : "1px solid #dee2e6",
                                                          boxShadow:
                                                            state.isFocused
                                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                                              : "none",
                                                          borderRadius:
                                                            ".45rem",
                                                        }),
                                                        valueContainer: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          padding:
                                                            "0.18rem 0.55rem",
                                                          width: "100%",
                                                        }),
                                                        indicatorSeparator: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          display: "none",
                                                        }),
                                                        dropdownIndicator: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          padding:
                                                            "0.375rem 0.75rem",
                                                          color:
                                                            clickedRow?.key ===
                                                              key &&
                                                            clickedRow?.index ===
                                                              index
                                                              ? "black"
                                                              : isDarkMode
                                                              ? "#ffffff"
                                                              : "#868686",
                                                          display:
                                                            userData &&
                                                            userData.roles[0].permissions.some(
                                                              (item) =>
                                                                item.name ===
                                                                "Change Event Status"
                                                            )
                                                              ? "block"
                                                              : "none",
                                                        }),
                                                        menu: (provided) => ({
                                                          ...provided,
                                                          color: isDarkMode
                                                            ? "#ffffff"
                                                            : "black",
                                                          backgroundColor:
                                                            isDarkMode
                                                              ? "#1f2023"
                                                              : "white",
                                                          margin: "0",
                                                          width: "100%",
                                                        }),
                                                        placeholder: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          color: isDarkMode
                                                            ? "#ffffff"
                                                            : "#333333",
                                                        }),
                                                        singleValue: (
                                                          provided
                                                        ) => ({
                                                          ...provided,
                                                          color:
                                                            clickedRow?.key ===
                                                              key &&
                                                            clickedRow?.index ===
                                                              index
                                                              ? "black"
                                                              : isDarkMode
                                                              ? "#ffffff"
                                                              : "black",
                                                        }),
                                                        option: (
                                                          provided,
                                                          state
                                                        ) => ({
                                                          ...provided,
                                                          color: isDarkMode
                                                            ? "#ffffff"
                                                            : "black",
                                                          backgroundColor:
                                                            state.isHovered
                                                              ? "#80bdff"
                                                              : isDarkMode
                                                              ? "#1f2023"
                                                              : "white",
                                                          ":hover": {
                                                            backgroundColor:
                                                              isDarkMode
                                                                ? "#34DBB4"
                                                                : "#34DBB4",
                                                          },
                                                        }),
                                                      }}
                                                    />
                                                  </div>
                                                </td>
                                              )}

                                            {
                                              <>
                                                {userType &&
                                                  internalUsers.includes(
                                                    userType
                                                  ) &&
                                                  userType !== "Animator" &&
                                                  userType !== "Agent" &&
                                                  userType !== "Accountant" && (
                                                    <td
                                                      style={{
                                                        background: `${
                                                          clickedRow?.key ===
                                                            key &&
                                                          clickedRow?.index ===
                                                            index
                                                            ? "yellow"
                                                            : index % 2 === 0
                                                            ? isDarkMode
                                                              ? "#343A3F"
                                                              : "#F8F9FA"
                                                            : isDarkMode
                                                            ? "#343A3F"
                                                            : "#E9ECEF"
                                                        }`,
                                                        borderBottom:
                                                          isLastElement
                                                            ? "1px solid #dee2e6"
                                                            : "0px solid",
                                                        paddingInline: "1px",
                                                        position:
                                                          "-webkit-sticky",
                                                        position: "sticky",
                                                        right:
                                                          language === "fr"
                                                            ? userType &&
                                                              !internalUsers.includes(
                                                                userType
                                                              )
                                                              ? "134px"
                                                              : userType ===
                                                                  "Animator" ||
                                                                userType ===
                                                                  "Agent"
                                                              ? 0
                                                              : !hasPrivateEventDestroy &&
                                                                !userData.roles[0].permissions.some(
                                                                  (item) =>
                                                                    item.name ===
                                                                    "Private Event Index"
                                                                )
                                                              ? 0
                                                              : userType ===
                                                                "Accountant"
                                                              ? "106px"
                                                              : userType ===
                                                                  "Admin" ||
                                                                userType ===
                                                                  "Super Admin"
                                                              ? "221.18px"
                                                              : "179px"
                                                            : !hasPrivateEventDestroy &&
                                                              !userData.roles[0].permissions.some(
                                                                (item) =>
                                                                  item.name ===
                                                                  "Private Event Index"
                                                              )
                                                            ? 0
                                                            : userType &&
                                                              !internalUsers.includes(
                                                                userType
                                                              )
                                                            ? 0
                                                            : !hasPrivateEventDestroy
                                                            ? userType ===
                                                                "Animator" ||
                                                              userType ===
                                                                "Agent"
                                                              ? 0
                                                              : "136px"
                                                            : !userData.roles[0].permissions.some(
                                                                (item) =>
                                                                  item.name ===
                                                                  "Private Event Index"
                                                              )
                                                            ? "92px"
                                                            : "180.56px",
                                                      }}
                                                      className="my-2"
                                                    >
                                                      <div
                                                        className="btn-group"
                                                        role="group"
                                                        aria-label="Basic example"
                                                      >
                                                        {}
                                                        {userType &&
                                                          internalUsers.includes(
                                                            userType
                                                          ) &&
                                                          userType !==
                                                            "Animator" &&
                                                          userType !==
                                                            "Agent" &&
                                                          userType !==
                                                            "Accountant" && (
                                                            <div
                                                              className="btn-group"
                                                              role="group"
                                                            >
                                                              <button
                                                                type="button"
                                                                className="d-flex btn btn-light align-items-center justify-content-center edit-button gap-1 mx-auto"
                                                                style={{
                                                                  width: "100%",
                                                                  maxWidth:
                                                                    "150px",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                  textOverflow:
                                                                    "ellipsis",
                                                                  overflow:
                                                                    "hidden",
                                                                  minWidth:
                                                                    "130px",
                                                                  background:
                                                                    "transparent",
                                                                  color:
                                                                    clickedRow?.key ===
                                                                      key &&
                                                                    clickedRow?.index ===
                                                                      index
                                                                      ? "black"
                                                                      : isDarkMode
                                                                      ? "#ffffff"
                                                                      : "black",
                                                                }}
                                                                onClick={() => {
                                                                  if (
                                                                    event.copy ===
                                                                    "0"
                                                                  )
                                                                    checkParent(
                                                                      event.id
                                                                    );
                                                                  else {
                                                                    setDisplayComponent(
                                                                      "create-event"
                                                                    );

                                                                    window.history.pushState(
                                                                      {
                                                                        id: "gallery",
                                                                        randomData:
                                                                          window.Math.random(),
                                                                      },
                                                                      "title",
                                                                      "/event-portal?page=create-event&parent_id=" +
                                                                        event.id +
                                                                        "&event-navigate=true&getFormData=false"
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                {
                                                                  translations
                                                                    .commonWords
                                                                    .form[
                                                                    language
                                                                  ]
                                                                }
                                                              </button>
                                                              {userType !==
                                                                "Representative" && (
                                                                <button
                                                                  type="button"
                                                                  className="d-flex btn btn-light align-items-center justify-content-center edit-button gap-1 mx-auto"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    maxWidth:
                                                                      "150px",
                                                                    whiteSpace:
                                                                      "nowrap",
                                                                    textOverflow:
                                                                      "ellipsis",
                                                                    overflow:
                                                                      "hidden",
                                                                  }}
                                                                  onClick={() => {
                                                                    if (
                                                                      event.copy ===
                                                                      "0"
                                                                    )
                                                                      checkParent(
                                                                        event.id
                                                                      );
                                                                    else {
                                                                      setDisplayComponent(
                                                                        "create-event"
                                                                      );

                                                                      window.history.pushState(
                                                                        {
                                                                          id: "gallery",
                                                                          randomData:
                                                                            window.Math.random(),
                                                                        },
                                                                        "title",
                                                                        "/event-portal?page=create-event&parent_id=" +
                                                                          event.id +
                                                                          "&event-navigate=true&getFormData=false"
                                                                      );
                                                                    }
                                                                  }}
                                                                >
                                                                  <i
                                                                    className={
                                                                      "bi bi-plus-circle"
                                                                    }
                                                                  ></i>{" "}
                                                                </button>
                                                              )}
                                                            </div>
                                                          )}
                                                        {(hasCreateParentForm ||
                                                          userType ===
                                                            "Representative") && (
                                                          <button
                                                            type="button"
                                                            className="d-flex btn align-items-center justify-content-center admin-btn edit-button gap-1 mx-auto"
                                                            style={{
                                                              width: "100%",
                                                              maxWidth: "150px",
                                                              whiteSpace:
                                                                "nowrap",
                                                              textOverflow:
                                                                "ellipsis",
                                                              overflow:
                                                                "hidden",

                                                              color: "#ffffff",
                                                            }}
                                                            disabled={
                                                              event &&
                                                              (userType &&
                                                              internalUsers.includes(
                                                                userType
                                                              )
                                                                ? event?.parents_form ===
                                                                  null
                                                                : event
                                                                    ?.parents_form
                                                                    ?.clients_form ===
                                                                  null)
                                                            }
                                                            onClick={() => {
                                                              if (
                                                                userType &&
                                                                internalUsers.includes(
                                                                  userType
                                                                )
                                                              ) {
                                                                setDisplayComponent(
                                                                  "edit-form"
                                                                );
                                                                window.history.pushState(
                                                                  {
                                                                    id: "gallery",
                                                                    randomData:
                                                                      window.Math.random(),
                                                                  },
                                                                  "title",
                                                                  "/event-portal?page=edit-form&event_id=" +
                                                                    event.id +
                                                                    "&form_id=" +
                                                                    event
                                                                      ?.parents_form
                                                                      ?.id
                                                                );
                                                              } else {
                                                                navigate(
                                                                  "/client-submission?id=" +
                                                                    event
                                                                      ?.parents_form
                                                                      ?.clients_form
                                                                      ?.id
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <i
                                                              className={`bi bi-file-earmark-text`}
                                                            ></i>{" "}
                                                            {}
                                                          </button>
                                                        )}
                                                        {}
                                                      </div>
                                                    </td>
                                                  )}
                                                {userType &&
                                                  externalUsers.includes(
                                                    userType
                                                  ) && (
                                                    <td
                                                      style={{
                                                        background: `${
                                                          clickedRow?.key ===
                                                            key &&
                                                          clickedRow?.index ===
                                                            index
                                                            ? "yellow"
                                                            : index % 2 === 0
                                                            ? isDarkMode
                                                              ? "#343A3F"
                                                              : "#F8F9FA"
                                                            : isDarkMode
                                                            ? "#343A3F"
                                                            : "#E9ECEF"
                                                        }`,
                                                        borderBottom:
                                                          isLastElement
                                                            ? "1px solid #dee2e6"
                                                            : "0px solid",
                                                        paddingInline: "1px",
                                                        position:
                                                          "-webkit-sticky",
                                                        position: "sticky",
                                                        right:
                                                          language === "fr"
                                                            ? "133px"
                                                            : "93px",
                                                      }}
                                                    >
                                                      <button
                                                        type="button"
                                                        className="d-flex btn btn-light align-items-center justify-content-center gap-1 mx-auto"
                                                        style={{
                                                          width: "100%",
                                                          maxWidth: "150px",
                                                          whiteSpace: "nowrap",
                                                          textOverflow:
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                          background: isDarkMode
                                                            ? ""
                                                            : !stats?.latest_submission.some(
                                                                (item) =>
                                                                  item.Event_ID?.toString() ===
                                                                  event.id?.toString()
                                                              )
                                                            ? "#ced4da66"
                                                            : "",
                                                        }}
                                                        disabled={
                                                          !stats?.latest_submission.some(
                                                            (item) =>
                                                              item.Event_ID?.toString() ===
                                                              event.id?.toString()
                                                          )
                                                        }
                                                        onClick={() => {
                                                          const submission =
                                                            stats?.latest_submission.find(
                                                              (item) =>
                                                                item.Event_ID?.toString() ===
                                                                event.id?.toString()
                                                            );

                                                          const clientID =
                                                            submission?.Client_ID;

                                                          setDisplayComponent(
                                                            "client-submission"
                                                          );

                                                          window.history.pushState(
                                                            {
                                                              id: "gallery",
                                                              randomData:
                                                                window.Math.random(),
                                                            },

                                                            "title",
                                                            "/event-portal?page=client-submission&id=" +
                                                              clientID
                                                          );
                                                        }}
                                                      >
                                                        <i
                                                          className={
                                                            "bi bi-file-earmark-text"
                                                          }
                                                        ></i>{" "}
                                                        {
                                                          translations
                                                            .commonWords.form[
                                                            language
                                                          ]
                                                        }
                                                      </button>
                                                    </td>
                                                  )}

                                                <td
                                                  style={{
                                                    background: `${
                                                      clickedRow?.key === key &&
                                                      clickedRow?.index ===
                                                        index
                                                        ? "yellow"
                                                        : index % 2 === 0
                                                        ? isDarkMode
                                                          ? "#343A3F"
                                                          : "#F8F9FA"
                                                        : isDarkMode
                                                        ? "#343A3F"
                                                        : "#E9ECEF"
                                                    }`,
                                                    borderBottom: isLastElement
                                                      ? "1px solid #dee2e6"
                                                      : "0px solid",
                                                    paddingInline: "1px",
                                                    position: "-webkit-sticky",
                                                    position: "sticky",
                                                    right:
                                                      language === "fr"
                                                        ? userType &&
                                                          !internalUsers.includes(
                                                            userType
                                                          )
                                                          ? 0
                                                          : userType ===
                                                              "Animator" ||
                                                            userType === "Agent"
                                                          ? 0
                                                          : !hasPrivateEventDestroy &&
                                                            !userData.roles[0].permissions.some(
                                                              (item) =>
                                                                item.name ===
                                                                "Private Event Index"
                                                            )
                                                          ? 0
                                                          : userType ===
                                                            "Accountant"
                                                          ? "41px"
                                                          : userType ===
                                                              "Admin" ||
                                                            userType ===
                                                              "Super Admin"
                                                          ? "85.2px"
                                                          : "43px"
                                                        : !hasPrivateEventDestroy &&
                                                          !userData.roles[0].permissions.some(
                                                            (item) =>
                                                              item.name ===
                                                              "Private Event Index"
                                                          )
                                                        ? 0
                                                        : userType &&
                                                          !internalUsers.includes(
                                                            userType
                                                          )
                                                        ? 0
                                                        : !hasPrivateEventDestroy
                                                        ? userType ===
                                                            "Animator" ||
                                                          userType === "Agent"
                                                          ? 0
                                                          : "43px"
                                                        : !userData.roles[0].permissions.some(
                                                            (item) =>
                                                              item.name ===
                                                              "Private Event Index"
                                                          )
                                                        ? "92px"
                                                        : "85.2px",
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="d-flex btn btn-light align-items-center justify-content-center gap-1 mx-auto"
                                                    style={{
                                                      width: "100%",
                                                      maxWidth: "150px",
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      overflow: "hidden",
                                                    }}
                                                    onClick={() => {
                                                      setDealershipLabel(
                                                        event?.dealership?.name
                                                      );
                                                      setDisplayComponent(
                                                        "event-view"
                                                      );
                                                      setIsLoading(true);

                                                      window.history.pushState(
                                                        {
                                                          id: "gallery",
                                                          randomData:
                                                            window.Math.random(),
                                                        },
                                                        "title",
                                                        `/event-portal?page=event-view&event_id=${event.id}`
                                                      );
                                                    }}
                                                  >
                                                    <i
                                                      className={
                                                        "bi bi-calendar2-event"
                                                      }
                                                    ></i>{" "}
                                                    {
                                                      pageTranslations["title"]
                                                        .events[language]
                                                    }
                                                  </button>
                                                </td>
                                              </>
                                            }
                                            {}
                                            {userType &&
                                              internalUsers.includes(
                                                userType
                                              ) &&
                                              userType !== "Animator" &&
                                              userType !== "Agent" && (
                                                <td
                                                  style={{
                                                    background: `${
                                                      clickedRow?.key === key &&
                                                      clickedRow?.index ===
                                                        index
                                                        ? "yellow"
                                                        : index % 2 === 0
                                                        ? isDarkMode
                                                          ? "#2B3035"
                                                          : "#F8F9FA"
                                                        : isDarkMode
                                                        ? "#343A3F"
                                                        : "#E9ECEF"
                                                    }`,
                                                    borderBottom: isLastElement
                                                      ? "1px solid #dee2e6"
                                                      : "0px solid",
                                                    paddingInline: "1px",
                                                    position: "-webkit-sticky",
                                                    position: "sticky",
                                                    right: 0,
                                                  }}
                                                >
                                                  <div
                                                    className="btn-group"
                                                    role="group"
                                                  >
                                                    {}
                                                    {userType &&
                                                      internalUsers.includes(
                                                        userType
                                                      ) &&
                                                      userType !== "Animator" &&
                                                      userType !== "Agent" && (
                                                        <button
                                                          type="button"
                                                          className="d-flex btn btn-light align-items-center justify-content-center edit-button gap-1 mx-auto"
                                                          style={{
                                                            width: "100%",
                                                            maxWidth: "150px",
                                                            whiteSpace:
                                                              "nowrap",
                                                            textOverflow:
                                                              "ellipsis",
                                                            overflow: "hidden",
                                                          }}
                                                          onClick={() => {
                                                            setDisplayComponent(
                                                              "edit-event"
                                                            );
                                                            setEditEventID(
                                                              event.id
                                                            );
                                                            window.history.pushState(
                                                              {
                                                                id: "gallery",
                                                                randomData:
                                                                  window.Math.random(),
                                                              },
                                                              "title",
                                                              "/event-portal?page=edit-event&parent_id=" +
                                                                event.id
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className={
                                                              userData &&
                                                              userData.roles[0].permissions.some(
                                                                (item) =>
                                                                  item.name ===
                                                                  "Private Event Show"
                                                              )
                                                                ? "bi bi-pencil"
                                                                : "bi bi-eye"
                                                            }
                                                          ></i>{" "}
                                                          {}
                                                        </button>
                                                      )}
                                                    {hasPrivateEventDestroy && (
                                                      <>
                                                        {deleteStates &&
                                                        deleteStates[
                                                          accordionIndex
                                                        ][index][event.id] ? (
                                                          <div
                                                            className="btn-group"
                                                            role="group"
                                                            style={{
                                                              width: "180px",
                                                            }}
                                                          >
                                                            <button
                                                              type="button"
                                                              className="btn btn-success delete-button"
                                                              onClick={() =>
                                                                handleDelete(
                                                                  event.id,
                                                                  index,
                                                                  accordionIndex
                                                                )
                                                              }
                                                            >
                                                              {
                                                                pageTranslations[
                                                                  "eventsTableButtons"
                                                                ].confirm[
                                                                  language
                                                                ]
                                                              }
                                                            </button>
                                                            <button
                                                              type="button"
                                                              className="btn btn-secondary cancel-button"
                                                              onClick={() =>
                                                                setDeleteStates(
                                                                  (
                                                                    prevStates
                                                                  ) => {
                                                                    const newStates =
                                                                      [
                                                                        ...prevStates,
                                                                      ];
                                                                    newStates[
                                                                      accordionIndex
                                                                    ][index][
                                                                      event.id
                                                                    ] = false;
                                                                    return newStates;
                                                                  }
                                                                )
                                                              }
                                                            >
                                                              {
                                                                pageTranslations[
                                                                  "eventsTableButtons"
                                                                ].cancel[
                                                                  language
                                                                ]
                                                              }
                                                            </button>
                                                          </div>
                                                        ) : (
                                                          <button
                                                            type="button"
                                                            className="d-flex btn btn-danger align-items-center justify-content-center before-delete-button gap-1 mx-auto"
                                                            style={{
                                                              width: "100%",
                                                              maxWidth: "150px",
                                                              whiteSpace:
                                                                "nowrap",
                                                              textOverflow:
                                                                "ellipsis",
                                                              overflow:
                                                                "hidden",
                                                            }}
                                                            onClick={() => {
                                                              setDeleteStates(
                                                                (
                                                                  prevStates
                                                                ) => {
                                                                  const newStates =
                                                                    [
                                                                      ...prevStates,
                                                                    ];
                                                                  newStates[
                                                                    accordionIndex
                                                                  ][index][
                                                                    event.id
                                                                  ] = true;
                                                                  return newStates;
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <i className="bi bi-trash"></i>{" "}
                                                            {}
                                                          </button>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                              )}
                                          </>
                                        )}
                                      </tr>

                                      {isLastElement && (
                                        <tr
                                          key={index}
                                          className="align-middle"
                                          style={{
                                            position: "relative",
                                            height: "30px",
                                            zIndex:
                                              10 + eventList.length - index,
                                          }}
                                        >
                                          <td
                                            id="totalRow"
                                            style={{
                                              borderBottom: "1px solid #dee2e6",
                                              background: `${
                                                isDarkMode
                                                  ? "#435574"
                                                  : "#435574"
                                              }`,
                                              position: "-webkit-sticky",
                                              position: "sticky",
                                              left: "0px",
                                              width: "57px",
                                            }}
                                            className="text-center dlrname fw-bold"
                                          >
                                            Total
                                          </td>
                                          {currentView &&
                                            currentView?.columns
                                              .slice(1, -1)
                                              .filter(
                                                (header) =>
                                                  header.renderInView !== false
                                              )
                                              .map((col) => {
                                                if (col.showIf === false)
                                                  return null; // Skip columns with showIf: false

                                                return (
                                                  <td
                                                    key={col.id}
                                                    style={{
                                                      width: `${col.width}px`,
                                                      minWidth: col.width,
                                                      maxWidth: `${
                                                        col.width + 50
                                                      }px`,
                                                      background: "#435574",
                                                      borderBottom:
                                                        "1px solid #dee2e6",
                                                      fontWeight: "700",
                                                      // Apply column-specific styles
                                                    }}
                                                    id="totalRow"
                                                    className="dlrname"
                                                    // className={col.className || ""}
                                                  >
                                                    {col.id === "nom_vp"
                                                      ? eventList.length
                                                      : col.id === "clients"
                                                      ? eventList.reduce(
                                                          (sum, event) =>
                                                            sum +
                                                            (Number(
                                                              event?.clients
                                                            ) || 0),
                                                          0
                                                        )
                                                      : col.id === "inactive"
                                                      ? eventList.reduce(
                                                          (sum, event) =>
                                                            sum +
                                                            (Number(
                                                              event?.inactive
                                                            ) || 0),
                                                          0
                                                        )
                                                      : col.id === "conquest"
                                                      ? eventList.reduce(
                                                          (sum, event) =>
                                                            sum +
                                                            (Number(
                                                              event?.conquest
                                                            ) || 0),
                                                          0
                                                        )
                                                      : col.id === "amount"
                                                      ? eventList.reduce(
                                                          (sum, event) =>
                                                            sum +
                                                            (Number(
                                                              event?.amount
                                                            ) || 0),
                                                          0
                                                        )
                                                      : "-"}
                                                  </td>
                                                );
                                              })}
                                          {currentView?.columns
                                            .filter(
                                              (header) =>
                                                header.renderInView !== false
                                            )
                                            .some(
                                              (column) =>
                                                column.id === "actions"
                                            ) && (
                                            <>
                                              <td
                                                style={{
                                                  background: `${
                                                    isDarkMode
                                                      ? "#435574"
                                                      : "#435574"
                                                  }`,
                                                  borderBottom: isLastElement
                                                    ? "1px solid #dee2e6"
                                                    : "0px solid",
                                                  position: "-webkit-sticky",
                                                  position: "sticky",
                                                  right:
                                                    language === "fr"
                                                      ? userType &&
                                                        !internalUsers.includes(
                                                          userType
                                                        )
                                                        ? "258px"
                                                        : StatusCondition
                                                        ? StatusCondition4
                                                          ? userType ===
                                                              "Animator" ||
                                                            userType === "Agent"
                                                            ? "133px"
                                                            : userType ===
                                                              "Accountant"
                                                            ? "175px"
                                                            : "348px"
                                                          : "122px"
                                                        : StatusCondition2
                                                        ? "394px"
                                                        : StatusCondition3
                                                        ? 0
                                                        : "433px"
                                                      : userType &&
                                                        !internalUsers.includes(
                                                          userType
                                                        )
                                                      ? "176px"
                                                      : StatusCondition
                                                      ? StatusCondition4
                                                        ? userType ===
                                                            "Animator" ||
                                                          userType === "Agent"
                                                          ? "93px"
                                                          : userType ===
                                                            "Accountant"
                                                          ? "136px"
                                                          : "307px"
                                                        : "94px"
                                                      : StatusCondition2
                                                      ? userType ===
                                                        "Project Manager"
                                                        ? "351px"
                                                        : "165px"
                                                      : StatusCondition3
                                                      ? 0
                                                      : "393px",

                                                  width: "151px",
                                                }}
                                                id="totalRow"
                                              >
                                                -
                                              </td>

                                              {userType &&
                                                internalUsers.includes(
                                                  userType
                                                ) &&
                                                userType !== "Animator" &&
                                                userType !== "Agent" &&
                                                userType !== "Accountant" && (
                                                  <td
                                                    style={{
                                                      background: `${
                                                        isDarkMode
                                                          ? "#435574"
                                                          : "#435574"
                                                      }`,
                                                      borderBottom:
                                                        isLastElement
                                                          ? "1px solid #dee2e6"
                                                          : "0px solid",
                                                      paddingInline: "1px",
                                                      position:
                                                        "-webkit-sticky",
                                                      position: "sticky",
                                                      right:
                                                        language === "fr"
                                                          ? userType &&
                                                            !internalUsers.includes(
                                                              userType
                                                            )
                                                            ? "134px"
                                                            : userType ===
                                                                "Animator" ||
                                                              userType ===
                                                                "Agent"
                                                            ? 0
                                                            : !hasPrivateEventDestroy &&
                                                              !userData.roles[0].permissions.some(
                                                                (item) =>
                                                                  item.name ===
                                                                  "Private Event Index"
                                                              )
                                                            ? 0
                                                            : userType ===
                                                              "Accountant"
                                                            ? "106px"
                                                            : userType ===
                                                                "Admin" ||
                                                              userType ===
                                                                "Super Admin"
                                                            ? "216px"
                                                            : "179px"
                                                          : !hasPrivateEventDestroy &&
                                                            !userData.roles[0].permissions.some(
                                                              (item) =>
                                                                item.name ===
                                                                "Private Event Index"
                                                            )
                                                          ? 0
                                                          : userType &&
                                                            !internalUsers.includes(
                                                              userType
                                                            )
                                                          ? 0
                                                          : !hasPrivateEventDestroy
                                                          ? userType ===
                                                              "Animator" ||
                                                            userType === "Agent"
                                                            ? 0
                                                            : "136px"
                                                          : !userData.roles[0].permissions.some(
                                                              (item) =>
                                                                item.name ===
                                                                "Private Event Index"
                                                            )
                                                          ? "92px"
                                                          : "177px",
                                                    }}
                                                    id="totalRow"
                                                    className="my-2"
                                                  >
                                                    {"-"}
                                                  </td>
                                                )}
                                              {userType &&
                                                externalUsers.includes(
                                                  userType
                                                ) && (
                                                  <td
                                                    style={{
                                                      background: `${
                                                        isDarkMode
                                                          ? "#435574"
                                                          : "#435574"
                                                      }`,
                                                      borderBottom:
                                                        isLastElement
                                                          ? "1px solid #dee2e6"
                                                          : "0px solid",
                                                      paddingInline: "1px",
                                                      position:
                                                        "-webkit-sticky",
                                                      position: "sticky",
                                                      right:
                                                        language === "fr"
                                                          ? "133px"
                                                          : "93px",
                                                    }}
                                                    id="totalRow"
                                                    className="my-2"
                                                  >
                                                    {"-"}
                                                  </td>
                                                )}
                                              {
                                                <td
                                                  style={{
                                                    background: `${
                                                      isDarkMode
                                                        ? "#435574"
                                                        : "#435574"
                                                    }`,
                                                    borderBottom: isLastElement
                                                      ? "1px solid #dee2e6"
                                                      : "0px solid",
                                                    paddingInline: "1px",
                                                    position: "-webkit-sticky",
                                                    position: "sticky",
                                                    right:
                                                      language === "fr"
                                                        ? userType &&
                                                          !internalUsers.includes(
                                                            userType
                                                          )
                                                          ? 0
                                                          : userType ===
                                                              "Animator" ||
                                                            userType === "Agent"
                                                          ? 0
                                                          : !hasPrivateEventDestroy &&
                                                            !userData.roles[0].permissions.some(
                                                              (item) =>
                                                                item.name ===
                                                                "Private Event Index"
                                                            )
                                                          ? 0
                                                          : userType ===
                                                            "Accountant"
                                                          ? "41px"
                                                          : userType ===
                                                              "Admin" ||
                                                            userType ===
                                                              "Super Admin"
                                                          ? "84px"
                                                          : "43px"
                                                        : !hasPrivateEventDestroy &&
                                                          !userData.roles[0].permissions.some(
                                                            (item) =>
                                                              item.name ===
                                                              "Private Event Index"
                                                          )
                                                        ? 0
                                                        : userType &&
                                                          !internalUsers.includes(
                                                            userType
                                                          )
                                                        ? 0
                                                        : !hasPrivateEventDestroy
                                                        ? userType ===
                                                            "Animator" ||
                                                          userType === "Agent"
                                                          ? 0
                                                          : "43px"
                                                        : !userData.roles[0].permissions.some(
                                                            (item) =>
                                                              item.name ===
                                                              "Private Event Index"
                                                          )
                                                        ? "92px"
                                                        : "84px",
                                                  }}
                                                  id="totalRow"
                                                  className="my-2"
                                                >
                                                  {"-"}
                                                </td>
                                              }

                                              {userType &&
                                                internalUsers.includes(
                                                  userType
                                                ) &&
                                                userType !== "Animator" &&
                                                userType !== "Agent" && (
                                                  <td
                                                    style={{
                                                      background: `${
                                                        isDarkMode
                                                          ? "#435574"
                                                          : "#435574"
                                                      }`,
                                                      borderBottom:
                                                        isLastElement
                                                          ? "1px solid #dee2e6"
                                                          : "0px solid",
                                                      paddingInline: "1px",
                                                      position:
                                                        "-webkit-sticky",
                                                      position: "sticky",
                                                      width: "120px",
                                                      right: "0px",
                                                    }}
                                                    className="my-2"
                                                    id="totalRow"
                                                  >
                                                    {"-"}
                                                  </td>
                                                )}
                                            </>
                                          )}
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr
                            className="align-middle"
                            style={{
                              position: "relative",
                            }}
                          >
                            <td
                              style={{
                                background: `${
                                  isDarkMode ? "transparent" : "white"
                                }`,
                                borderStyle: "none",
                              }}
                              colSpan={15}
                              className="text-center py-5"
                            >
                              <h4>
                                {
                                  translations.EventsPortal.noDataMessage[
                                    language
                                  ]
                                }
                              </h4>
                            </td>
                          </tr>
                        )}

                        {/* Loader Row  */}

                        {isFetching && (
                          <tr className="skeleton-loader">
                            <th className="p-2">
                              <div
                                className={
                                  isDarkMode ? "skeleton-dark" : "skeleton"
                                }
                              ></div>
                            </th>
                            {currentView?.columns
                              .slice(1, -1)
                              .filter((header) => header.renderInView !== false)
                              .map((col) => {
                                if (col.showIf === false) return null; // Skip columns with showIf: false

                                return (
                                  <td className="p-2">
                                    <div
                                      className={
                                        isDarkMode
                                          ? "skeleton-dark"
                                          : "skeleton"
                                      }
                                    ></div>
                                  </td>
                                );
                              })}

                            {currentView?.columns
                              .filter((header) => header.renderInView !== false)
                              .some((column) => column.id === "actions") && (
                              <>
                                <td className="p-2">
                                  <div
                                    className={
                                      isDarkMode ? "skeleton-dark" : "skeleton"
                                    }
                                  ></div>
                                </td>
                                <td className="p-2">
                                  <div
                                    className={
                                      isDarkMode ? "skeleton-dark" : "skeleton"
                                    }
                                  ></div>
                                </td>

                                {}
                                {
                                  <td className={` ${"p-2"}`}>
                                    <div
                                      className={
                                        isDarkMode
                                          ? "skeleton-dark"
                                          : "skeleton"
                                      }
                                    ></div>
                                  </td>
                                }

                                {userType &&
                                  internalUsers.includes(userType) && (
                                    <td
                                      className={` ${
                                        hasPrivateEventDestroy ||
                                        userData.roles[0].permissions.some(
                                          (item) =>
                                            item.name === "Private Event Index"
                                        )
                                          ? "p-2"
                                          : "p-0"
                                      }`}
                                    >
                                      <div
                                        className={
                                          isDarkMode
                                            ? "skeleton-dark"
                                            : "skeleton"
                                        }
                                      ></div>
                                    </td>
                                  )}
                              </>
                            )}
                          </tr>
                        )}

                        {/* Total Row  */}

                        {userType &&
                          internalUsers.includes(userType) &&
                          events &&
                          Object.keys(events).length > 0 && (
                            <tr
                              className="align-middle"
                              style={{
                                position: "-webkit-sticky",
                                position: "sticky",
                                bottom: 0,
                                zIndex: 999,
                                backgroundColor: "#435574",
                                background: "#435574",
                                height: "30px",
                              }}
                            >
                              <td
                                id="totalRow"
                                style={{
                                  borderBottom: "1px solid #dee2e6",
                                  background: `${
                                    isDarkMode ? "#435574" : "#435574"
                                  }`,

                                  position: "sticky",
                                  left: "0px",
                                  width: "57px",
                                }}
                                className="text-center dlrname fw-bold"
                              >
                                Total
                              </td>

                              {currentView?.columns
                                .slice(1, -1)
                                .filter(
                                  (header) => header.renderInView !== false
                                )
                                .map((col) => {
                                  if (col.showIf === false) return null; // Skip columns with showIf: false

                                  return (
                                    <td
                                      key={col.id}
                                      style={{
                                        width: `${col.width}px`,
                                        minWidth: col.width,
                                        background: "#435574",
                                        borderBottom: "1px solid #dee2e6",
                                        fontWeight: "700",

                                        // Apply column-specific styles
                                      }}
                                      id="totalRow"
                                      className="dlrname"
                                      // className={col.className || ""}
                                    >
                                      {col.id === "nom_vp"
                                        ? Object.values(events).reduce(
                                            (total, weekEvents) =>
                                              total + weekEvents.length,
                                            0
                                          )
                                        : col.id === "clients"
                                        ? numeral(
                                            Object.values(events)
                                              .flatMap((week) => week)
                                              .reduce(
                                                (total, event) =>
                                                  total +
                                                  Number(event.clients || 0),
                                                0
                                              )
                                          ).format("0,000")
                                        : col.id === "inactive"
                                        ? numeral(
                                            Object.values(events)
                                              .flatMap((week) => week)
                                              .reduce(
                                                (total, event) =>
                                                  total +
                                                  Number(event.inactive || 0),
                                                0
                                              )
                                          ).format("0,000")
                                        : col.id === "conquest"
                                        ? numeral(
                                            Object.values(events)
                                              .flatMap((week) => week)
                                              .reduce(
                                                (total, event) =>
                                                  total +
                                                  Number(event.conquest || 0),
                                                0
                                              )
                                          ).format("0,0")
                                        : col.id === "amount"
                                        ? numeral(
                                            Object.values(events)
                                              .flatMap((week) => week)
                                              .reduce(
                                                (total, event) =>
                                                  total +
                                                  Number(event.amount || 0),
                                                0
                                              )
                                          ).format("0,000.00")
                                        : "-"}
                                    </td>
                                  );
                                })}
                              {currentView?.columns
                                .filter(
                                  (header) => header.renderInView !== false
                                )
                                .some((column) => column.id === "actions") && (
                                <>
                                  <td
                                    style={{
                                      background: `${
                                        isDarkMode ? "#435574" : "#435574"
                                      }`,
                                      borderBottom: "1px solid #dee2e6",
                                      width: "151px",
                                      position: "sticky",
                                      right:
                                        language === "fr" ? "433px" : "395px",
                                    }}
                                    id="totalRow"
                                  >
                                    -
                                  </td>

                                  {
                                    <td
                                      style={{
                                        background: `${
                                          isDarkMode ? "#435574" : "#435574"
                                        }`,
                                        borderBottom: "1px solid #dee2e6",
                                        paddingInline: "1px",
                                        position: "sticky",

                                        right:
                                          language === "fr"
                                            ? "221.81px"
                                            : "180.56px",
                                      }}
                                      id="totalRow"
                                      className=""
                                    >
                                      {"-"}
                                    </td>
                                  }
                                  {
                                    <td
                                      style={{
                                        background: `${
                                          isDarkMode ? "#435574" : "#435574"
                                        }`,
                                        borderBottom: "1px solid #dee2e6",
                                        paddingInline: "1px",
                                        position: "sticky",

                                        right:
                                          language === "fr"
                                            ? userType &&
                                              !internalUsers.includes(userType)
                                              ? 0
                                              : userType === "Animator" ||
                                                userType === "Agent"
                                              ? 0
                                              : !hasPrivateEventDestroy &&
                                                !userData.roles[0].permissions.some(
                                                  (item) =>
                                                    item.name ===
                                                    "Private Event Index"
                                                )
                                              ? 0
                                              : userType === "Accountant"
                                              ? "41px"
                                              : userType === "Admin" ||
                                                userType === "Super Admin"
                                              ? "85.7px"
                                              : "43px"
                                            : !hasPrivateEventDestroy &&
                                              !userData.roles[0].permissions.some(
                                                (item) =>
                                                  item.name ===
                                                  "Private Event Index"
                                              )
                                            ? 0
                                            : userType &&
                                              !internalUsers.includes(userType)
                                            ? 0
                                            : !hasPrivateEventDestroy
                                            ? userType === "Animator" ||
                                              userType === "Agent"
                                              ? 0
                                              : "43px"
                                            : !userData.roles[0].permissions.some(
                                                (item) =>
                                                  item.name ===
                                                  "Private Event Index"
                                              )
                                            ? "92px"
                                            : "85.2px",
                                      }}
                                      id="totalRow"
                                      className=""
                                    >
                                      {"-"}
                                    </td>
                                  }

                                  <td
                                    style={{
                                      background: `${
                                        isDarkMode ? "#435574" : "#435574"
                                      }`,
                                      borderBottom: "1px solid #dee2e6",
                                      position: "sticky",
                                      right: "0",
                                      paddingInline: "1px",
                                    }}
                                    className=""
                                    id="totalRow"
                                  >
                                    {"-"}
                                  </td>
                                </>
                              )}
                            </tr>
                          )}
                      </tbody>
                    </table>
                    <div ref={bottomMarkerRef} style={{ height: "1px" }} />
                  </div>
                </SortableContext>
              </DndContext>
            </div>
          </div>

          <AddWeekModal
            handleCopy={handleCopy}
            eventId={eventId}
            week={week}
            setWeek={setWeek}
            setEventId={setEventId}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EventsNew;
