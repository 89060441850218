import { toast } from "react-toastify";
import logo from "./assets/Nexus_logo@3x.png";
import ChangePasswordModal from "./ChangePasswordModal";
import { ApiUrl } from "./ApiUrl";
import { Link, useNavigate } from "react-router-dom";
import DarkModeToggle from "react-dark-mode-toggle";
import LanguageToggle from "./languageToggle";
import translations from "./translation.json";
import { useLanguage } from "./languageContext";
import { useState } from "react";
import Loader from "./loader";
import logoDark from "./assets/Nexus_logo@3x_Dark.png";

const Navbar = ({ setToken, token, isDarkMode, setIsDarkMode }) => {
  const { language } = useLanguage();

  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const handleLogout = async () => {
    if (localStorage.getItem("token")) {
      try {
        setIsLoading(true);
        const res = await fetch(ApiUrl + "/api/user/logout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          localStorage.removeItem("token");
          setToken(null);
          localStorage.removeItem("userType");
          localStorage.removeItem("userData");
          localStorage.removeItem("dealershipFilter");
          localStorage.removeItem("eventsFilterData");
          setIsDarkMode(false);
          toast.success(data.message);
          navigate("/");
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsDarkMode(false);
        localStorage.removeItem("userData");
        localStorage.removeItem("dealershipFilter");
        localStorage.removeItem("eventsFilterData");
        toast.error(translations.alertMessages.wentWrong[language]);
      }
    } else {
      navigate("/", { replace: true });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="client-navbar mb-4"
          style={{ backgroundColor: isDarkMode ? "#2b3035" : "" }}
        >
          <div
            style={{
              minWidth: language === "en" ? "310px" : "350px",
              width: language === "en" ? "310px" : "350px",
            }}
          >
            {" "}
          </div>
          <div
            style={{ minWidth: "82px", width: "82px", cursor: "pointer" }}
            onClick={() => navigate("/client-dashboard")}
          >
            <img className="p-1" src={isDarkMode ? logoDark : logo} alt="" />
          </div>
          <div
            style={{
              minWidth: language === "en" ? "310px" : "350px",
              width: language === "en" ? "310px" : "350px",
              paddingLeft: "",
            }}
            className="d-flex align-items-center"
          >
            <DarkModeToggle
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={70}
              className="mx-2"
            />
            {/* <div className="me-2">
          <LanguageToggle />
        </div> */}
            <Link to={"/user-profile"} style={{ textDecoration: "none" }}>
              <div>
                <button className="d-flex btn btn-primary align-items-center admin-btn">
                  <i className="bi bi-person-circle mx-2"></i>
                  {translations.topAndsidebar.profile[language]}
                </button>
              </div>
            </Link>
            {/* <button
              type="button"
              class="btn btn-light mx-2"
              data-bs-toggle="modal"
              data-bs-target="#changePasswordModal"
              fdprocessedid="3bt1kb"
            >
              {translations.topAndsidebar.changePassword[language]}
            </button> */}

            <button
              type="button"
              class="btn btn-light ms-2"
              onClick={handleLogout}
            >
              <i class="bi bi-box-arrow-left"></i>{" "}
              {translations.topAndsidebar.logout[language]}
            </button>
          </div>
          <ChangePasswordModal setIsLoading={setIsLoading} />
        </div>
      )}
    </>
  );
};

export default Navbar;
