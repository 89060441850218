export const clearLocalStorage = () => {
  const keysToRemove = [
    "token",
    "dealershipFilter",
    "eventsFilterData",
    "eventsFilterPostData",
    "clientsFilterData",
    "clientsFilterPostData",
    "userType",
    "userData",
    "appointmentsFilterData",
    "appointmentsFilterPostData",
    "calendarSelectedDate",
  ];

  keysToRemove.forEach((key) => localStorage.removeItem(key));
};
