 export const getWeekOfYear = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      console.error("Invalid date");
      return null;
    }
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const dayOfYear =
      Math.floor((date - startOfYear) / (1000 * 60 * 60 * 24)) + 1;

    // Calculate the ISO week number
    // ISO week starts on Monday, and the first week of the year contains January 4th
    const weekNumber = Math.ceil((dayOfYear + startOfYear.getDay()) / 7);
    return weekNumber;
  };