import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import PaginatedItems from "./Pagination";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import AddWeekModal from "./addWeekModal";
import Loader from "./loader";

const Forms = ({
  setDisplayComponent,
  eventTrigger,
  setEditEventID,
  setIsDarkMode,
  isDarkMode,
}) => {
  const token = localStorage.getItem("token");
  const [events, setEvents] = useState();
  const [eventsSearch, setEventsSearch] = useState();

  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  const [key, setKey] = useState("");
  const [eventId, setEventId] = useState("");
  const [week, setWeek] = useState("");
  const [loading, setLoading] = useState("");

  // Create state to track deleteClicked for each item
  const [deleteStates, setDeleteStates] = useState();
  const { language } = useLanguage();
  const pageTranslations = translations["EventsPortal"];

  //   number: 405,
  //   Dealer: "Val D'Or Chrysler",
  //   Representative: "Vincent Mercier",
  //   Week: 27,
  //   drive: "https://drive.google.com/drive/folders/1roc6fyoGWS34MBbzqao0Rqx78N80vAUa",
  // }, {
  //   number: 407,
  //   Dealer: "Granby Toyota",
  //   Representative: "Représentant",
  //   Week: 27,
  //   drive: "https://drive.google.com/drive/folders/1roc6fyoGWS34MBbzqao0Rqx78N80vAUa",
  // }

  function formatDate(date) {
    const datesArray = date.split(" / ");
    const lastDate = datesArray[datesArray.length - 1];

    return lastDate;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setEvents(cachedResponse);
        setEventsSearch(cachedResponse);
        setTotal(cachedTotal);
      } else {
        try {
          setLoading(true);
          const res = await fetch(
            ApiUrl + "/api/user/get-event?page=" + pageNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log("Get Events res: ", res);
          const data = await res.json();
          // console.log("Get Events data: ", data)
          // console.log(result)
          if (data.status === "success") {
            const newData = data.event_list.data;
            const newTotal = data.event_list.total;

            // Update the cache with new data
            // Set state with new data
            setEvents(newData);
            setEventsSearch(newData);
            setTotal(newTotal);
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));

            setDeleteStates(newData.map(() => false));
          } else {
            setLoading(false);
            toast.error(data.message);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toast.error(translations.alertMessages.wentWrong[language]);
        } finally {
        }
      }
    };
    if (!activeSearch && token) {
      fetchData();
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    setIsDarkMode(userData.theme_mode !== "light" ? true : false);
  }, [pageNo, pageCache, events, eventTrigger]);

  useEffect(() => {
    if (eventTrigger) {
      const newData = eventTrigger.data;
      const newTotal = eventTrigger.total;

      // Update the cache with new data
      setPageCache((prevCache) => ({
        ...prevCache,
        [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
      }));
      // Set state with new data
      setEvents(newData);
      setEventsSearch(newData);
      setTotal(newTotal);
    }
  }, [eventTrigger]);

  const handleDelete = async (id, index) => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl +
          "/api/user/delete-parentform?page=" +
          page +
          "" +
          searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(translations.alertMessages.eventDeletion[language]);
        const newData = data.event_list.data;
        const newTotal = data.event_list.total;

        setEvents(newData);
        setTotal(newTotal);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        if (key) {
          setSearchCache({
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          });
        }

        if (activeSearch) {
          setPageNo(
            events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
          );
        }

        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleCopy = async (event_id, week) => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl +
          "/api/user/copy-parentform?page=" +
          page +
          "" +
          searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parent_id: event_id,
            week: week,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(translations.alertMessages.eventCopied[language]);
        setDisplayComponent("edit-form");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          "/event-portal?page=edit-form&parent_id=" + data.event_url
        );
        const newData = data.event_list.data;
        const newTotal = data.event_list.total;

        setEvents(newData);
        setTotal(newTotal);
        if (key) {
          setSearchCache({
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          });
        } else {
          setPageCache({
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          });
        }

        if (activeSearch) {
          setPageNo(
            events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
          );
        }
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      setEvents(cachedResponse);
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/search-events?page=" + pageNo,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              search_parameter: key,
            }),
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.event_list.data;
          const newTotal = data.event_list.total;
          // Update the cache with new data
          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setEvents(newData);
          setEventsSearch(newData);
          setTotal(newTotal);
          // if (pageNo === 1) toast.success("Search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(translations.alertMessages.wentWrong[language]);
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[1];
    setPageNo(1);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setEvents(cachedResponse);
    setKey("");
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  return (
    <div className="Events">
      {!loading ? (
        events && (
          <>
            <div className="container maincont">
              <div id="app" className="appcountainer2">
                <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                  {translations.topAndsidebar.forms[language]}
                </h2>
                <div className="headersec">
                  <div className="mb-2 d-flex">
                    <input
                      style={{
                        borderColor: "#dee2e6",
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                      }}
                      type="text"
                      autocomplete="off"
                      className={`form-control ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                      id="searchInput"
                      placeholder={
                        pageTranslations["searchbar"].search[language]
                      }
                      value={key}
                      onChange={(e) => {
                        setKey(e.target.value);
                        // const filteredData = events.filter((event) =>
                        //   event.dealershipName
                        //     .toLowerCase()
                        //     .startsWith(e.target.value.toLowerCase())
                        // );
                        // setEvents(filteredData);
                      }}
                    />
                    {activeSearch && (
                      <button
                        className="bi bi-x-lg btn searchBtn"
                        style={{ padding: "6px 8px" }}
                        onClick={handleClearFilters}
                      ></button>
                    )}
                    <button
                      className="bi bi-search btn searchBtn"
                      style={{ padding: "6px 8px" }}
                      onClick={() => {
                        setPageCache((prevCache) => ({
                          ...prevCache,
                          pageNo: pageNo,
                        }));
                        setPageNo(1);
                        setSearchCache({});
                        if (key) {
                          handleSearch(1, true);
                        }
                      }}
                    />
                  </div>
                </div>
                {events && events.length > 0 ? (
                  <div
                    className="bodysecsearch"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <table className="table table-hover text-center align-middle">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            {
                              pageTranslations["eventsTableHeader"].dealer[
                                language
                              ]
                            }
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            {
                              pageTranslations["eventsTableHeader"]
                                .representative[language]
                            }
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            {
                              pageTranslations["eventsTableHeader"].week[
                                language
                              ]
                            }
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            {translations.commonWords.firstDay[language]}
                          </th>
                          <th
                            scope="col"
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            {translations.commonWords.lastDay[language]}
                          </th>
                          {/* <th scope="col"></th> */}
                          <th
                            scope="col"
                            colSpan={4}
                            className={`${isDarkMode ? "darkthead" : ""}`}
                          >
                            {
                              pageTranslations["eventsTableHeader"].actions[
                                language
                              ]
                            }
                          </th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>

                      <tbody id="searchResults">
                        {events &&
                          events.map((event, index) => {
                            return (
                              <tr
                                className="result-box"
                                style={{ borderColor: "#dee2e6" }}
                              >
                                <th className="custID" scope="row">
                                  {event.id}
                                </th>
                                <td className="dlrname">
                                  {event.dealershipName}
                                </td>
                                <td className="salesrep">
                                  {event.sales_representative}
                                </td>
                                <td className="semainesearch">{event.week}</td>
                                <td className="semainesearch">
                                  {event.first_day === null
                                    ? ""
                                    : event.first_day}
                                </td>
                                <td className="semainesearch">
                                  {event.event_dates === null
                                    ? ""
                                    : formatDate(event.event_dates)}
                                </td>
                                <td>
                                  <a
                                    type="button"
                                    target="_blank"
                                    className="btn btn-light drive-button"
                                    href={event.drive_link}
                                  >
                                    <i className="bi bi-folder"></i>{" "}
                                    {
                                      pageTranslations["eventsTableButtons"]
                                        .drive[language]
                                    }
                                  </a>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-light edit-button"
                                    data-customer-id="405"
                                    onClick={() => {
                                      setDisplayComponent("edit-form");
                                      setEditEventID(event.id);
                                      // setEditEventID(event.id)
                                      window.history.pushState(
                                        {
                                          id: "gallery",
                                          randomData: window.Math.random(),
                                        },
                                        "title",
                                        `/event-portal?page=edit-form&event_id=${event?.event_id}&form_id=${event.id}`
                                      );
                                    }}
                                  >
                                    <i className="bi bi-pencil"></i>{" "}
                                    {
                                      pageTranslations["eventsTableButtons"]
                                        .edit[language]
                                    }
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-light copy-button"
                                    data-customer-id="405"
                                    data-bs-toggle="modal"
                                    data-bs-target="#copyEvent"
                                    onClick={() => setEventId(event.id)}
                                  >
                                    <i className="bi bi-clipboard-plus"></i>{" "}
                                    {
                                      pageTranslations["eventsTableButtons"]
                                        .copy[language]
                                    }
                                  </button>
                                </td>
                                <td style={{ width: "170px" }}>
                                  <div className="btn-group" role="group">
                                    {deleteStates[index] ? (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-success delete-button"
                                          data-customer-id="405"
                                          onClick={() =>
                                            handleDelete(event.id, index)
                                          }
                                        >
                                          {
                                            pageTranslations[
                                              "eventsTableButtons"
                                            ].confirm[language]
                                          }
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-secondary cancel-button"
                                          onClick={() =>
                                            setDeleteStates((prevStates) => {
                                              const newStates = [...prevStates];
                                              newStates[index] = false;
                                              return newStates;
                                            })
                                          }
                                        >
                                          {
                                            pageTranslations[
                                              "eventsTableButtons"
                                            ].cancel[language]
                                          }
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-danger before-delete-button"
                                        dataset-button-state="delete"
                                        onClick={() =>
                                          setDeleteStates((prevStates) => {
                                            const newStates = [...prevStates];
                                            newStates[index] = true;
                                            return newStates;
                                          })
                                        }
                                      >
                                        <i className="bi bi-trash"></i>{" "}
                                        {
                                          pageTranslations["eventsTableButtons"]
                                            .delete[language]
                                        }
                                      </button>
                                    )}
                                  </div>
                                  {/* {!deleteClicked && <div>
                              <button
                                type="button"
                                className="btn btn-danger before-delete-button"
                              >
                                Delete
                              </button>
                            </div>}
                             */}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="">
                    <div id="" className="text-center my-4">
                      <h3>
                        {translations.EventsPortal.noDataMessage[language]}{" "}
                      </h3>
                    </div>
                  </div>
                )}
                {total !== 0 && total > 10 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    handlePageChange={handlePageChange}
                    isDarkMode={isDarkMode}
                  />
                )}
              </div>
            </div>
            <template id="rowTemplate">
              <tr className="result-box">
                <th className="custID" scope="row"></th>
                <td className="dlrname"></td>
                <td className="salesrep"></td>
                <td className="semainesearch"></td>
                <td>
                  <a
                    type="button"
                    target="_blank"
                    className="btn btn-light drive-button"
                  >
                    <i className="bi bi-folder"></i> Drive
                  </a>
                </td>
                <td>
                  <button type="button" className="btn btn-light edit-button">
                    <i className="bi bi-pencil"></i> Modifier
                  </button>
                </td>
                <td>
                  <button type="button" className="btn btn-light copy-button">
                    <i className="bi bi-clipboard-plus"></i> Copier
                  </button>
                </td>
                <td>
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className="btn btn-success delete-button d-none"
                    >
                      Confirmer
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger before-delete-button"
                      dataset-button-state="delete"
                    >
                      <i className="bi bi-trash"></i> Supprimer
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            <div id="copieEventModal" className="modal fade" tabindex="-1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div id="creationsuccess">
                    <div className="modal-header">
                      <h3 style={{ color: "#000000" }}>
                        Semaine de l'événement
                      </h3>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p style={{ color: "#000000" }}>
                        Veuillez entrer le numéro de la semaine.
                      </p>
                      <div className="row">
                        <div className="mb-3">
                          <label
                            style={{ color: "#000000" }}
                            for="client-form-email"
                            data-bs-toggle="tooltip"
                            title=""
                            className="form-label"
                          >
                            Semaine:{" "}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="event-week-copie"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: "71px" }}
                      className="modal-footer"
                      id="modalfooter"
                    >
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="btn btn-primary"
                        id="submit-week-copie"
                      >
                        <span id="spinner-doc-send" className="visually-hidden">
                          Loading...
                        </span>
                        Envoyer
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Fermer
                      </button>
                      <div className="row" style={{ width: "100%" }}>
                        <div
                          className="alert alert-danger d-flex align-items-center invisible"
                          id="clientFormAlert"
                          role="alert"
                        >
                          {/* <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"></use></svg> */}
                          <div id="clientFormAlertText"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddWeekModal
              handleCopy={handleCopy}
              eventId={eventId}
              week={week}
              setWeek={setWeek}
              setEventId={setEventId}
            />
          </>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Forms;
