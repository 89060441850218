import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
// import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "./assets/Nexus_logo@3x.png";
import { ApiUrl } from "./ApiUrl";
import useDealerships from "./useDealerships";
import Select from "react-select";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import logoDark from "./assets/Nexus_logo@3x_Dark.png";

const EditUser = ({ setDisplayComponent, setIsLoading, isDarkMode }) => {
  const { language } = useLanguage();
  const [usertype, setUserType] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoadng] = useState(false);
  const [formValues, setFormValues] = useState();
  const [oldDealerships, setOldDealerships] = useState(null);
  const [selectedDealerships, setselectedDealerships] = useState(null);
  const userType = localStorage.getItem("userType");
  const userData = JSON.parse(localStorage.getItem("userData"));
  // Check if the token exists in local storage
  const token = localStorage.getItem("token");
  const [editUserID, setEditUserID] = useState();
  var currentUrl = window.location.href;
  const dealerships = useDealerships(token, true);
  const [width, setWidth] = useState("35%");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.usersPage.createUser.validationText.name[language]
    ),
    email: Yup.string()
      .email(
        translations.usersPage.createUser.validationText.email.invalid[language]
      )
      .required(
        translations.usersPage.createUser.validationText.email.required[
          language
        ]
      ),
    phone: Yup.string().required(
      translations.usersPage.createUser.validationText.phone[language]
    ),
    userType: Yup.string().required(
      translations.usersPage.createUser.validationText.userType[language]
    ),
    designation:
      usertype === "2"
        ? Yup.string().required(
            translations.usersPage.createUser.validationText.designation[
              language
            ]
          )
        : Yup.string().nullable(),
    dealerships:
      usertype === "2"
        ? Yup.array().min(
            1,
            translations.usersPage.createUser.validationText.dealership[
              language
            ]
          )
        : Yup.array().optional(),
  });

  const options = dealerships.map((dealership) => ({
    value: dealership.id,
    label: dealership.name,
  }));
  const languages = [
    {
      label: translations.commonWords.english[language],
      value: "en",
    },
    {
      label: translations.commonWords.french[language],
      value: "fr",
    },
  ];

  const yearsOption = Array.from(
    { length: 11 },
    (_, i) => new Date().getFullYear() + i
  ).map((item) => {
    return { label: item, value: item };
  });
  useEffect(() => {
    // Get the current URL
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");

    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);

        setEditUserID(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchFormData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/get-user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: editUserID,
          }),
        });
        const data = await res.json();

        // console.log("data get ", data)
        if (data.status === "success") {
          setFormValues(data.user_data);
          setUserType(data.user_data.user_type);
          setOldDealerships(data.user_dealership);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error);
      }
    };
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth > 1920) {
        setWidth("55%");
      }
      if (screenWidth < 768 || screenWidth <= 1000) setWidth("50%");
    };

    fetchFormData();
    handleResize(); // Set initial width
    
    
    window.addEventListener("resize", handleResize);

    window.removeEventListener("resize", handleResize);
  }, [editUserID]);

  // console.log(editUserID);
  // const [user, setUser] = useState('');

  // const handleRegister = () => {}
  // const userType = localStorage.getItem('userType')
  // useEffect(() => {
  //   // If the token exists, redirect to "/event-portal"
  //   if (!token) {
  //     navigate('/');
  //   }
  //   else {
  //     console.log('usertype Create user', userType)
  //     if (userType === 'client') {
  //       navigate('/client-form')
  //     } else if (userType === 'admin') {
  //     } else {
  //       toast.error('Something went wrong!');
  //       localStorage.removeItem('token')
  //       localStorage.removeItem('userType')
  //       navigate('/')
  //     }
  //   }
  //   //  else {
  //   //   if (userType !== 'Admin') {
  //   //     navigate('/client-form')
  //   //   }
  //   // }
  // }, [navigate]);

  const handleSubmit = async (values, { resetForm }) => {
    const oldData = {
      user_id: editUserID,
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      userType: formValues.user_type,
      designation: formValues.designation,
      user_language: formValues.user_language,
      year: formValues?.year,
      jan: formValues?.jan,
      feb: formValues?.feb,
      mar: formValues?.mar,
      april: formValues?.april,
      may: formValues?.may,
      june: formValues?.june,
      july: formValues?.july,
      aug: formValues?.aug,
      sep: formValues?.sep,
      oct: formValues?.oct,
      nov: formValues?.nov,
      dec: formValues?.dec,
      dealerships: formValues.dealerships,
      pre_form: formValues.pre_form
        ? formValues.pre_form === "1"
          ? true
          : false
        : false,
      ca_form: formValues.ca_form
        ? formValues.ca_form === "1"
          ? true
          : false
        : false,
      gd_form: formValues.gd_form
        ? formValues.gd_form === "1"
          ? true
          : false
        : false,
      event_report: formValues.event_report
        ? formValues.event_report === "1"
          ? true
          : false
        : false,
      client_submission: formValues.client_submission
        ? formValues.client_submission === "1"
          ? true
          : false
        : false,
    };

    if (JSON.stringify(oldData) !== JSON.stringify(values)) {
      try {
        const res = await fetch(ApiUrl + "/api/user/edit-user-profile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: values.user_id,
            name: values.name,
            email: values.email,
            phone: values.phone,
            user_language: values.user_language,
            year: values.year,
            jan: values.jan,
            feb: values.feb,
            mar: values.mar,
            april: values.april,
            may: values.may,
            june: values.june,
            july: values.july,
            aug: values.aug,
            sep: values.sep,
            oct: values.oct,
            nov: values.nov,
            dec: values.dec,
            userType: values.userType,
            role: values.designation,
            dealerships: values.dealerships,
            pre_form: values.pre_form,
            ca_form: values.ca_form,
            gd_form: values.gd_form,
            event_report: values.event_report,
            client_submission: values.client_submission,
          }),
        });

        // return res.json()
        const data = await res.json();

        if (data.status === "success") {
          // console.log("Registered successfully");
          toast.success(translations.alertMessages.userUpdatedSuccess[language], {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("?page=manage-users");
        } else {
          // console.log(data.message)
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
    } else {
      toast.error("Nothing to update");
      navigate("?page=manage-users");
    }

    // setLoadng(true);
    // await axios
    //   .post(`${process.env.REACT_APP_BASEURL}auth/register`, values)
    //   .then((res) => {
    //     setLoadng(false);
    //     resetForm();
    //     localStorage.setItem("token", res.data.token);
    //     localStorage.setItem("user", JSON.stringify(res.data.data));
    //     if (res.data.data.role === "admin") {
    //       navigate("/all-products");
    //     } else {
    //       navigate("/");
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadng(false);
    //     toast.error(error.response.data.message);
    //     console.error("Error updating user data:", error);
    //   });
  };
  const internalUsers_Ids = [
    {
      label: translations.commonWords.usersTypes.superAdmin[language],
      value: "1",
    },
    {
      label: translations.commonWords.usersTypes.admin[language],
      value: "2",
    },
    {
      label: translations.commonWords.usersTypes.representative[language],
      value: "3",
    },
    {
      label: translations.commonWords.usersTypes.accountant[language],
      value: "4",
    },
    {
      label: translations.commonWords.usersTypes.projectManager[language],
      value: "5",
    },
    {
      label: translations.commonWords.usersTypes.agent[language],
      value: "6",
    },
    {
      label: translations.commonWords.usersTypes.animator[language],
      value: "7",
    },
  ];

  const externalUsers_Ids = [
    {
      label: translations.commonWords.usersTypes.manager[language],
      value: "8",
    },
    {
      label: translations.commonWords.usersTypes.employee[language],
      value: "9",
    },
  ];

  // const token = localStorage.getItem("token");
  // const token = false;
  // useEffect(() => {
  //   if (token) {
  //     navigate("/");
  //   }
  // }, [token, navigate]);
  const selected =
    options &&
    options.filter((item) => {
      return (
        oldDealerships &&
        oldDealerships.some(
          (dealership) => dealership.dealership_id === item.value
        )
      );
    });

  const months = [
    { month: "jan", label: "January" },
    { month: "feb", label: "February" },
    { month: "mar", label: "March" },
    { month: "april", label: "April" },
    { month: "may", label: "May" },
    { month: "june", label: "June" },
    { month: "july", label: "July" },
    { month: "aug", label: "August" },
    { month: "sep", label: "September" },
    { month: "oct", label: "October" },
    { month: "nov", label: "November" },
    { month: "dec", label: "December" },
  ];

  const renderMonthFields = (monthsToRender) => {
    return monthsToRender.map(({ month, label }) => (
      <div
        key={month}
        className={`form-floating mb-3 ${isDarkMode ? "dark-mode" : ""}`}
        style={{ width: width >= "55%" ? "16.6%" : "25%" }}
      >
        <Field
          type="string"
          name={`${month}`}
          className="form-control"
          style={{
            backgroundColor: isDarkMode ? "transparent" : "",
            color: isDarkMode ? "#ffffff" : "#000000",
            border: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6",
          }}
        />
        <label
          htmlFor={`objectives.${month}`}
          style={{
            backgroundColor: isDarkMode ? "transparent" : "",
            color: isDarkMode ? "#ffffff" : "#000000",
          }}
        >
          {label}
        </label>
      </div>
    ));
  };

  const getObjectives = async (year) => {
    // setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/user-objective", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: editUserID,
          year: year,
        }),
      });
      const data = await res.json();

      // console.log("data get ", data)
      if (data.status === "success") {
        return data;
      } else {
        toast.error(data.message);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      toast.error(error);
    }
  };

  return (
    <div className="container">
      <div
        className="editUsers d-flex justify-content-center mx-auto my-5"
        style={
          {
            // width: width,
          }
        }
      >
        <main className="p-4 w-100 mx-auto ">
          {formValues && (
            <Formik
              initialValues={{
                user_id: editUserID,
                name: editUserID ? formValues?.name : "",
                email: editUserID ? formValues?.email : "",
                phone: editUserID ? formValues?.phone : "",
                userType: editUserID ? formValues?.user_type : "",
                user_language: editUserID ? formValues?.user_language : "",
                year: editUserID
                  ? formValues?.objective?.year || yearsOption[0].value
                  : "",
                jan: editUserID ? formValues?.objective?.jan : "",
                feb: editUserID ? formValues?.objective?.feb : "",
                mar: editUserID ? formValues?.objective?.mar : "",
                april: editUserID ? formValues?.objective?.april : "",
                may: editUserID ? formValues?.objective?.may : "",
                june: editUserID ? formValues?.objective?.june : "",
                july: editUserID ? formValues?.objective?.july : "",
                aug: editUserID ? formValues?.objective?.aug : "",
                sep: editUserID ? formValues?.objective?.sep : "",
                oct: editUserID ? formValues?.objective?.oct : "",
                nov: editUserID ? formValues?.objective?.nov : "",
                dec: editUserID ? formValues?.objective?.dec : "",
                designation: editUserID ? formValues?.roles[0]?.id : "",
                dealerships: editUserID ? oldDealerships : [],
                pre_form: editUserID
                  ? formValues?.pre_form
                    ? formValues?.pre_form === "1"
                      ? true
                      : false
                    : false
                  : "",
                ca_form: editUserID
                  ? formValues?.ca_form
                    ? formValues?.ca_form === "1"
                      ? true
                      : false
                    : false
                  : "",
                gd_form: editUserID
                  ? formValues?.gd_form
                    ? formValues?.gd_form === "1"
                      ? true
                      : false
                    : false
                  : "",
                event_report: editUserID
                  ? formValues.event_report
                    ? formValues?.event_report === "1"
                      ? true
                      : false
                    : false
                  : "",

                client_submission: editUserID
                  ? formValues.client_submission
                    ? formValues?.client_submission === "1"
                      ? true
                      : false
                    : false
                  : "",
              }}
              enableReinitialize={editUserID ? true : false}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values, setValues }) => (
                <Form>
                  <div className="d-flex w-100 mb-3">
                    {/* <Link to="/"> */}
                    {/* <img
                      className="mb-1"
                      width="100px"
                      src={isDarkMode ? logoDark : logo}
                      alt=""
                    /> */}
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div className="w-50">
                        <h2
                          id="h1"
                          className={`${isDarkMode ? "darkthead" : ""}`}
                        >
                          {translations.usersPage.createUser.title[language]}
                        </h2>
                      </div>
                      <div className="">
                        <button
                          className="w-100 admin-btn btn-lg btn-primary d-flex align-items-center gap-1"
                          type="button"
                          onClick={() =>
                            navigate("/event-portal?page=manage-users")
                          }
                        >
                          <i class="bi bi-arrow-return-left"></i>
                          {translations.commonFields.backToUsers[language]}
                        </button>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className={`w-50 form-floating mb-3 ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="floatingInput "
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations.commonFields.name[language]}{" "}
                      </label>
                    </div>
                    <div
                      className={`w-50 form-floating mb-3 ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="floatingInput "
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations.commonFields.email[language]}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className={`w-50 form-floating mb-3 ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      <Field
                        type="tel"
                        name="phone"
                        className="form-control"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="floatingInput "
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations.commonFields.phone[language]}
                      </label>
                    </div>
                    {/* <div className={errors.password && touched.password ? "form-floating" : "form-floating mb-3"}>
                    <Field
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>
                  <div className={errors.confirmPassword && touched.confirmPassword ? "form-floating" : "form-floating mb-3"}>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="floatingPassword">Confirm Password</label>
                  </div> */}
                    <div
                      className={`w-50 form-floating mb-3 ${
                        isDarkMode ? "dark-mode" : ""
                      }`}
                    >
                      <Field
                        as="select"
                        name="userType"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            userType: e.target.value,
                            designation: "",
                            designation: [],
                          });
                          setUserType(e.target.value);
                        }}
                        class={`form-select ${
                          isDarkMode ? "custom-select" : ""
                        }`}
                      >
                        <option
                          value=""
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          {translations.dropdown.select[language]}
                        </option>
                        {
                          userType && userType !== "Animator" &&

                        <option
                          value="1"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          {translations.commonWords.internalUsers[language]}
                        </option>
                        }
                        <option
                          value="2"
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          {translations.commonWords.externalUsers[language]}
                        </option>
                      </Field>
                      <ErrorMessage
                        name="userType"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="userType"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations.usersPage.createUser.userType[language]}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className={`${
                        values.userType !== "1" ? "w-50" : "w-100"
                      } form-floating mb-3 ${isDarkMode ? "dark-mode" : ""}`}
                    >
                      <Field
                        as="select"
                        name="designation"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          border: isDarkMode
                            ? "1px solid #495057"
                            : "1px solid #dee2e6",
                        }}
                        class={`form-select ${
                          isDarkMode ? "custom-select" : ""
                        }`}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            designation: e.target.value,
                            year:
                              e.target.value === "1" ||
                              e.target.value === 1 ||
                              e.target.value === "2" ||
                              e.target.value === 2 ||
                              e.target.value === "3" ||
                              e.target.value === 3
                                ? new Date().getFullYear()
                                : "",
                          });
                        }}
                      >
                        <option
                          value=""
                          style={{
                            background: isDarkMode ? "#1B1F22" : "",
                            color: isDarkMode ? "#ffffff" : "",
                          }}
                        >
                          {translations.dropdown.select[language]}
                        </option>
                        {values.userType === "1" && (
                          <>
                            {userData &&
                              internalUsers_Ids
                                .slice(
                                  userData.roles[0]?.name === "Super Admin"
                                    ? 0
                                    : 1
                                ) // Exclude the first option if not "Super Admin"
                                .map((user, index) => (
                                  <option
                                    key={index}
                                    value={user.value}
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {user.label}
                                  </option>
                                ))}
                          </>
                        )}
                        {values.userType === "2" && (
                          <>
                            {externalUsers_Ids.map((user, index) => (
                              <option
                                key={index}
                                value={user.value}
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {user.label}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="designation"
                        component="div"
                        className="error text-danger"
                      />
                      <label
                        htmlFor="designation"
                        style={{
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {
                          translations.usersPage.createUser.designation[
                            language
                          ]
                        }
                      </label>
                    </div>
                    {values.userType !== "1" && (
                      <>
                        <div
                          className="w-50 designationDrop"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                            height: "58px",
                          }}
                        >
                          <label
                            className=""
                            style={{
                              marginTop: "-10px",
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              after: {
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                              },
                            }}
                          >
                            {translations.commonWords.dealership[language]}
                          </label>
                          <div
                            className={`form-floating ${
                              isDarkMode ? "dark-mode" : ""
                            }`}
                          >
                            {/* <Field as="select" name="userType" className="form-select">
                    <option value="">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">Client</option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  /> */}

                            <Select
                              name="dealerships"
                              className="selectpicker"
                              maxMenuHeight={"100px"}
                              value={options.filter((item) => {
                                // Check if any dealership_id in values.dealerships matches the item's value
                                return values.dealerships.some(
                                  (dealership) =>
                                    dealership.dealership_id === item.value
                                );
                              })}
                              isMulti={true}
                              onChange={(selectedOption) => {
                                const selectedDealershipsId =
                                  selectedOption.map((data) => {
                                    return { dealership_id: data.value };
                                  });
                                setValues({
                                  ...values,
                                  dealerships: selectedDealershipsId,
                                });
                              }}
                              aria-labelledby="dealership-name-quick"
                              isClearable={false}
                              options={options}
                              placeholder={
                                translations.dropdown.select[language]
                              }
                              isSearchable
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: "transparent",

                                  border: state.isFocused
                                    ? "1px solid none"
                                    : "1px solid none", // Adjust the border color for focus
                                  boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".25rem",
                                  paddingTop: "0rem",

                                  borderStyle: "none",

                                  padding: "0px",
                                  minHeight: "20px",
                                  marginTop: "-8px",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.25rem 0.75rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  svg: {
                                    fill: isDarkMode ? "#ffffff" : "#212529",
                                    width: "18px",
                                    height: "18px",
                                  },
                                  paddingRight: "10px",
                                  // color: isDarkMode ? "#ffffff" : "#868686",
                                }),

                                menu: (provided) => ({
                                  ...provided,
                                  margin: "0", // Remove default margin
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="dealerships"
                          component="div"
                          className="error text-danger"
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="d-flex gap-2 mb-3 pb-4"
                    style={{
                      borderBottom:
                        values.userType === "1" ? "1px solid #dee2e6" : "none",
                    }}
                  >
                    <div
                      className="designationDrop w-50"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                    >
                      <label
                        class=""
                        style={{
                          marginTop: "-10px",
                          backgroundColor: isDarkMode ? "transparent" : "",
                          color: isDarkMode ? "#ffffff" : "#000000",
                          after: {
                            backgroundColor: isDarkMode ? "transparent" : "",
                          },
                        }}
                      >
                        {translations["commonFields"].language[language]}
                      </label>
                      <div
                        className={`form-floating ${
                          isDarkMode ? "dark-mode" : ""
                        }`}
                      >
                        {/* <Field as="select" name="userType" className="form-select">
                    <option value="">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">Client</option>
                  </Field>
                  <ErrorMessage
                    name="userType"
                    component="div"
                    className="error text-danger"
                  /> */}

                        <Select
                          placeholder={
                            translations["dropdown"].selectLanguage[language]
                          }
                          name="user_language"
                          className="selectpicker"
                          maxMenuHeight={"80px"}
                          value={languages.filter(
                            (item) => item.value === values.user_language
                          )}
                          onChange={(selectedOption) =>
                            setValues({
                              ...values,
                              user_language: selectedOption.value,
                            })
                          }
                          aria-labelledby="dealership-name-quick"
                          // isClearable
                          options={languages}
                          isSearchable
                          styles={{
                            input: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              border: state.isFocused
                                ? "1px solid none"
                                : "1px solid none", // Adjust the border color for focus
                              boxShadow: state.isFocused ? "none" : "none", // Bootstrap's box shadow for focus
                              borderRadius: ".25rem",
                              paddingTop: "0rem",

                              borderStyle: "none",
                              backgroundColor: "transparent",
                              padding: "0px",
                              minHeight: "20px",
                              marginTop: "-8px",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none", // Hide the indicator separator
                            }),

                            dropdownIndicator: (provided) => ({
                              ...provided,
                              svg: {
                                fill: isDarkMode ? "#ffffff" : "#212529",
                                width: "18px",
                                height: "18px",
                              },
                              paddingRight: "10px",
                              // paddingTop:"-10px"
                              // color: isDarkMode ? "#ffffff" : "#868686",
                            }),

                            menu: (provided) => ({
                              ...provided,
                              margin: "0", // Remove default margin
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: isDarkMode ? "#1f2023" : "white",
                              zIndex: "9999",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                              // Additional styles for placeholder
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              color: isDarkMode ? "#ffffff" : "black",
                              backgroundColor: state.isHovered
                                ? "#80bdff"
                                : isDarkMode
                                ? "#1f2023"
                                : "white",
                              ":hover": {
                                backgroundColor: isDarkMode
                                  ? "#34DBB4"
                                  : "#34DBB4",
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {userData &&
                    userData.roles[0].permissions.some(
                      (item) => item.name === "Objective"
                    ) && (
                      <>
                        {(values.designation === "1" ||
                          values.designation === 1 ||
                          values.designation === "2" ||
                          values.designation === 2 ||
                          values.designation === "3" ||
                          values.designation === 3) && (
                          <div>
                            <div className="d-flex align-items-center mb-3">
                              <div className="w-50">
                                <p
                                  className="py-1"
                                  style={{ paddingInline: "10px" }}
                                >
                                  {
                                    translations.salesReport.objectives[
                                      language
                                    ]
                                  }{" "}
                                </p>
                              </div>
                              <div
                                className="designationDrop w-50"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              >
                                <label
                                  class=""
                                  style={{
                                    marginTop: "-10px",
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    after: {
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                    },
                                  }}
                                >
                                  {
                                    translations.usersPage.createUser.year[
                                      language
                                    ]
                                  }
                                </label>
                                <div
                                  className={`form-floating ${
                                    isDarkMode ? "dark-mode" : ""
                                  }`}
                                >
                                  <Select
                                    placeholder={
                                      translations.usersPage.createUser
                                        .selectYear[language]
                                    }
                                    name="year"
                                    className="selectpicker"
                                    maxMenuHeight={"100px"}
                                    value={yearsOption.filter(
                                      (item) => item.value?.toString() === values.year?.toString()
                                    )}
                                    onChange={async (selectedOption) => {
                                      const returnedData = await getObjectives(
                                        selectedOption.value
                                      );
                                      if (returnedData.objective !== null) {
                                        setValues({
                                          ...values,
                                          year: selectedOption.value,
                                          jan: returnedData?.objective?.jan,
                                          feb: returnedData?.objective?.feb,
                                          mar: returnedData?.objective?.mar,
                                          april: returnedData?.objective?.april,
                                          may: returnedData?.objective?.may,
                                          june: returnedData?.objective?.june,
                                          july: returnedData?.objective?.july,
                                          aug: returnedData?.objective?.aug,
                                          sep: returnedData?.objective?.sep,
                                          oct: returnedData?.objective?.oct,
                                          nov: returnedData?.objective?.nov,
                                          dec: returnedData?.objective?.dec,
                                        });
                                      } else {
                                        setValues({
                                          ...values,
                                          year: selectedOption.value,
                                          jan: "",
                                          feb: "",
                                          mar: "",
                                          april: "",
                                          may: "",
                                          june: "",
                                          july: "",
                                          aug: "",
                                          sep: "",
                                          oct: "",
                                          nov: "",
                                          dec: "",
                                        });
                                      }
                                    }}
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={yearsOption}
                                    isSearchable
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        border: state.isFocused
                                          ? "1px solid none"
                                          : "1px solid none", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "none"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".25rem",
                                        paddingTop: "0rem",

                                        borderStyle: "none",
                                        backgroundColor: "transparent",
                                        padding: "0px",
                                        minHeight: "20px",
                                        marginTop: "-8px",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0rem 0.75rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),

                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        svg: {
                                          fill: isDarkMode
                                            ? "#ffffff"
                                            : "#212529",
                                          width: "18px",
                                          height: "18px",
                                        },
                                        paddingRight: "10px",
                                        // paddingTop:"-10px"
                                        // color: isDarkMode ? "#ffffff" : "#868686",
                                      }),

                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0", // Remove default margin
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        zIndex: "9999",
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex gap-2">
                              {renderMonthFields(
                                months.slice(0, width >= "55%" ? 6 : 4)
                              )}
                            </div>

                            {/* Row 2 */}
                            <div className="d-flex gap-2">
                              {renderMonthFields(
                                months.slice(
                                  width >= "55%" ? 6 : 4,
                                  width >= "55%" ? 12 : 8
                                )
                              )}
                            </div>

                            {/* Row 3 */}
                            {width < "55%" && (
                              <div className="d-flex gap-2">
                                {renderMonthFields(months.slice(8, 12))}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}

                  <div>
                    {values.userType === "1" && (
                      <>
                        <div className="d-flex w-100 py-3 align-items-center ">
                          <div
                            className="form-check my-1"
                            style={{ width: "20%" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="pre_form"
                              name="pre_form"
                              checked={values.pre_form}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  pre_form: e.target.checked,
                                });
                              }}
                            />

                            <label htmlFor="pre_form" class="form-check-label">
                              PRE Form
                            </label>
                          </div>
                          <div
                            className="form-check mb-1"
                            style={{ width: "20%" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="gd_form"
                              name="gd_form"
                              checked={values.gd_form}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  gd_form: e.target.checked,
                                });
                              }}
                            />

                            <label htmlFor="gd_form" class="form-check-label">
                              DG Form
                            </label>
                          </div>
                          <div
                            className="form-check mb-1"
                            style={{ width: "20%" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="ca_form"
                              name="ca_form"
                              checked={values.ca_form}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  ca_form: e.target.checked,
                                });
                              }}
                            />

                            <label htmlFor="ca_form" class="form-check-label">
                              CA Form
                            </label>
                          </div>
                          <div
                            className="form-check mb-1"
                            style={{ width: "20%" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="client_submission"
                              name="client_submission"
                              checked={values.client_submission}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  client_submission: e.target.checked,
                                });
                              }}
                            />

                            <label
                              htmlFor="client_submission"
                              class="form-check-label"
                            >
                              Client Submissions
                            </label>
                          </div>
                          <div
                            className="form-check mb-1"
                            style={{ width: "20%" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="client_submission"
                              name="event_report"
                              checked={values.event_report}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  event_report: e.target.checked,
                                });
                              }}
                            />

                            <label
                              htmlFor="client_submission"
                              class="form-check-label"
                            >
                              {translations.viewEventReport.title[language]}
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div
                    className={
                      errors.checktc && touched.checktc
                        ? "form-check"
                        : "form-check mb-3"
                    }
                  >
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      id="checktc"
                      name="checktc"
                    />

                    <label htmlFor="checktc" class="form-check-label">
                      I Agree Terms & Conditions
                    </label>
                    <ErrorMessage
                      name="checktc"
                      component="div"
                      className="error text-danger"
                    />
                  </div> */}
                  <div
                    // className={`mx-auto
                    //   ${width >= "55%" ? "w-25" : "w-50"}
                    //   `}
                    style={{
                      width: "15%",
                      marginInline: "auto",
                    }}
                  >
                    <button
                      className="w-100 admin-btn btn-lg btn-primary my-2"
                      type="submit"
                      disabled={loading}
                    >
                      {translations.commonFields.submitBtn[language]}
                    </button>
                  </div>
                  {/* <p className="text-center">
                  Go Back to{" "}
                  <a
                    className="link-primary "
                    style={{ cursor: "pointer" }}
                    onClick={() => window.}
                  >
                    User
                  </a>
                  <Link to="/event-portal" className="text-primary">
                  Event Portal
                </Link>
                </p> */}
                </Form>
              )}
            </Formik>
          )}
        </main>
      </div>
    </div>
  );
};

export default EditUser;
