import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Select from "react-select";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loader from "./loader";

const CreatePackage = ({
  setDealerTrigger,
  setIsLoading,
  isDarkMode,
  setNewDealership = () => {},
  dealerships,
  packages,
  setallPackages,
  setSelectedPackage
}) => {
  const token = localStorage.getItem("token");
  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);
  var button = document.getElementById("closebtn4");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.addPackage.validationText.name[language]
    ),
  });



  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/packages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: values.name.trim(),
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setallPackages([
          ...packages,
          { label: data.package.name, value: data.package.id },
        ]);
        setSelectedPackage(data.package.id);
        resetForm();
        button.click();
      } else {
        toast.error(translations.alertMessages.wentWrong[language]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(translations.alertMessages.wentWrong[language]);
    }
  };

  return (
    <div id="packageModal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <Formik
            initialValues={{ name: "", brand_id: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
          >
            {({ errors, values, setValues, resetForm }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3 style={{ color: "#000000" }}>
                    {translations["commonWords"].addPackage[language]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="mb-3">
                    <label for="inputadddealership" class="form-label">
                      {translations.forms.formFields.package[language] +
                        " " +
                        translations.commonFields.name[language]}
                    </label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control"
                      id="inputadddealership"
                      style={{
                        backgroundColor: isDarkMode ? "transparent" : "",
                        color: isDarkMode ? "#ffffff" : "#000000",
                        border: isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6",
                      }}
                      placeholder={translations["commonFields"].name[language]}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                </div>
                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    id="add-dealership"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                  >
                    {loading
                      ? translations["commonFields"].addingBtn[language]
                      : translations["commonFields"].addBtn[language]}
                  </button>
                  <button
                    id="closebtn4"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      resetForm();
                      setValues({ name: "", brand_id: "" });
                    }}
                  >
                    {translations["commonFields"].closeBtn[language]}
                  </button>
                  {/* <br />
                  <div
                    class="alert alert-danger d-flex align-items-center invisible"
                    id="clientFormAlert"
                    role="alert"
                  >
                    <div id="clientFormAlertText"></div>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default CreatePackage;
