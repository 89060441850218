import React, { useState } from "react";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { debounce } from "lodash";

function DraggableHeaderCell({ column, onResize, isDarkMode }) {
  const [isResizing, setIsResizing] = useState(false); // Track resizing state
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  // Debounced function to call after resizing stops
  const debouncedResizeStop = debounce(() => {
    setIsResizing(false);
  }, 300); // Adjust delay as needed
  
  return (
    <Resizable
      width={column.width}
      // height={40}
      height={0}
      onResizeStart={() => setIsResizing(true)} // Disable drag when resizing starts
      onResizeStop={() => {
        debouncedResizeStop();
      }}
      onResize={(e, { size }) => onResize(column.id, size.width)}
      draggableOpts={{ enableUserSelectHack: false }}
      handle={
        <div
          className={`resize-handle ${isDarkMode ? "dark" : "light"}`}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "8px",
            cursor: "col-resize",
            zIndex: 1,
          }}
          onClick={(e) => e.stopPropagation()} // Prevent click from triggering drag
        />
      }
    >
      <th
        className={`${isDarkMode ? "darkthead" : ""} ${column.className || ""}`}
        colSpan={column.colSpan}
        scope="col"
        id={column.id}
        style={{
          ...style,
          width: column.width,
          minWidth: column.width,
          maxWidth: `${column.width + 50}px`,
          cursor: isResizing ? "col-resize" : "move",
          position: "relative",
          paddingInline: "50px !important",
          marginInline: "4px !important",
        }}
      >
        <div
          className="column-content"
          ref={setNodeRef} // Applies only to the draggable column
          {...(isResizing ? {} : { ...attributes, ...listeners })}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            className={`column-title p-2 ${isDarkMode ? "darkthead" : ""} ${
              column.className || ""
            }`}
            style={{ userSelect: "none", cursor: "move" }}
          >
            {column.content}
          </span>

          {/* Resize handle (Separately placed inside the column) */}
          <div
            className="custom-resize-handle"
            style={{
              width: "6px",
              height: "100%",
              cursor: "col-resize",
              position: "absolute",
              right: "0",
              top: "0",
              bottom: "0",
              backgroundColor: "#435574", // Light visual indicator for the resize handle
            }}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </div>
      </th>
    </Resizable>
  );
}

export default DraggableHeaderCell;
